import { useEffect, useState } from "react";

const useMakePercent = () => {
  const [precentValue, setPercentValue] = useState(null);
  const [chartValue, setChartValue] = useState(0);

  useEffect(() => {
    switch (precentValue) {
      case 100:
        setChartValue(0);
        break;
      case 99:
        setChartValue(1);
        break;
      case 98:
        setChartValue(2);
        break;
      case 97:
        setChartValue(3);
        break;
      case 96:
        setChartValue(4);
        break;
      case 95:
        setChartValue(5);
        break;
      case 94:
        setChartValue(6);
        break;
      case 93:
        setChartValue(7);
        break;
      case 92:
        setChartValue(8);
        break;
      case 91:
        setChartValue(9);
        break;
      case 90:
        setChartValue(10);
        break;
      case 89:
        setChartValue(11);
        break;
      case 88:
        setChartValue(12);
        break;
      case 87:
        setChartValue(13);
        break;
      case 86:
        setChartValue(14);
        break;
      case 85:
        setChartValue(15);
        break;
      case 84:
        setChartValue(16);
        break;
      case 83:
        setChartValue(17);
        break;
      case 82:
        setChartValue(18);
        break;
      case 81:
        setChartValue(19);
        break;
      case 80:
        setChartValue(20);
        break;
      case 79:
        setChartValue(21);
        break;
      case 78:
        setChartValue(22);
        break;
      case 77:
        setChartValue(23);
        break;
      case 76:
        setChartValue(24);
        break;
      case 75:
        setChartValue(25);
        break;
      case 74:
        setChartValue(26);
        break;
      case 73:
        setChartValue(27);
        break;
      case 72:
        setChartValue(28);
        break;
      case 71:
        setChartValue(29);
        break;
      case 70:
        setChartValue(30);
        break;
      case 69:
        setChartValue(31);
        break;
      case 68:
        setChartValue(32);
        break;
      case 67:
        setChartValue(33);
        break;
      case 66:
        setChartValue(34);
        break;
      case 65:
        setChartValue(35);
        break;
      case 64:
        setChartValue(36);
        break;
      case 63:
        setChartValue(37);
        break;
      case 62:
        setChartValue(38);
        break;
      case 61:
        setChartValue(39);
        break;
      case 60:
        setChartValue(40);
        break;
      case 59:
        setChartValue(41);
        break;
      case 58:
        setChartValue(42);
        break;
      case 57:
        setChartValue(43);
        break;
      case 56:
        setChartValue(44);
        break;
      case 55:
        setChartValue(45);
        break;
      case 54:
        setChartValue(46);
        break;
      case 53:
        setChartValue(47);
        break;
      case 52:
        setChartValue(48);
        break;
      case 51:
        setChartValue(49);
        break;
      case 50:
        setChartValue(50);
        break;
      case 49:
        setChartValue(51);
        break;
      case 48:
        setChartValue(52);
        break;
      case 47:
        setChartValue(53);
        break;
      case 46:
        setChartValue(54);
        break;
      case 45:
        setChartValue(55);
        break;
      case 44:
        setChartValue(56);
        break;
      case 43:
        setChartValue(57);
        break;
      case 42:
        setChartValue(58);
        break;
      case 41:
        setChartValue(59);
        break;
      case 40:
        setChartValue(60);
        break;
      case 39:
        setChartValue(61);
        break;
      case 38:
        setChartValue(62);
        break;
      case 37:
        setChartValue(63);
        break;
      case 36:
        setChartValue(64);
        break;
      case 35:
        setChartValue(65);
        break;
      case 34:
        setChartValue(66);
        break;
      case 33:
        setChartValue(67);
        break;
      case 32:
        setChartValue(68);
        break;
      case 31:
        setChartValue(69);
        break;
      case 30:
        setChartValue(70);
        break;
      case 29:
        setChartValue(71);
        break;
      case 28:
        setChartValue(72);
        break;
      case 27:
        setChartValue(73);
        break;
      case 26:
        setChartValue(74);
        break;
      case 25:
        setChartValue(75);
        break;
      case 24:
        setChartValue(76);
        break;
      case 23:
        setChartValue(77);
        break;
      case 22:
        setChartValue(78);
        break;
      case 21:
        setChartValue(79);
        break;
      case 20:
        setChartValue(80);
        break;
      case 19:
        setChartValue(81);
        break;
      case 18:
        setChartValue(82);
        break;
      case 17:
        setChartValue(83);
        break;
      case 16:
        setChartValue(84);
        break;
      case 15:
        setChartValue(85);
        break;
      case 14:
        setChartValue(86);
        break;
      case 13:
        setChartValue(87);
        break;
      case 12:
        setChartValue(88);
        break;
      case 11:
        setChartValue(89);
        break;
      case 10:
        setChartValue(90);
        break;
      case 9:
        setChartValue(91);
        break;
      case 8:
        setChartValue(92);
        break;
      case 7:
        setChartValue(93);
        break;
      case 6:
        setChartValue(94);
        break;
      case 5:
        setChartValue(95);
        break;
      case 4:
        setChartValue(96);
        break;
      case 3:
        setChartValue(97);
        break;
      case 2:
        setChartValue(98);
        break;
      case 1:
        setChartValue(99);
        break;
      case 0:
        setChartValue(100);
        break;

      default:
        break;
    }
  }, [precentValue]);
  return {
    precentValue,
    chartValue,
    setChartValue,
    setPercentValue,
  };
};

export default useMakePercent;

import "./sharedComponent-style.css";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareComponent = ({ setClickedInvite }) => {
  const shareUrl = "https://my-meditation-time.us";
  return (
    <div className="allSocialsContainer">
      <div className="headerInviteModal">
        <h1>Invite via social media</h1>
        <button
          onClick={() => setClickedInvite(false)}
          className="buttonCloseInvite"
        >
          x
        </button>
      </div>
      <div className="invitecontent">
        <div className="invitehead">
          <h3>Invite to try this application!</h3>
          <p>
            You can invite your friends and family by sharing the address of the
            the application quickly on the various social networks listed below.
            in the list below.
          </p>
        </div>
        <div className="iconsInviteContainer">
          <div className="socialsContainer">
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={45} round={true} />
            </EmailShareButton>
          </div>
          <div className="socialsContainer">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={45} round={true} />
            </FacebookShareButton>
          </div>

          <div className="socialsContainer">
            <InstapaperShareButton url={shareUrl}>
              <InstapaperIcon size={45} round={true} />
            </InstapaperShareButton>
          </div>

          <div className="socialsContainer">
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={45} round={true} />
            </LinkedinShareButton>
          </div>
          <div className="socialsContainer">
            <RedditShareButton url={shareUrl}>
              <RedditIcon size={45} round={true} />
            </RedditShareButton>
          </div>
          <div className="socialsContainer">
            <TumblrShareButton url={shareUrl}>
              <TumblrIcon size={45} round={true} />
            </TumblrShareButton>
          </div>
          <div className="socialsContainer">
            <TwitterShareButton
              openShareDialogOnClick
              title="Visite My Meditation Time"
              url={shareUrl}
            >
              <TwitterIcon size={45} round={true} />
            </TwitterShareButton>
          </div>
          <div className="socialsContainer">
            <WhatsappShareButton
              openShareDialogOnClick
              title="Visite My Meditation Time"
              url={shareUrl}
            >
              <WhatsappIcon size={45} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
      <p className="inviteFooter">
        If you wish to share an image of your profile, click on the Share
        button!
      </p>
    </div>
  );
};

export default ShareComponent;

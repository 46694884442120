import { useState } from "react";
import useCutDecimals from "../../hooks/useCutDecimals";

const useConstants = () => {
  const [historymeditation] = useState(
    JSON.parse(localStorage.getItem("history")) ?? []
  );
  const { cutDecimals } = useCutDecimals();

  const BeginnerLevel =
    historymeditation.length > 0 && historymeditation.length <= 60;
  const IntermedLevel =
    historymeditation.length > 60 && historymeditation.length <= 120;
  const ConfirmedLevel =
    historymeditation.length > 120 && historymeditation.length <= 360;
  const ExpertLevel = historymeditation.length > 360;
  const AbsoluteExpertLevel = historymeditation.length > 720;

  const progressBeginnerLevelValue = Number(
    cutDecimals((100 * historymeditation.length) / 60, 0)
  );

  const startPercentIntermed = historymeditation.length - 60;
  const progressIntermedLevelValue = Number(
    cutDecimals((100 * startPercentIntermed) / 60, 0)
  );

  const startPercentConfirmed = historymeditation.length - 120;
  const progressConfirmedLevelValue = Number(
    cutDecimals((100 * startPercentConfirmed) / 240, 0)
  );

  const startPercentExpert = historymeditation.length - 360;
  const progressExpertLevelValue = Number(
    cutDecimals((100 * startPercentExpert) / 360, 0)
  );

  return {
    BeginnerLevel,
    IntermedLevel,
    ConfirmedLevel,
    ExpertLevel,
    AbsoluteExpertLevel,
    progressBeginnerLevelValue,
    progressIntermedLevelValue,
    progressConfirmedLevelValue,
    progressExpertLevelValue,
  };
};

export default useConstants;

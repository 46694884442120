import { useEffect, useState } from "react";
import BeginnerBadge from "../../images/beginner-home.svg";
import IntermediateBadge from "../../images/intermediate-home.svg";
import ConfirmedBadge from "../../images/confirmed-home.svg";
import ExpertBadge from "../../images/expert-home.svg";
import "../percentProgress.css";
import levelsNameAtom from "../../statesManager/levelsNameAtom";
import { useRecoilState } from "recoil";
import useConstants from "./useConstants";
import levelOfMomentAtom from "../../statesManager/levelsOfMomentAtom";
import useSweetAlertComponent from "../sweetAlertComponent/useSweetAlertComponent";
import CustomBadgeWithoutProgress from "./customBadges/CustomBadgeWithoutProgress";
import CustomBadgeWithProgress from "./customBadges/CustomBadgeWithProgress";

const LevelBadges = () => {
  const { levelBadgesAlert } = useSweetAlertComponent();
  const [historymeditation] = useState(
    JSON.parse(localStorage.getItem("history")) ?? []
  );
  const [clickedBadge, setClickedBadge] = useState(false);

  const handleClickBadge = () => {
    setClickedBadge((clickedBadge) => !clickedBadge);
  };
  const [firstClick, setFirstClick] = useState(
    localStorage.getItem("firstClick") ?? "no"
  );

  const {
    BeginnerLevel,
    IntermedLevel,
    ConfirmedLevel,
    ExpertLevel,
    AbsoluteExpertLevel,
    progressBeginnerLevelValue,
    progressIntermedLevelValue,
    progressConfirmedLevelValue,
    progressExpertLevelValue,
  } = useConstants();

  const [levelsName] = useRecoilState(levelsNameAtom);
  // eslint-disable-next-line no-unused-vars
  const [levelOfMoment, setLevelOfMoment] = useRecoilState(levelOfMomentAtom);

  useEffect(() => {
    const LevelTitle = () => {
      if (levelsName === "beginner") {
        setLevelOfMoment("Beginner");
      }
      if (levelsName === "intermed") {
        setLevelOfMoment("Intermediate");
      }
      if (levelsName === "confirmed") {
        setLevelOfMoment("Confirmed");
      }
      if (levelsName === "expert") {
        setLevelOfMoment("Expert");
      }
      if (AbsoluteExpertLevel) {
        setLevelOfMoment("Expert Absolut");
      }
    };

    if (clickedBadge && firstClick !== "click") {
      // console.log("first click", firstClick);
      setFirstClick("click");
      localStorage.setItem("firstClick", "click");
    }
    if (clickedBadge) {
      LevelTitle();
      levelBadgesAlert().then((clickedBadge) => {
        if (clickedBadge) {
          setClickedBadge(false);
        }
      });
    }
  }, [
    clickedBadge,
    firstClick,
    AbsoluteExpertLevel,
    levelsName,
    levelBadgesAlert,
    setLevelOfMoment,
  ]);

  return (
    <div
      className={
        firstClick !== "click"
          ? "Badge-container jello-horizontal"
          : "Badge-container "
      }
      onClick={handleClickBadge}
    >
      {BeginnerLevel && historymeditation.length < 2 ? (
        <CustomBadgeWithoutProgress
          classBadge="beginnerBadge"
          badgeImg={BeginnerBadge}
        />
      ) : null}
      {BeginnerLevel && historymeditation.length >= 2 ? (
        <CustomBadgeWithProgress
          classBadge="beginnerBadge"
          badgeImg={BeginnerBadge}
          progressValue={progressBeginnerLevelValue}
        />
      ) : IntermedLevel && historymeditation.length < 62 ? (
        <CustomBadgeWithoutProgress
          classBadge="intermedBadge"
          badgeImg={IntermediateBadge}
        />
      ) : IntermedLevel && historymeditation.length >= 62 ? (
        <CustomBadgeWithProgress
          classBadge="intermedBadge"
          badgeImg={IntermediateBadge}
          progressValue={progressIntermedLevelValue}
        />
      ) : ConfirmedLevel && historymeditation.length < 122 ? (
        <CustomBadgeWithoutProgress
          classBadge="confirmedBadge"
          badgeImg={ConfirmedBadge}
        />
      ) : ConfirmedLevel && historymeditation.length >= 122 ? (
        <CustomBadgeWithProgress
          classBadge="confirmedBadge"
          badgeImg={ConfirmedBadge}
          progressValue={progressConfirmedLevelValue}
        />
      ) : ExpertLevel && historymeditation.length < 362 ? (
        <CustomBadgeWithoutProgress
          classBadge="expertBadge"
          badgeImg={ExpertBadge}
        />
      ) : ExpertLevel && historymeditation.length >= 362 ? (
        <CustomBadgeWithProgress
          classBadge="expertBadge"
          badgeImg={ExpertBadge}
          progressValue={progressExpertLevelValue}
        />
      ) : AbsoluteExpertLevel ? (
        <CustomBadgeWithoutProgress
          classBadge="expertBadge"
          badgeImg={ExpertBadge}
          checkColor="color-green"
        />
      ) : null}
    </div>
  );
};

export default LevelBadges;

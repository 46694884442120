import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  HealingSound,
  HealingSoundAlt,
  TissusSound,
  TissusSoundAlt,
  chakraSound,
  chakraSoundAlt,
  negativeSound,
  negativeSoundAlt,
  whaleSound,
  whaleSoundAlt,
  deepMeditateSound,
  deepMeditateSoundAlt,
  ToxinSound,
  ToxinSoundAlt,
  HigherConsciousnessSound,
  HigherConsciousnessSoundAlt,
  spiritualSound,
  spiritualSoundAlt,
  deeperSleepSound,
  deeperSleepSoundAlt,
  immuneSound,
  immuneSoundAlt,
  liberateSound,
  liberateSoundAlt,
  releaseSound,
  releaseSoundAlt,
  innerSound,
  innerSoundAlt,
  dnaSound,
  dnaSoundAlt,
  loveSound,
  loveSoundAlt,
  overcomeSound,
  overcomeSoundAlt,
  awakeSound,
  awakeSoundAlt,
  AjamSound,
  AjamSoundAlt,
  BambooSound,
  BambooSoundAlt,
  DhyanSound,
  DhyanSoundAlt,
  GuruSound,
  GuruSoundAlt,
  HarekrishnaSound,
  HarekrishnaSoundAlt,
  OmkarSound,
  OmkarSoundAlt,
  OmnamahSound,
  OmnamahSoundAlt,
  OmsarvaSound,
  OmsarvaSoundAlt,
  SaurashtreSound,
  SaurashtreSoundAlt,
  WaveSound,
  WaveSoundAlt,
  DesertSound,
  DesertSoundAlt,
  ForestSound,
  ForestSoundAlt,
  RiverSound,
  RiverSoundAlt,
  RainForestSound,
  RainForestSoundAlt,
  NightSound,
  NightSoundAlt,
  MountainSound,
  MountainSoundAlt,
  RainOnRoofSound,
  RainOnRoofSoundAlt,
  CaveSound,
  CaveSoundAlt,
  StormSound,
  StormSoundAlt,
  FireSound,
  FireSoundAlt,
  LakeIconSound,
  LakeIconSoundAlt,
  volcanoSound,
  volcanoSoundAlt,
  waterfallSound,
  waterfallSoundAlt,
  millSound,
  millSoundAlt,
} from "../components/presets/dataSounds";
import titleSoundAtom from "../statesManager/titleSoundAtom";

const useSoundsToPlay = () => {
  const [titleSound] = useRecoilState(titleSoundAtom);
  const [SoundList, setSoundList] = useState([]);
  const [SoundListAlt, setSoundListAlt] = useState([]);

  // BEGINNER SOUNDS
  useEffect(() => {
    let controller = new AbortController();

    // Wave sound
    async function loadWaveSound() {
      const WaveSoundList = await WaveSound;
      const WaveSoundListAlt = await WaveSoundAlt;
      setSoundListAlt(WaveSoundListAlt);
      setSoundList(WaveSoundList);
    }
    if (titleSound === "Beach") {
      loadWaveSound();
    }

    // Forest sound
    async function loadForestSound() {
      const ForestSoundList = await ForestSound;
      const ForestSoundListAlt = await ForestSoundAlt;
      setSoundListAlt(ForestSoundListAlt);
      setSoundList(ForestSoundList);
    }
    if (titleSound === "Forest") {
      loadForestSound();
    }

    // Desert sound
    async function loadDesertSound() {
      const DesertSoundList = await DesertSound;
      const DesertSoundListAlt = await DesertSoundAlt;
      setSoundListAlt(DesertSoundListAlt);
      setSoundList(DesertSoundList);
    }
    if (titleSound === "Desert") {
      loadDesertSound();
    }

    // Night sound
    async function loadNightSound() {
      const NightSoundList = await NightSound;
      const NightSoundListAlt = await NightSoundAlt;
      setSoundListAlt(NightSoundListAlt);
      setSoundList(NightSoundList);
    }
    if (titleSound === "Night") {
      loadNightSound();
    }

    // River sound
    async function loadRiverSound() {
      const RiverSoundList = await RiverSound;
      const RiverSoundListAlt = await RiverSoundAlt;
      setSoundListAlt(RiverSoundListAlt);
      setSoundList(RiverSoundList);
    }
    if (titleSound === "River") {
      loadRiverSound();
    }

    // RainForest sound
    async function loadRainForestSound() {
      const RainForestSoundList = await RainForestSound;
      const RainForestSoundListAlt = await RainForestSoundAlt;
      setSoundListAlt(RainForestSoundListAlt);
      setSoundList(RainForestSoundList);
    }
    if (titleSound === "Tropical") {
      loadRainForestSound();
    }

    // Mountain sound
    async function loadMountainSound() {
      const MountainSoundList = await MountainSound;
      const MountainSoundListAlt = await MountainSoundAlt;
      setSoundListAlt(MountainSoundListAlt);
      setSoundList(MountainSoundList);
    }
    if (titleSound === "Mountain") {
      loadMountainSound();
    }

    // RainOnRoof sound
    async function loadRainOnRoofSound() {
      const RainOnRoofSoundList = await RainOnRoofSound;
      const RainOnRoofSoundListAlt = await RainOnRoofSoundAlt;
      setSoundListAlt(RainOnRoofSoundListAlt);
      setSoundList(RainOnRoofSoundList);
    }
    if (titleSound === "Rain") {
      loadRainOnRoofSound();
    }

    // Cave sound
    async function loadCaveSound() {
      const CaveSoundList = await CaveSound;
      const CaveSoundListAlt = await CaveSoundAlt;
      setSoundListAlt(CaveSoundListAlt);
      setSoundList(CaveSoundList);
    }
    if (titleSound === "Cavern") {
      loadCaveSound();
    }

    // Storm sound
    async function loadStormSound() {
      const StormSoundList = await StormSound;
      const StormSoundListAlt = await StormSoundAlt;
      setSoundListAlt(StormSoundListAlt);
      setSoundList(StormSoundList);
    }
    if (titleSound === "Storm") {
      loadStormSound();
    }
    // Fire sound
    async function loadFireSound() {
      const FireSoundList = await FireSound;
      const FireSoundListAlt = await FireSoundAlt;
      setSoundListAlt(FireSoundListAlt);
      setSoundList(FireSoundList);
    }
    if (titleSound === "Fire") {
      loadFireSound();
    }

    // Lake sound
    async function loadLakeIconSound() {
      const LakeIconSoundList = await LakeIconSound;
      const LakeIconSoundListAlt = await LakeIconSoundAlt;
      setSoundList(LakeIconSoundListAlt);
      setSoundList(LakeIconSoundList);
    }
    if (titleSound === "Lake") {
      loadLakeIconSound();
    }

    // waterfall sound
    async function loadwaterfallSound() {
      const waterfallSoundList = await waterfallSound;
      const waterfallSoundListAlt = await waterfallSoundAlt;
      setSoundListAlt(waterfallSoundListAlt);
      setSoundList(waterfallSoundList);
    }
    if (titleSound === "Waterfalls") {
      loadwaterfallSound();
    }

    // volcano sound
    async function loadvolcanoSound() {
      const volcanoSoundList = await volcanoSound;
      const volcanoSoundListAlt = await volcanoSoundAlt;
      setSoundListAlt(volcanoSoundListAlt);
      setSoundList(volcanoSoundList);
    }
    if (titleSound === "Volcan") {
      loadvolcanoSound();
    }

    // mill sound
    async function loadmillSound() {
      const millSoundList = await millSound;
      const millSoundListAlt = await millSoundAlt;
      setSoundListAlt(millSoundListAlt);
      setSoundList(millSoundList);
    }
    if (titleSound === "Mill") {
      loadmillSound();
    }

    return () => {
      controller?.abort();
    };
  }, [titleSound]);

  // INTERMEDIATE SOUNDS
  useEffect(() => {
    let controller = new AbortController();

    // Ajam sound
    async function loadAjamSound() {
      const AjamSoundList = await AjamSound;
      const AjamSoundListAlt = await AjamSoundAlt;
      setSoundListAlt(AjamSoundListAlt);
      setSoundList(AjamSoundList);
    }
    if (titleSound === "Ajam nirvikalpam") {
      loadAjamSound();
    }

    // Bamboo sound
    async function loadBambooSound() {
      const BambooSoundList = await BambooSound;
      const BambooSoundListAlt = await BambooSoundAlt;
      setSoundListAlt(BambooSoundListAlt);
      setSoundList(BambooSoundList);
    }
    if (titleSound === "bamboo mantra") {
      loadBambooSound();
    }

    // Dhyan sound
    async function loadDhyanSound() {
      const DhyanSoundList = await DhyanSound;
      const DhyanSoundListAlt = await DhyanSoundAlt;
      setSoundListAlt(DhyanSoundListAlt);
      setSoundList(DhyanSoundList);
    }
    if (titleSound === "Dhyan Mantra") {
      loadDhyanSound();
    }
    // Guru sound
    async function loadGuruSound() {
      const GuruSoundList = await GuruSound;
      const GuruSoundListAlt = await GuruSoundAlt;
      setSoundListAlt(GuruSoundListAlt);
      setSoundList(GuruSoundList);
    }
    if (titleSound === "Guru brahma") {
      loadGuruSound();
    }

    // Harekrishna sound
    async function loadHarekrishnaSound() {
      const HarekrishnaSoundList = await HarekrishnaSound;
      const HarekrishnaSoundListAlt = await HarekrishnaSoundAlt;
      setSoundListAlt(HarekrishnaSoundListAlt);
      setSoundList(HarekrishnaSoundList);
    }
    if (titleSound === "harekrishna flute") {
      loadHarekrishnaSound();
    }

    // Omkar sound
    async function loadOmkarSound() {
      const OmkarSoundList = await OmkarSound;
      const OmkarSoundListAlt = await OmkarSoundAlt;
      setSoundListAlt(OmkarSoundListAlt);
      setSoundList(OmkarSoundList);
    }
    if (titleSound === "Omkar") {
      loadOmkarSound();
    }

    // Omnamah sound
    async function loadOmnamahSound() {
      const OmnamahSoundList = await OmnamahSound;
      const OmnamahSoundListAlt = await OmnamahSoundAlt;
      setSoundListAlt(OmnamahSoundListAlt);
      setSoundList(OmnamahSoundList);
    }
    if (titleSound === "om namah shivaya") {
      loadOmnamahSound();
    }

    // Omsarva sound
    async function loadOmsarvaSound() {
      const OmsarvaSoundList = await OmsarvaSound;
      const OmsarvaSoundListAlt = await OmsarvaSoundAlt;
      setSoundListAlt(OmsarvaSoundListAlt);
      setSoundList(OmsarvaSoundList);
    }
    if (titleSound === "Om Sarva Mangal") {
      loadOmsarvaSound();
    }

    // Saurashtre sound
    async function loadSaurashtreSound() {
      const SaurashtreSoundList = await SaurashtreSound;
      const SaurashtreSoundListAlt = await SaurashtreSoundAlt;
      setSoundListAlt(SaurashtreSoundListAlt);
      setSoundList(SaurashtreSoundList);
    }
    if (titleSound === "Saurashtre Somnatham") {
      loadSaurashtreSound();
    }

    return () => {
      controller?.abort();
    };
  }, [titleSound]);

  // CONFIRMED SOUNDS
  useEffect(() => {
    let controller = new AbortController();

    // deeperSleep sound
    async function loaddeeperSleepSound() {
      const deeperSleepSoundList = await deeperSleepSound;
      const deeperSleepSoundListAlt = await deeperSleepSoundAlt;
      setSoundListAlt(deeperSleepSoundListAlt);
      setSoundList(deeperSleepSoundList);
    }
    if (titleSound === "174Hz") {
      loaddeeperSleepSound();
    }

    // immune sound
    async function loadimmuneSound() {
      const immuneSoundList = await immuneSound;
      const immuneSoundListAlt = await immuneSoundAlt;
      setSoundListAlt(immuneSoundListAlt);
      setSoundList(immuneSoundList);
    }
    if (titleSound === "285Hz") {
      loadimmuneSound();
    }

    // liberate sound
    async function loadliberateSound() {
      const liberateSoundList = await liberateSound;
      const liberateSoundListAlt = await liberateSoundAlt;
      setSoundListAlt(liberateSoundListAlt);
      setSoundList(liberateSoundList);
    }
    if (titleSound === "396Hz") {
      loadliberateSound();
    }

    // release sound
    async function loadreleaseSound() {
      const releaseSoundList = await releaseSound;
      const releaseSoundListAlt = await releaseSoundAlt;
      setSoundListAlt(releaseSoundListAlt);
      setSoundList(releaseSoundList);
    }
    if (titleSound === "417Hz") {
      loadreleaseSound();
    }

    // inner sound
    async function loadinnerSound() {
      const innerSoundList = await innerSound;
      const innerSoundListAlt = await innerSoundAlt;
      setSoundListAlt(innerSoundListAlt);
      setSoundList(innerSoundList);
    }
    if (titleSound === "432Hz") {
      loadinnerSound();
    }

    // dna sound
    async function loaddnaSound() {
      const dnaSoundList = await dnaSound;
      const dnaSoundListAlt = await dnaSoundAlt;
      setSoundListAlt(dnaSoundListAlt);
      setSoundList(dnaSoundList);
    }
    if (titleSound === "528Hz") {
      loaddnaSound();
    }

    // love sound
    async function loadloveSound() {
      const loveSoundList = await loveSound;
      const loveSoundListAlt = await loveSoundAlt;
      setSoundListAlt(loveSoundListAlt);
      setSoundList(loveSoundList);
    }
    if (titleSound === "639Hz") {
      loadloveSound();
    }

    // overcome sound
    async function loadovercomeSound() {
      const overcomeSoundList = await overcomeSound;
      const overcomeSoundListAlt = await overcomeSoundAlt;
      setSoundListAlt(overcomeSoundListAlt);
      setSoundList(overcomeSoundList);
    }
    if (titleSound === "714Hz") {
      loadovercomeSound();
    }

    // awake sound
    async function loadawakeSound() {
      const awakeSoundList = await awakeSound;
      const awakeSoundListAlt = await awakeSoundAlt;
      setSoundListAlt(awakeSoundListAlt);
      setSoundList(awakeSoundList);
    }
    if (titleSound === "852Hz") {
      loadawakeSound();
    }

    return () => {
      controller?.abort();
    };
  }, [titleSound]);

  // EXPERT SOUNDS
  useEffect(() => {
    let controller = new AbortController();

    // Healing sound
    async function loadHealingSound() {
      const HealingSoundList = await HealingSound;
      const HealingSoundListAlt = await HealingSoundAlt;
      setSoundListAlt(HealingSoundListAlt);
      setSoundList(HealingSoundList);
    }
    if (titleSound === "*174Hz") {
      loadHealingSound();
    }

    // Tissus sound
    async function loadTissusSound() {
      const TissusSoundList = await TissusSound;
      const TissusSoundListAlt = await TissusSoundAlt;
      setSoundListAlt(TissusSoundListAlt);
      setSoundList(TissusSoundList);
    }
    if (titleSound === "*285Hz") {
      loadTissusSound();
    }

    // chakra sound
    async function loadchakraSound() {
      const chakraSoundList = await chakraSound;
      const chakraSoundListAlt = await chakraSoundAlt;
      setSoundListAlt(chakraSoundListAlt);
      setSoundList(chakraSoundList);
    }
    if (titleSound === "*396Hz") {
      loadchakraSound();
    }

    // negative sound
    async function loadnegativeSound() {
      const negativeSoundList = await negativeSound;
      const negativeSoundListAlt = await negativeSoundAlt;
      setSoundListAlt(negativeSoundListAlt);
      setSoundList(negativeSoundList);
    }
    if (titleSound === "*417Hz") {
      loadnegativeSound();
    }

    // whale sound
    async function loadwhaleSound() {
      const whaleSoundList = await whaleSound;
      const whaleSoundListAlt = await whaleSoundAlt;
      setSoundListAlt(whaleSoundListAlt);
      setSoundList(whaleSoundList);
    }
    if (titleSound === "*432Hz") {
      loadwhaleSound();
    }

    // deepMeditate sound
    async function loaddeepMeditateSound() {
      const deepMeditateSoundList = await deepMeditateSound;
      const deepMeditateSoundListAlt = await deepMeditateSoundAlt;
      setSoundListAlt(deepMeditateSoundListAlt);
      setSoundList(deepMeditateSoundList);
    }
    if (titleSound === "*528Hz") {
      loaddeepMeditateSound();
    }

    // Toxin sound
    async function loadToxinSound() {
      const ToxinSoundList = await ToxinSound;
      const ToxinSoundListAlt = await ToxinSoundAlt;
      setSoundListAlt(ToxinSoundListAlt);
      setSoundList(ToxinSoundList);
    }
    if (titleSound === "*714Hz") {
      loadToxinSound();
    }

    // HigherConsciousness sound
    async function loadHigherConsciousnessSound() {
      const HigherConsciousnessSoundList = await HigherConsciousnessSound;
      const HigherConsciousnessSoundListAlt = await HigherConsciousnessSoundAlt;
      setSoundListAlt(HigherConsciousnessSoundListAlt);
      setSoundList(HigherConsciousnessSoundList);
    }
    if (titleSound === "*852Hz") {
      loadHigherConsciousnessSound();
    }

    // spiritual sound
    async function loadspiritualSound() {
      const spiritualSoundList = await spiritualSound;
      const spiritualSoundListAlt = await spiritualSoundAlt;
      setSoundListAlt(spiritualSoundListAlt);
      setSoundList(spiritualSoundList);
    }
    if (titleSound === "*963Hz") {
      loadspiritualSound();
    }

    return () => {
      controller?.abort();
    };
  }, [titleSound]);
  return {
    SoundList,
    SoundListAlt,
  };
};

export default useSoundsToPlay;

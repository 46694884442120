import { Fragment, useEffect } from "react";
import { useRecoilState } from "recoil";
import levelsNameAtom from "../../../statesManager/levelsNameAtom";

const LevelFlower = ({
  historymeditation,
  BlueLotus,
  PurpleLotus,
  RedLotus,
  ExpertLotus,
}) => {
  const [levelsName, setLevelsName] = useRecoilState(levelsNameAtom);

  useEffect(() => {
    const beginnerLevel =
      historymeditation.length > 0 && historymeditation.length <= 60;
    const IntermedLevel =
      historymeditation.length > 60 && historymeditation.length <= 120;
    const confirmedLevel =
      historymeditation.length > 120 && historymeditation.length <= 360;
    const ExpertLevel = historymeditation.length > 360;
    if (levelsName === "") {
      setLevelsName("none");
    }

    if (beginnerLevel) {
      setLevelsName("beginner");
    }
    if (IntermedLevel) {
      setLevelsName("intermed");
    }
    if (confirmedLevel) {
      setLevelsName("confirmed");
    }
    if (ExpertLevel) {
      setLevelsName("expert");
    }
    // console.log("level", levelsName);
    localStorage.setItem("levels", levelsName);
  }, [levelsName, setLevelsName, historymeditation]);

  return (
    <Fragment>
      {historymeditation.length > 0 && historymeditation.length <= 60 ? (
        <img
          className="logoNiveauIcon"
          src={BlueLotus}
          alt="lotus"
          style={{ width: 95, height: 95 }}
        />
      ) : historymeditation.length > 60 && historymeditation.length <= 120 ? (
        <img className="logoNiveauIcon" src={PurpleLotus} alt="lotus" />
      ) : historymeditation.length > 120 && historymeditation.length <= 360 ? (
        <img className="logoNiveauIcon" src={RedLotus} alt="lotus" />
      ) : historymeditation.length > 360 ? (
        <img className="logoNiveauIcon" src={ExpertLotus} alt="lotus" />
      ) : null}
    </Fragment>
  );
};

export default LevelFlower;

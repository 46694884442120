import "./animateText.css";

const AnimateText = () => {
  return (
    <div className="container-text">
      <svg
        viewBox="0 0 1418 116"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>Amano'Hilot</title>
        <g stroke="none" fill="none" fillRule="evenodd" fillOpacity="0">
          <text
            id="@amanohilot"
            stroke="var(--animateTextStroke)"
            fill="var(--animateTextFill)"
            fontWeight="normal"
            fontFamily="PermanentMarker-Regular, Permanent Marker"
            fontSize="134"
          >
            <tspan x="3" y="109">
              <tspan>M</tspan>
              <tspan>y</tspan>
              <tspan>&ensp;</tspan>
              <tspan>M</tspan>
              <tspan>e</tspan>
              <tspan>d</tspan>
              <tspan>i</tspan>
              <tspan>t</tspan>
              <tspan>a</tspan>
              <tspan>t</tspan>
              <tspan>i</tspan>
              <tspan>o</tspan>
              <tspan>n</tspan>
              <tspan>&ensp;</tspan>
              <tspan>T</tspan>
              <tspan>i</tspan>
              <tspan>m</tspan>
              <tspan>e</tspan>
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default AnimateText;

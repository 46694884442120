import Wifi from "@material-ui/icons/Wifi";
import WifiOff from "@material-ui/icons/WifiOff";
import { useState } from "react";
import { useRecoilState } from "recoil";
import useNetwork from "../../hooks/useNetwork";
import clickedConnexionAtom from "../../statesManager/clickedConnexionAtom";
import "./networkModal-style.css";
import NetworkInfoTable from "./NetworkInfoTable";

const NetworkModal = () => {
  const networkState = useNetwork();
  const [clickedConnexion, setClickedConnexion] =
    useRecoilState(clickedConnexionAtom);
  const [clickedStats, setClickedStats] = useState(false);

  const handleClickStatsButton = () => {
    setClickedStats((clickedStats) => !clickedStats);
  };
  return (
    <div
      className={
        !clickedConnexion
          ? "modalConnexion hidden"
          : "modalConnexion slideModal"
      }
    >
      <div className="statsInfo">
        <div className="headerInfos">
          <h1>Offline mode:</h1>
          <button
            onClick={() => {
              setClickedConnexion(false);
            }}
          >
            X
          </button>
        </div>
        <div className="contentInfoModal">
          <div className="contentInfo-center">
            <h3>How does it work?</h3>
            <h5>
              The colour varies according to the round-trip time (RTT) of your
              connection.
            </h5>
            <sub style={{ fontSize: 11, width: "80%", fontStyle: "italic" }}>
              RTT is the time it takes for the signal to send and receive data.{" "}
            </sub>
            <br />

            <div className="icons-container">
              <div className="Icon-content">
                <Wifi style={{ color: "green" }} />
                <sub>Strong</sub>
                <p className="rtt">- than 100</p>
              </div>
              <div className="Icon-content">
                <Wifi style={{ color: "#93ce1c" }} />
                <sub>Medium</sub>
                <p className="rtt">100 and 200</p>
              </div>
              <div className="Icon-content">
                <Wifi style={{ color: "#c8840a" }} />
                <sub>Low</sub>
                <p className="rtt">200 and 375</p>
              </div>
              <div className="Icon-content">
                <Wifi style={{ color: "#ff5722" }} />
                <sub>Very low</sub>
                <p className="rtt">+ than 375</p>
              </div>
              <div className="Icon-content">
                <WifiOff style={{ color: "darkred" }} />
                <sub>Offline</sub>
                <p className="rtt">0</p>
              </div>
            </div>

            <br />
            <p>
              You can activate the aeroplane mode during your meditation
              sessions. meditation sessions, in which case the offline icon will
              be displayed.
            </p>
          </div>
          <div className="contentInfo-footer">
            <p>Your current signal strength</p>
            <button
              className="btn--primary statsButton"
              onClick={handleClickStatsButton}
            >
              {networkState.rtt}
            </button>
          </div>
        </div>
        {clickedStats ? <NetworkInfoTable data={networkState} /> : null}
      </div>
    </div>
  );
};

export default NetworkModal;

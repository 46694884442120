const InfosWhyData = [
  {
    id: 0,
    title: "Meditation and the ability to concentrate",
    content: [
      {
        id: 0,
        title: "Meditation increases the ability to concentrate",
        parag1:
          "Some forms of meditation such as vipassana meditation, mindfulness meditation or meditations based on concentration on objects or sensations are like muscle building exercises for your attention span. In this example, attention span or concentration is like a muscle that becomes more and more powerful through the practice of meditation. These meditations then allow us to increase the strength and endurance of your attention. This is a major benefit of meditation that can have an impact on all your activities.",
        title2: "Learning the present moment as a key to concentration",
        parag2:
          "With meditation, the practitioner is led to return again and again to the present moment, to the here and now. The present moment is not just an empty space in which we must do nothing in order not to lose it. On the contrary, the present moment is the moment of action, the moment of doing and the moment of pure creativity. When we learn through meditation to move towards this state of being, certain barriers fall away and we become more effective and focused in what we do.",
        title3: "Active meditations and concentration",
        parag3:
          "Among the many forms of meditation, active meditations can be a real springboard for your attention span. Indeed, if you apply active meditation as often as possible during the day, you will quickly realise how much your effectiveness will be enhanced. Active meditation can be practised at ANY time of the day and even during your work, making it much more effective.",
        title4: "In practice",
        parag4:
          "A simple exercise is to keep your attention on your breathing as often as possible. And, if you feel overwhelmed by your thoughts, pause for a minute with a timer for example to stop everything and observe the movements of your breath during that minute. This is just one of a huge number of techniques for getting back into the present moment and out of our thoughts.",
        title5: "Some studies on the effects of meditation on concentration:",
        parag5:
          "One study found that human resource workers who regularly practiced mindfulness meditation stayed focused on a task longer. These workers also remembered the details of their tasks better than their peers who did not practice meditation. Even a short period of meditation can benefit you. One study found that four days of meditation practice can be enough to increase attention span.",
        title6: "In a few words",
        parag6:
          "Meditation can be a great asset for your attention span and concentration. Applied in the world of work meditation is not counterproductive and quite the contrary! With the practice of meditation, people become more present to their work and more effective too.",
      },
    ],
  },
  {
    id: 1,
    title: "Meditation is good for creativity",
    sub: "Just like muscles, the brain needs to be maintained to stay at its best. As creativity is a tool for innovation in everyday life, it is important to cultivate it through activities that stimulate it. Meditation is one way to subtly energise your imagination.",
    content: [
      {
        id: 0,
        title: "Creativity: a history of complacency",
        parag1:
          "What is the most glaring difference between individuals who are capable of high creative potential and those who seem to lack it? The people who are most successful at tapping into their creativity are simply those who are most successful at letting go. Learning to let your mind wander, allowing yourself moments of contemplation, writing down all your ideas in a jumble are all activities that creative people naturally indulge in to fuel their imagination. To develop your creative side, there is nothing better than taking time for yourself: a sports session, a walk in the forest, a visit to a museum, a contemplative nap in a park, etc. All activities that give you the opportunity to let your mind wander and feed your thoughts are good to practice.",
        title2: "Meditation: for a higher level of creativity",
        parag2:
          "Meditation has been known for thousands of years for its benefits to the body and mind, and has proven to be a real benefit in stimulating creativity. By focusing on breathing exercises, deep exploration of the self and elevation of the spirit, meditation can provide access to a higher level of understanding of the world. As you work on your own spirituality, giving yourself a few meditation sessions a week is a great way to bring the well-being and calm your mind needs to stimulate your imagination. Whether at work, in relationships with others or at home, stimulated creativity gives you a fresh perspective on even the most intractable situations and adds to your daily life.",
        title3: "How to boost your creativity through meditation?",
        parag3:
          "Even if it's difficult to work out or to find an hour for a meditation session, think about arranging your schedule so that you can spare a few hours a week: you can, depending on the time you have, go to a group or individual class with a yoga teacher to practise breathing exercises and meditation, but you can also practise it alone at home. As soon as you get 10 minutes of peace and quiet, light some candles, burn an incense stick and sit in a quiet room (without a mobile phone or television). Sit on the floor and close your eyes: listen to your breathing, feel the beat of your heart, feel the vibrations of your environment,...Meditation is a moment just for you that will give you the opportunity to reconnect with your inner self. Avoid negative thoughts and take advantage of this moment to relax.",
      },
    ],
  },
  {
    id: 2,
    title: "Meditation stimulates the immune system",
    sub: "Meditation increases the effectiveness of the immune system for months, say US researchers.",
    content: [
      {
        id: 0,
        parag1:
          "They asked half of the 48 volunteers they recruited for their study to attend meditation classes once or twice a week. They also provided them with meditation exercises to do at home for one hour a day, six days a week.",
        parag2:
          "The researchers then found that the immune response generated by a flu shot was significantly greater in meditators than in non-meditators. Moreover, these benefits to the immune system, rather than being short-lived, were, in some cases, sustained for four months.",
        parag3:
          "The link between meditation and a stronger immune system is not clear to the scientists, but an electroencephalogram allowed them to determine that the meditating volunteers showed increased activity in the area of the brain associated with positive emotions.",
        parag4:
          "This link between state of mind and body health is supported by other research. Researchers at Georgetown University in Washington, D.C., for example, found that an angry outburst could clog the arteries of 30% to 60% of patients already suffering from cardiovascular disease. Again, the exact link remains unclear, but the scientists note that anger provokes an immune response that may cause the plaques that are already partially clogging the arteries to detach.",
        parag5:
          "Finally, relaxation techniques taught to children suffering from chronic abdominal pain - often with no apparent medical explanation - resulted in a 67% reduction in the number of days the pain was felt within two months.",
      },
    ],
  },
  {
    id: 3,
    title: "Meditation for a better memory",
    content: [
      {
        id: 0,
        parag1:
          " The practice of meditation strengthens the brain circuits associated with memory, while it has a positive effect on mood disorders, particularly depression.",
        parag2:
          "People aged 55 (on average) were included in this American study (University of Los Angeles). Two groups were formed: for three months, some participated in kirtan kriya sessions, a meditation derived from yoga and based on singing and finger movements, while the others practised classic memory exercises.",
        parag3:
          "At the end of the follow-up, the tests show «a statistically significant improvement» in memory scores in all participants, but greater in the meditation group than in the exercise group, especially for spatial, visual and verbal memory. The positive influence of meditation was also noted with regard to depressive and anxiety symptoms.",
        parag4:
          "What is at least as remarkable, say the researchers, are the observations of the brain, since the MRI (magnetic resonance) shows a notable strengthening of the connections between different areas of the brain involved in the circuits of memorization (specialists speak of «functional connectivity»), with better capacities verified by the tests. How can this effect of meditation be «scientifically» explained? This is a question that has not (yet) been answered.",
      },
    ],
  },
  {
    id: 4,
    title: "Meditation works on our stress",
    sub: "Resisting stress sometimes requires patience and practice! If you are often subject to it, you know that it is important to keep a few anti-stress techniques on hand, which are easy to mobilise. Discover 3 soothing exercises to regain your calm quickly!",
    content: [
      {
        id: 0,
        title: "Exercise 1: 5 minutes of mindfulness meditation",
        sub: "Level of difficulty: easy. ",
        parag1:
          "Mindfulness is inspired by meditation and yoga. To combat stress, it suggests focusing your attention on the present moment. It allows you to better manage your negative thoughts by focusing on your sensations. Thanks to this tool, you will be able to reduce your nervousness, limit the effects of stress and take a step back from the events that make you feel unwell. To free yourself from stress and put things into perspective, sit down in a quiet place. In the lotus position or cross-legged, keep your back straight - this helps you to breathe well - and close your eyes. Breathe in and out deeply to reduce the stress you feel. Mindfulness focuses on the present moment; live it without reservation! To learn how to manage stress, it is not always necessary to clear your mind. Let your thoughts go, without judging them. Concentrate on your breathing, your muscles. Do you feel your mind slipping away into stressful thoughts? Bring it back to the «here and now». Practice this exercise at least once a day to help you let go, relieve stress or calm yourself before a stressful moment.",
        title2: "Exercise 2: 15 minutes OFF",
        parag2:
          "The STOP exercise proposes an action linked to each letter of the acronym, to be performed one after the other. It stimulates your senses to help you divert your attention from the stressor. Do this exercise at home for comfort. The A, for STOP, is about eliminating stress. Stop whatever you are doing. You can close your eyes if that helps. The R stands for Breathe. Focus on your breath. If your heart rate has increased due to stress, calm it down by taking deep breaths. Focus on 10 breaths. The second R is for Look. Deal with stress by looking at your environment. Observe it as if you were discovering it for the first time.E is for Listen. It is possible to deal with stress by listening to every sound. Can you hear the silence? Your colleagues talking? The chirping of birds? Listen without judgement, with conscious attention.T is for Touch. Stress control is easier when you touch one or more objects: your clothes, a pen, your face. Once you have managed to «forget» the stress, gradually return to your normal routine. To escape the stress of everyday life, practice this exercise 2 or 3 times a week.",
        title3: "Exercise 3: 30 minutes of Zen meditation",
        parag3:
          "Zen meditation helps reduce anxiety and improve stress management. It helps you to free yourself from your anxieties. To achieve this, it invites you to clear your mind. At the end of the session, your mind will be calm and relaxed, and you will regain control of your thoughts. When you feel stressed, sit down in a quiet room. Facing the wall, in a lotus or half-lotus position, make sure that your knees are firmly rooted in the ground for these breathing exercises. With your back and head straight, keep your chin tucked in and your shoulders relaxed. Eyes half closed, your left hand resting on your right, palms facing the sky. You can breathe normally if that is enough to relieve stress, or you can breathe in and out more deeply. As you hold this position and stay focused, you will feel calm. This anti-stress method shows its full potential after 30 minutes of practice. It won't take long for your mind to be calmed. You can deal with stress in this way as often as you feel the need. Two sessions a week should reduce your stress.",
        parag4:
          "These 3 exercises can help you reduce your stress level. They allow you to acquire two essential basics: slow, deep breathing and focusing your attention on an object in your environment. This will help you in all circumstances! If one of the exercises doesn't work for you, simply move on to the next one. You will see that with «serious» practice, you will soon be able to relax and act against stress without thinking about it!",
      },
    ],
  },
  {
    id: 5,
    title: "Meditation and anxiety.",
    sub: "Meditation allows us to enter into a relationship with our anxieties in order to better understand them and find peace. Here are 3 exercises to avoid letting anxiety get to you",
    content: [
      {
        id: 0,
        parag1:
          "In Asia, for more than 3500 years, meditation has helped people to be calmer, more at peace and therefore happier with themselves, their surroundings and their environment. Exported to the West, this practice has several levels: physiological, physical and spiritual. Each person can appropriate it according to their needs, to the degree they wish.",
        parag2:
          "Meditation remains one of the best anti-stress weapons today, says meditation teacher Giovanni Dienstmann, author of À chacun sa méditation (published by Au fil de soi). Because focusing on the present moment lowers levels of cortisol, the stress hormone. In 2016, an American study even showed that regular meditation practice had more positive effects on stress than holidays!",
        title3: "learn to take breaks",
        parag3:
          "It allows us to free ourselves from the stream of thoughts that constantly crosses our mind and to be fully awake to the present moment. To become aware of each moment, of your body, of others and of your environment and to accept them as they are.",
        title4: "Step 1",
        parag4:
          "Take breaks several times a day with your eyes closed for 10 full breaths (natural inhalation and exhalation) without straining. This allows you to rest your brain and calm yourself. Ideally, repeat this transition as soon as you change activity (after completing a file, making a phone call, etc.). It's a break for the mind, an opportunity to unplug for a few dozen seconds.",
        title5: "Step 2",
        parag5:
          "When you are waiting (when you are in the queue at the supermarket for example), rather than listening to music or tapping on your smartphone, refocus on yourself: your body, your breathing, what you see, the sounds you hear (birdsong is particularly audible at the moment!). Instead of being in action, distraction, rumination or reflection, you will be in a soothing feeling for the mind.",
        title6: "get in touch with yourself",
        sub6: "Step 3",
        parag6:
          "Sit comfortably with your eyes closed. You don't have to cross your legs at all, let alone adopt the lotus position. Don't try to control what comes into your mind. But let yourself be in touch with yourself, without any particular thought or goal, without wanting to change anything. Just try to be there, in the present moment, as if in a bubble: this attitude increases lucidity and self-awareness.",
      },
    ],
  },
  {
    id: 6,
    title: "Meditation builds up the brain's grey matter",
    sub: "People who meditate frequently at age 50 regain the same amount of grey matter as at age 25. A practice of 15 to 20 minutes a day offers significant results. An American neuroscientist found that people who regularly meditated had more grey matter in their frontal cortex, the part of the brain linked to decision-making and short-term memory.",
    content: [
      {
        id: 0,
        title: "MORE GREY MATTER IN MEDITATORS",
        parag1:
          "Following an injury, neuroscientist Sara Lazar of Harvard Medical School took up yoga and saw a marked improvement in her ability to manage her emotions and empathise. This prompted her to look at the scientific literature on mindfulness meditation, which yoga can be part of, and found increasing evidence that meditation not only reduces stress, depression and anxiety, but also improves sleep and well-being for those who practice it regularly.",
        parag2:
          "In her first study, the neuroscientist compared long-time meditators (between 6 and 9 years of experience) with a control group, and it turned out that the former had a large increase in the amount of material in several areas of their brains, such as the auditory and sensory cortex, but also the frontal cortex, which is linked to decision making and short-term memory. While most people's cortexes 'shrink' as they age, the 50-year-old meditators had the same amount of grey matter as the 25-year-olds.",
        title3: "PROVEN BENEFITS",
        parag3:
          "To ensure that long-time meditators did not originally have more grey matter, Lazar and his team conducted a second study, in which subjects who had never practised mindfulness meditation underwent an eight-week programme. And the results were not long in coming: at the end of the programme, the neuroscientists noted a densification of several regions of the brain, involved in learning, memorising, emotional stability or feeling empathy.",
        parag4:
          "The researchers also found a reduction in the amygdala, a region of the brain associated with fear, anxiety and aggression, in the new meditators, which correlated with their reduced stress levels. Since then, Lazar has conducted further research, which has shown increased compassion and overall improvement in mental health in people who have experienced severe trauma and have taken up mindfulness meditation.",
        parag5:
          "On average, the subjects in whom such results were observed meditated for 27 minutes a day, but several studies suggest that significant positive changes can be observed with daily sessions of 15 to 20 minutes. But according to Sara Lazar, the most important thing is that the practice is sustained over time. «It's a lot like exercise. Exercising three times a week is good. But if you can only do a little every day, that's also good,» concludes the researcher.",
      },
    ],
  },
  {
    id: 7,
    title: "Emotions at the heart of ill-being",
    sub: "Emotion is both a physical and psychological response that everyone is familiar with. Major emotions such as anxiety, anger, guilt, shame, sadness are normal but can also have a negative role on our well-being. Many states of stress and unhappiness such as anxiety and depression are marked by excessive and persistent negative emotions. Many specialists now consider that the difficulty in managing emotions explains the occurrence and maintenance of these states. When faced with a negative emotion triggered by stress, there are two opposing reflexes: the tendency to repress this emotion, to fight it, to refuse to be invaded, and the opposite tendency, which consists in accepting one's own emotion.",
    content: [
      {
        id: 0,
        sub1: "The hypothesis is that the refusal of a feeling has the paradoxical effect of triggering an emotion on the contrary. This is why, when faced with a feeling of injustice or a conflict, we can be led to ruminate, and to feel an emotion of anger, anxiety or sadness. All this is understandable, but unfortunately it is not always effective, and on the contrary, the invasion of negative thoughts and emotions is a source of ill-being.",
        title: "Relaxation: a therapy for the emotions",
        parag1:
          "In states of stress, anxiety and depression, self-induced thoughts and feelings sustain the ill-being and are not always accessible to rational reasoning. It is impossible for the person to change by will alone. A new psychotherapeutic approach focuses on the management of emotions. It consists more in accepting one's own emotions than in trying to repress them at all costs. Relaxation plays an important role in this new medicine of the emotions. Relaxation does not only induce relaxation and a better physiological and psychological control of the body, it also allows to better confront negative emotions and to induce positive emotions. Many relaxation and meditation techniques are known, some very old like Yoga and Zen, others more recent like Schultz's Autogenic Training, Jacobson's muscle relaxation, sophrology, hypnosis... These techniques all have their interest but they are not always easy to put in place either because they require a long commitment or because they do not meet the expectations and problems. A new approach to relaxation is now proposed based on these classic methods but also on more recent techniques such as mindfulness meditation or biofeedback through cardiac coherence.",
        title2: "Relaxation and meditation: the most effective techniques",
        parag2:
          "Relaxation and meditation are based on 4 basic techniques: breath control, muscle relaxation by contraction - relaxation or by self-suggestion, mindfulness meditation and visualisation. Through simple exercises, which can be carried out alone, one can train for 5 to 10 minutes a day at the beginning and then have a real toolbox for dealing with emotions. There is a growing body of scientific evidence that confirms the value of these techniques, particularly muscle relaxation and meditation, in improving physical well-being, managing stress and preventing anxiety and depression. They represent a good alternative and complement to other therapies (medication, psychoanalysis and psychotherapy...). It is important to use techniques that allow one to deal with stress and negative emotions in daily life, and to do so, a shorter and more concrete learning process will be favoured. It is therefore necessary to focus not only on relaxation but also on refocusing thoughts as in mindfulness meditation techniques.",
        title3:
          "What do new approaches such as mindfulness meditation and cardiac coherence biofeedback bring to the management of emotions?",
        parag3:
          "Mindfulness meditation has recently been proposed by a group of psychologists, particularly Jon Kabat Zinn, as a new application of Zen and Buddhist meditation techniques while leaving the spiritual and religious aspects aside to make it accessible to a large number of people. The principle is to learn to get out of the pattern, to be on automatic pilot to become aware of each moment and not to be focused on oneself. Regular practice offers a way to stay in the present, while offering a different perspective on things, and to stay away from negative ruminations. The aim is to increase awareness of what we are doing, here and now, in the present moment. The exercises are simple in principle but require daily practice. The main ones are centring on the breath, body scanning and refocusing on daily activities (walking, eating...).",
        parag4:
          "These relaxation and meditation techniques can be coupled with a new tool from new technologies called heart variability biofeedback. Cardiac variability is now recognised as a very good indicator of the ability to regulate emotions because it reflects the way in which our heart constantly adapts to stress by modifying the heart rate. Cardiac variability can be measured today thanks to small sensors integrated into bracelets that are worn on the wrist and connected to a simple computer. Using software, cardiac variability is represented and monitored on the screen in the form of a curve or graph. Some very innovative programmes now offer the possibility of monitoring and improving cardiac variability through 3D animations similar to those in video games (see www.symbiofi.com).",
        parag5:
          "These are not games but exercises whose approach reinforces the biofeedback effect and allows the person, through breathing control exercises but also meditation and visualisation, to act by himself on his cardiac variability to approach an ideal state of cardiac coherence. Recent studies show that regular training in cardiac coherence through biofeedback reduces the risk of recurrence in cardiac patients and appears to be a promising complementary tool in the management of stress and anxiety. It provides a better ability to regulate emotions in the face of stress both at the cardiac and central nervous system levels. Indeed, scientific knowledge today demonstrates the existence of an interconnection between the heart and the brain. It explains how everyone can act on emotions through relaxation and meditation but also by better controlling heart variability.",
        parag6:
          " Dr Dominique Servant, a psychiatrist and psychotherapist, is in charge of the specialised stress and anxiety unit at the Lille University Hospital. He is one of France's leading specialists in stress and anxiety and is the author of two self-help guides Relaxation and Meditation. Trouver son équilibre émotionnel and Soigner le stress et l'anxiété par soi même, both published by Editions Odile Jacob.",
      },
    ],
  },
  {
    id: 8,
    title: "Meditation boosts your fertility?",
    content: [
      {
        id: 0,
        title:
          "Scientific research published in the journal Human Reproduction indicates that «stress doubles the chances of infertility.»",
        parag1:
          "Scientists measured the level of alpha-amylase, an enzyme found in saliva that provides a biological indicator of stress. Women with high levels of the biomarker were 29% less likely to get pregnant each month than those with low levels.",
        parag2:
          "To carry out this study, the scientists followed 373 American women aged 18 to 40 with no known fertility problems who wanted to have a child. Their progress was monitored for 12 months or until they became pregnant.",
        title3:
          "Meditation improves fertility by reducing stress, better regulating emotions, and building resilience.",
        parag3:
          "Nancy Bardacke is a midwife and creator of a mindfulness-based pregnancy and parenting programme at the University of California. She says mindfulness is the best tool she knows of for women. It helps to «manage life's difficulties, reduce anxiety, depression and stimulate positive emotions.»",
        parag4:
          "Indeed, the period from the conception of a child to the first years is a formidable phase of openness and discovery. Meditation helps to reduce stress, better regulate emotions, and develop resilience. These skills are also useful in future child-rearing. It helps to develop peaceful family relationships, and to learn to take care of oneself even when everything is hectic around us.",
      },
    ],
  },
  {
    id: 9,
    title: "Meditation reduces pain",
    content: [
      {
        id: 0,
        title: "Irritable bowel syndrome: meditation eases the pain",
        parag1:
          "Practising mindfulness meditation may help reduce pain and other symptoms associated with irritable bowel syndrome.",
        parag2:
          "This was the finding of a small US study of 75 women aged 19 to 71, which compared the effects of two types of intervention: mindfulness meditation and participation in a support group.",
        parag3:
          "The result was that mindfulness meditation was significantly more effective than participation in a support group. The meditation technique consisted of focusing on the present moment, paying attention to body sensations and emotions, but without making value judgements.",
        parag4:
          "It seems that this form of meditation has been particularly beneficial in reducing abdominal pain and bloating. Similarly, it has been shown to reduce psychological distress as this condition, which is difficult to treat, affects quality of life.",
        parag5:
          "For example, after 8 weeks of meditation training, symptom severity was reduced by an average of 26.4% in the participants, compared to 6.2% in the support group. The effect was even more marked 3 months after the end of the study: the reduction had risen to 38.2% in the meditators compared with 11.8% in the others.",
        parag6:
          "The researchers stress that this technique should not be seen as a medical treatment, but rather as a teaching and a way to better manage the disease in everyday life. These results will now need to be confirmed by further studies with a larger sample.",
      },
    ],
  },
  {
    id: 10,
    title: "Meditation and hypertension",
    sub: "Known to reduce stress, mindfulness meditation is also believed to help lower blood pressure in people with pre-hypertension.",
    content: [
      {
        id: 0,
        title: "Hypertension: meditation can reduce it",
        parag1:
          "Mindfulness meditation is a relaxation practice that allows you to let go and de-stress. A recent study has shown that this form of meditation is particularly suitable for people who are anxious or highly stressed. But now a new study, conducted by researchers at Ohio University, shows that mindfulness meditation is particularly effective in lowering blood pressure in people with pre-hypertension.",
        parag2:
          "Pre-hypertension is a blood pressure that is higher than normal but not high enough to take medication. This rise in blood pressure can be accompanied by headaches, dizziness or buzzing. This is why Prof. Joel Hughes' team has decided to integrate yoga and mindfulness meditation into the treatment of pre-hypertensive patients, in the hope of delaying the switch to medication.",
        parag3:
          "The researchers enrolled 56 patients in a stress reduction group. All were invited to group meditation sessions and asked to meditate mindfully at home as well. At the end of these sessions, their blood pressure had fallen, albeit modestly, but enough to prevent them from taking anti-hypertensive medication.",
        parag4:
          "The ideal peak blood pressure is 14/9 (13/8 for diabetics). If your blood pressure exceeds 14/9 during a consultation, a new check will be necessary. Only if the figures are high at rest and on three occasions will the doctor consider you to be hypertensive and prescribe treatment.",
      },
    ],
  },
  {
    id: 11,
    title: "Meditation, a natural anti-inflammatory",
    sub: "After its benefits on stress, anxiety and high blood pressure, mindfulness meditation could also be effective against inflammation. Explanations.",
    content: [
      {
        id: 0,
        parag1:
          "Mindfulness meditation consists of perceiving what is going on in and around us, welcoming our different sensations without judging them.",
        parag2:
          "For the first time, an international study conducted by researchers from the universities of Lyon in France, Barcelona in Spain and Wisconsin-Madison in the United States has shown the value of mindfulness meditation in reducing inflammation.",
        title3: "Effective after 8 hours of practice",
        parag3:
          "In the case of inflammatory pain, it is therefore necessary to focus one's mind on the painful sensations felt, and to welcome them. After eight hours of mindfulness meditation practice, researchers have observed biological changes. There is a reduction in the genes directly involved in inflammatory processes, and a good level of cortisol, which is an anti-inflammatory hormone.",
        title4: "No side effects",
        parag4:
          "Mindfulness meditation could therefore be used as an anti-inflammatory treatment. It is effective and has no side effects, whereas non-steroidal anti-inflammatory drugs prescribed for inflammation can cause bleeding, ulcers and even cardiovascular problems if taken long-term.",
      },
    ],
  },
  {
    id: 12,
    title: "What are the benefits of meditation?",
    sub: "Take some quiet time, reconnect with yourself, enjoy the present moment... let's review the main benefits of meditation.",
    content: [
      {
        id: 0,
        parag1:
          "Meditation is based on a principle: that of «understanding the nature of your mind rather than battling with it», say the specialists on the Meditation France website. This approach, often mistakenly associated with people who are out of touch with real life, is in fact «a quality in you that is simply detachment, or in other words, inner vigilance and full awareness in the present moment». There are many resources (such as Vipassana meditation, Zazen, Osho's active meditations, Tantric meditations, Buddhist meditations) for entering meditation. Whatever your preferred practice, here are the benefits achievable through regular meditation:",
        title2:
          "Detach yourself from patterns or feelings that you may have acquired in spite of yourself. ",
        parag2:
          " « Meditation simply means learning to forget everything you have learned. It is a process of deconditioning. »",
        title3: "Clear your head.",
        parag3:
          "We live in an incessant flow of information, we evolve in a world that runs at 1,000 miles an hour with a concern for performance that is often overwhelming. Meditation is «a process of complete cleansing of your slate, it empties everything that has been forced, stuffed into you».",
        title4: "Know yourself.",
        parag4:
          "By clearing your mind, you make room for calm and tranquillity. In this way you connect to your sometimes buried, sometimes muted self. But what process is at work? By being still, the mind «is aware of every movement of thought and emotion». This allows «to know the hidden, secret, deep activities of the consciousness».",
        title5: "Letting go of control.",
        parag5:
          "By meditating, you get rid of «the superficial, conscious mind, absorbed in its daily activities: earning money, cheating people, exploiting, escaping from problems - all the daily activities of our existence,» says the book «On Self-Knowledge». You connect to the present moment, without judgement, simply by looking at what is happening in your body. «Therefore, there is no opposition or emotional negativity. You accept the moment as it is. You drive out «negative action, which is the result of anger, despair or frustration». You might as well say that your stress level decreases dramatically!",
        title6: "Improve your social relations.",
        parag6:
          "«Not letting go hardens the shell of the ego, and creates a strong sense of dissociation. You perceive the world around you and people in particular as a threat. This is accompanied by the unconscious compulsion to destroy others through judgement, and the need to compete and dominate.» Meditation frees everyone!",
      },
    ],
  },
];

export default InfosWhyData;

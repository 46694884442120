import styled from "styled-components";
import HomeIcon from "../../images/icons8-lotus-64.png";
import InfoIcon from "../../images/icons8-yin-yang-100.png";
import CreateIcon from "../../images/icons8-anahata.png";
import EmailIcon from "../../images/icons8-om-60.png";
import "./bottomNavigation.css";
import HelpIcon from "../../images/icons8-spa-flower-80.png";
import { useHistory } from "react-router-dom";
import useSwitchIconsNavigation from "../../hooks/useSwitchIconsNavigation";

const BottomNavigation = () => {
  const {
    navigationName,
    handleChangeHome,
    handleChangeWhy,
    handleChangeHow,
    handleChangePresets,
    handleChangeSession,
  } = useSwitchIconsNavigation();
  let history = useHistory();

  return (
    <Styles.Wrapper className="bottom-content">
      <MobileNavbar.Wrapper className="navWrapper">
        <MobileNavbar.Items>
          <MobileNavbar.Item>
            <button
              style={{ position: "relative" }}
              onClick={() => {
                handleChangeHome();
                history.push("/");
              }}
            >
              {navigationName === "home" ? (
                <img
                  className="bottomMenuIcons animateIcon"
                  src={HomeIcon}
                  alt=""
                />
              ) : (
                <img className="bottomMenuIcons active" src={HomeIcon} alt="" />
              )}
              <label
                className={
                  navigationName === "home"
                    ? "navigationLabels"
                    : "labelsWithoutBorder"
                }
              >
                Home
              </label>
            </button>
          </MobileNavbar.Item>
          <MobileNavbar.Item>
            <button
              style={{ position: "relative" }}
              onClick={() => {
                handleChangeWhy();
                history.push("/why");
              }}
            >
              {navigationName === "why" ? (
                <img
                  className="bottomMenuIcons animateIcon"
                  src={HelpIcon}
                  alt=""
                />
              ) : (
                <img className="bottomMenuIcons active" src={HelpIcon} alt="" />
              )}
              <label
                className={
                  navigationName === "why"
                    ? "navigationLabels"
                    : "labelsWithoutBorder"
                }
              >
                Why?
              </label>
            </button>
          </MobileNavbar.Item>
          <MobileNavbar.Item>
            <button
              style={{ position: "relative" }}
              onClick={() => {
                handleChangeHow();
                history.push("/how");
              }}
            >
              {navigationName === "how" ? (
                <img
                  className="bottomMenuIcons animateIcon"
                  src={InfoIcon}
                  alt=""
                />
              ) : (
                <img className="bottomMenuIcons active" src={InfoIcon} alt="" />
              )}
              <label
                className={
                  navigationName === "how"
                    ? "navigationLabels"
                    : "labelsWithoutBorder"
                }
              >
                How?
              </label>
            </button>
          </MobileNavbar.Item>
          <MobileNavbar.Item>
            <button
              style={{ position: "relative" }}
              onClick={() => {
                handleChangePresets();
                history.push("/presets");
              }}
            >
              {navigationName === "presets" ? (
                <img
                  className="bottomMenuIcons animateIcon"
                  src={CreateIcon}
                  alt=""
                />
              ) : (
                <img
                  className="bottomMenuIcons active"
                  src={CreateIcon}
                  alt=""
                />
              )}
              <label
                className={
                  navigationName === "presets"
                    ? "navigationLabels"
                    : "labelsWithoutBorder"
                }
              >
                Presets
              </label>
            </button>
          </MobileNavbar.Item>
          <MobileNavbar.Item>
            <button
              style={{ position: "relative" }}
              onClick={() => {
                handleChangeSession();
                history.push("/session");
              }}
            >
              {navigationName === "session" ? (
                <img
                  style={{ filter: "sepia(1) contrast(0.8)", opacity: "0.5" }}
                  className="bottomMenuIcons animateIcon"
                  src={EmailIcon}
                  alt=""
                />
              ) : (
                <img
                  className="bottomMenuIcons active"
                  src={EmailIcon}
                  alt=""
                />
              )}
              <label
                className={
                  navigationName === "session"
                    ? "navigationLabels"
                    : "labelsWithoutBorder"
                }
              >
                Session
              </label>
            </button>
          </MobileNavbar.Item>
        </MobileNavbar.Items>
      </MobileNavbar.Wrapper>
    </Styles.Wrapper>
  );
};

const Styles = {
  Wrapper: styled.main`
    display: flex;
    background-color: var(--MenuColor);
    height: 80px;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    align-items: center;
    justify-content: center;
  `,
};
const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    width:100%,
    align-self: flex-start;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--MenuColor);
  `,
  Logo: styled.h1`
    border: 1px solid gray;
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
    // margin-left: 5vw;
    // margin-right: 28px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  Item: styled.li`
    padding: 0 0.1rem;
    cursor: pointer;
  `,
};

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 0px 11px var(--menuShadow);
    // height: 65px;
    justify-content: center;
    z-index: 99;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0;

    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)``,
};

export default BottomNavigation;

import { useEffect, useState } from "react";
import "./minutesPickerStyle.css";
import InfoIcon from "../../../images/info.svg";
import useSweetAlertComponent from "../../../baseLayout/sweetAlertComponent/useSweetAlertComponent";
import { isMobile } from "react-device-detect";
import useScrollWithButton from "../../../hooks/useScrollWithButton";
import useCreateRange from "../../../hooks/useCreateRange";

const MinutesPickerComponent = ({
  setTimerMins,
  timerMins,
  selectTime,
  setSelectTime,
}) => {
  const { createRange, range } = useCreateRange();
  const [arrayTime] = useState([range]);
  const [clickedHelp, setClickedHelp] = useState(false);
  const { selectNumberHelp } = useSweetAlertComponent();

  const handleClickHelp = () => {
    setClickedHelp((clickedHelp) => !clickedHelp);
  };

  useEffect(() => {
    if (arrayTime.length < 120) {
      createRange(0, 120);
    }
    if (clickedHelp && isMobile) {
      selectNumberHelp().then((clickedHelp) => {
        if (clickedHelp) {
          setClickedHelp(false);
        }
      });
    }
    // console.log("selectTime", selectTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayTime, clickedHelp, selectTime]);

  const { slide } = useScrollWithButton();

  return (
    <div
      className={
        timerMins ? "minuteSlider minuteSlider-selected" : "minuteSlider"
      }
    >
      <div onClick={handleClickHelp} className="info-icon">
        <img src={InfoIcon} alt="icon" style={{ width: 20, height: 20 }} />
      </div>
      <div
        onClick={() => {
          slide("minuteContent", "top");
          setSelectTime(true);
        }}
        className="tap_area value_up"
      >
        <div
          className={timerMins ? "arrow up arrow-selected" : "arrow up"}
        ></div>
      </div>
      <ul className="minuteContent" id="minuteContent">
        {arrayTime[0]?.map((res) => (
          <li
            onClick={() => {
              setTimerMins(res);
              setSelectTime(true);
            }}
            key={res}
            className={
              res === timerMins && res !== 0
                ? `minuteLists number-selected minuteLists${res}`
                : `minuteLists minuteLists${res}`
            }
          >
            {res}
          </li>
        ))}
      </ul>
      <div
        onClick={() => {
          slide("minuteContent", "bottom");
          setSelectTime(true);
        }}
        className="tap_area value_down"
      >
        <div
          className={timerMins ? "arrow down arrow-selected" : "arrow down"}
        ></div>
      </div>
    </div>
  );
};

export default MinutesPickerComponent;

import AnimateText from "../animateText/AnimateText";
import logo from "../../logo-app2.svg";
import FullLogo from "../../images/logo-choices/fulllogo.jpg";
import LogoC from "../../images/logo-choices/logo-app2c.svg";
import BaseLayout from "../../baseLayout/BaseLayout";
import StartButton from "../startButton/StartButton";
import FloatingButton from "../../githubFloatingButton/FloatingButton";
import { Fragment, useEffect, useState } from "react";
import RedLotus from "../../images/red-lotus.svg";
import BlueLotus from "../../images/blue-lotus.svg";
import PurpleLotus from "../../images/pink-lotus.svg";
import ExpertLotus from "../../images/lotus-contours.svg";
import LevelFlower from "./levels/LevelFlower";
import LevelLogo from "./levels/LevelLogo";
import LevelText from "./levels/LevelText";
import newLevelAtom from "../../statesManager/newLevelAtom";
import { useRecoilState } from "recoil";
import clickedChildZoneAtom from "../../statesManager/clickedChildZoneAtom";
import ButtonChildSection from "../childSection/ButtonChildSection";
import ChildPage from "../childSection/ChildPage";
import historymeditationAtom from "../../statesManager/historymeditationAtom";
import useSweetAlertComponent from "../../baseLayout/sweetAlertComponent/useSweetAlertComponent";
import useTransitionControl from "../../hooks/useTransitionControl";
import navigationNameAtom from "../../statesManager/navigationNameAtom";
import "../../common-styles/animation.css";
import {
  defaultStyle,
  transitionStyles,
} from "../../common-styles/transitionStyleConstants";
import StartingAppLoader from "../../baseLayout/startingAppLoader/StartingAppLoader";
import AnimAppName from "../../baseLayout/startingAppLoader/AnimAppName";

const Home = (props) => {
  const [navigationName] = useRecoilState(navigationNameAtom);
  const [clickedChildZone, setClickedChildZone] =
    useRecoilState(clickedChildZoneAtom);
  // eslint-disable-next-line no-unused-vars
  const [alertClickedChild, setAlertChild] = useState(false);
  const [newLevel] = useRecoilState(newLevelAtom);
  const [historymeditation] = useRecoilState(historymeditationAtom);
  const { enterChildZoneAlert, confirmedEnterChildZoneAlert } =
    useSweetAlertComponent();
  const [startingApp, setStartingApp] = useState(true);
  const [state, enter, exit] = useTransitionControl();
  const isStartedApp = sessionStorage.getItem("started") !== null;

  const handleClickChildZone = () => {
    if (!clickedChildZone) {
      enterChildZoneAlert().then((result) => {
        if (result.isConfirmed) {
          confirmedEnterChildZoneAlert();
          setClickedChildZone((clickedChildZone) => !clickedChildZone);
          setTimeout(() => {
            setAlertChild(false);
          }, 2500);
        }
      });
    }
    if (clickedChildZone) {
      setClickedChildZone((clickedChildZone) => !clickedChildZone);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  const style = {
    ...defaultStyle,
    ...(transitionStyles[state] ?? {}),
  };

  useEffect(() => {
    if (navigationName === "home" && state !== "entered") {
      enter();
    }

    if (navigationName !== "home" && state === "exiting") {
      exit();
    }
  }, [state, navigationName, enter, exit]);

  useEffect(() => {
    if (isStartedApp) {
      setStartingApp(false);
    }
    if (!isStartedApp) {
      setTimeout(() => {
        setStartingApp(false);
        sessionStorage.setItem("started", "yes");
      }, 4800);
    }
  }, [isStartedApp]);

  return startingApp ? (
    <StartingAppLoader />
  ) : (
    <BaseLayout>
      {clickedChildZone ? (
        <Fragment>
          <ButtonChildSection
            clickedChildZone={clickedChildZone}
            handleClickChildZone={handleClickChildZone}
          />
          <div className="App-child">
            {" "}
            <ChildPage />{" "}
          </div>
        </Fragment>
      ) : (
        <header style={style} className={`App-header`}>
          <p className="copyright">Copyright - Rodolphe Augusto - 2021</p>

          <ButtonChildSection
            clickedChildZone={clickedChildZone}
            handleClickChildZone={handleClickChildZone}
          />

          <span
            className={newLevel ? "logoNiveau  from-top-flower" : "logoNiveau "}
          >
            <LevelFlower
              historymeditation={historymeditation}
              BlueLotus={BlueLotus}
              PurpleLotus={PurpleLotus}
              RedLotus={RedLotus}
              ExpertLotus={ExpertLotus}
            />
          </span>

          <AnimateText />
          <br />

          <div className={newLevel ? "rotate-center " : ""}>
            <LevelLogo
              FullLogo={FullLogo}
              logo={logo}
              LogoC={LogoC}
              historymeditation={historymeditation}
            />
          </div>
          <br />
          <AnimAppName />
          <h2
            style={{
              fontSize: 15,
              color: "darkgrey",
              wordBreak: "break-word",
              position: "relative",
              width: "100%",
            }}
          >
            <LevelText historymeditation={historymeditation} />
          </h2>
          <StartButton />

          <FloatingButton />
        </header>
      )}
    </BaseLayout>
  );
};

export default Home;

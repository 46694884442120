import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AttendanceBadgeIcon from "../../images/attendance-home.svg";
import AttendanceHalfBadgeIcon from "../../images/attendance-half.svg";
import logo from "../../logo-app2.svg";
import LogoChild from "../../components/childSection/assets/logo-app2.svg";
import LevelsIcon from "../../images/levels.svg";
import levelOfMomentAtom from "../../statesManager/levelsOfMomentAtom";
import useConstants from "../levelBadges/useConstants";
import CuriousIcon from "../../images/curiousBadge.svg";
import SwipeUp from "../../images/swipe-up.svg";
import Help from "../../images/help.gif";
import BestLearnIcon from "../../images/bestLearner.svg";
import usePercentAssiduity from "../../hooks/usePercentAssiduity";

const useSweetAlertComponent = () => {
  const MySwal = withReactContent(Swal);
  const [pourcentAssiduity] = usePercentAssiduity();

  const {
    BeginnerLevel,
    IntermedLevel,
    ConfirmedLevel,
    ExpertLevel,
    AbsoluteExpertLevel,
    progressBeginnerLevelValue,
    progressIntermedLevelValue,
    progressConfirmedLevelValue,
    progressExpertLevelValue,
  } = useConstants();
  const [levelOfMoment] = useRecoilState(levelOfMomentAtom);

  const attendanceAlerts = () =>
    MySwal.fire({
      title: "Your assiduity!",
      text: "This badge only appears if you are a regular!",
      html: `
        <div>
        <strong>You are assiduous.</strong>
          </div>
          <div class="progress-linear">
            <div class="${
              BeginnerLevel
                ? "bar-linear colorBlue"
                : IntermedLevel
                ? "bar-linear colorPurple"
                : ConfirmedLevel
                ? "bar-linear colorRed"
                : ExpertLevel || AbsoluteExpertLevel
                ? "bar-linear colorBlack"
                : ""
            }" style="width:${pourcentAssiduity}%">
            </div>
            <p class="${"percent-linear text-white"}">${pourcentAssiduity}%</p>
          </div>
          <br />
          <span class="levels-content"><p>This badge is only present if you are doing sessions <strong>regularly, </strong> <br /><br />otherwise it <strong>disappear</strong>!</p>

        </div>`,
      footer: `${
        pourcentAssiduity === 100
          ? "Well done, keep it up, you are really on the right track!"
          : "Beware, you are at 50% attendance, the badge may disappear very soon."
      }`,
      imageUrl:
        pourcentAssiduity === 100
          ? AttendanceBadgeIcon
          : AttendanceHalfBadgeIcon,
      imageWidth: 200,
      imageHeight: 100,
      imageAlt: "Custom image",
    });

  const notRegularAlert = () => {
    MySwal.fire({
      position: "top-end",
      title: "Be more regular if you want to grow in this art!",
      text: "It has been more than 48 hours since you last practiced. Try to practice more often...",
      icon: "info",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const isRegularAlert = () => {
    MySwal.fire({
      position: "top-end",
      icon: "success",
      title: "Bravo for your assiduity, keep it up!",
      text: "Your last session was less than 48 hours ago... ",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const intermedAlertComponent = () => {
    MySwal.fire({
      position: "top-end",
      icon: "success",
      title: "Your level has been changed to Intermediate!",
      text: "Mantras have been added to the available sounds...",
      footer: "Well done, keep it up, you are on the right track!",
      showConfirmButton: false,
      timer: 7500,
    });
  };

  const confirmedAlertComponent = () => {
    MySwal.fire({
      position: "top-end",
      icon: "success",
      title: "Your Level has been set to Confirmed!",
      text: "New sounds have been added to the available sounds...",
      footer: "Great, when will the expert level be available?",
      showConfirmButton: false,
      timer: 7500,
    });
  };

  const expertAlertComponent = () => {
    MySwal.fire({
      position: "top-end",
      icon: "success",
      title: "Your level has been upgraded to Expert!",
      text: "New sounds have been added to the available sounds...",
      footer: "Great, now just share your knowledge!",
      showConfirmButton: false,
      timer: 7500,
    });
  };

  const enterChildZoneAlert = () =>
    MySwal.fire({
      title: "Internet connection required ",
      html: `
          <div>
            <p>Unlike the rest of the application,
              <strong>which is fully accessible offline</strong>,
              this section
              <strong>need</strong>
              a
              <strong>internet connection</strong>
              !
            </p>
            <br />
            <sub style="color:red">Make sure you are logged in before accessing this section!</sub>
          </div>`,
      text: "",
      imageUrl: logo,
      imageWidth: 200,
      imageHeight: 100,
      imageAlt: "Custom image",
      showCancelButton: true,
      confirmButtonColor: "#7367f0",
      cancelButtonColor: "#ee0979",
      confirmButtonText: "Okay, I get it!",
      cancelButtonText: "Cancel",
    });

  const confirmedEnterChildZoneAlert = () => {
    Swal.fire({
      title: "Welcome to the little ones.",
      html: `
              <div>
                <p>All the media presented here have been checked and are suitable for use with children.</p>
                <br />
                <p style="font-size:12px; color:var(--purpleText)">The content will evolve over time! (new meditations are added to each category regularly)</p>
                <br />
                <div>Good mediation and a good time!</div>
              </div>`,
      text: "",
      imageUrl: LogoChild,
      imageWidth: 250,
      imageHeight: 150,
      imageAlt: "Custom image",
      confirmButtonText: "I'm going!",
    });
  };

  const levelBadgesAlert = () =>
    MySwal.fire({
      title: "Your Level!",
      text: "There are 4 levels, Beginner, Intermediate, Confirmed and Expert",
      html: `
        <div>
        <p>${levelOfMoment}</p>
          <div class="progress-linear">
            <div class="${
              BeginnerLevel
                ? "bar-linear colorBlue"
                : IntermedLevel
                ? "bar-linear colorPurple"
                : ConfirmedLevel
                ? "bar-linear colorRed"
                : ExpertLevel || AbsoluteExpertLevel
                ? "bar-linear colorBlack"
                : ""
            }" style="width:${
        BeginnerLevel
          ? progressBeginnerLevelValue
          : IntermedLevel
          ? progressIntermedLevelValue
          : ConfirmedLevel
          ? progressConfirmedLevelValue
          : ExpertLevel || AbsoluteExpertLevel
          ? progressExpertLevelValue
          : ""
      }%">
            </div>
            <p class="${
              ExpertLevel || AbsoluteExpertLevel
                ? "percent-linear text-white"
                : "percent-linear text-black"
            }">${
        BeginnerLevel
          ? progressBeginnerLevelValue
          : IntermedLevel
          ? progressIntermedLevelValue
          : ConfirmedLevel
          ? progressConfirmedLevelValue
          : ExpertLevel || AbsoluteExpertLevel
          ? progressExpertLevelValue
          : ""
      }%</p>
          </div>
          <h4>There are 4 levels,
            <br />
            <ul class="levels-list">
              <li class="text-blue">Beginner,</li>
              <li class="text-purple">Intermediate,</li>
              <li class="text-red">Confirmed,</li>
              <li class="text-black">Expert</li>
            </ul>
          </h4>
          <br />
          <span class="levels-content"><p><strong>From your first session</strong> you acquire the Beginner level,</p>
           <p>then <strong>as your sessions progress</strong> you're <strong>level increases</strong>!</p></span>
        </div>`,
      imageUrl: LevelsIcon,
      imageWidth: 200,
      imageHeight: 100,
      imageAlt: "Custom image",
    });

  const curiousBadgeAlerts = () =>
    MySwal.fire({
      title: "Your auditory curiosity!",
      text: "This badge only appears if you use sounds regularly!",
      html: `
        <div>
        <strong>You are a curious soul.</strong>
          </div>
          <br />
          <span class="levels-content"><p>This badge is only present if you do <strong>at least 50%. </strong>of your sessions with ambient sound!<br /><br />otherwise it <strong>disappears</strong>!</p>

        </div>`,
      footer:
        "Great, keep stimulating your imagination, you are really on the right track!",
      imageUrl: CuriousIcon,
      imageWidth: 200,
      imageHeight: 100,
      imageAlt: "Custom image",
    });

  const selectNumberHelp = () =>
    MySwal.fire({
      title: "Swipe to choose!",
      html: `
        <div>
        <strong>Put your finger on the number...</strong>
        <img src=${Help} alt="help" style="width:200px" />
          </div>
          <span class="levels-content helpContent"><p>Then, without raising your finger <strong>(it should always be in contact with your screen)</strong> gently slide up/down.<br /> Once the number is chosen, <strong>tap on it</strong> for <strong>select it</strong> !</p>

        </div>`,
      footer:
        "Tip: Once you put your finger down, you can shift your finger slightly, as long as you don't let go of your screen, the numbers will scroll.",
      imageUrl: SwipeUp,
      imageWidth: 150,
      imageHeight: 75,
      imageAlt: "Custom image",
    });

  const validateChangeLogo = () =>
    MySwal.fire({
      position: "top-end",
      icon: "success",
      html: `
        <div>
          <strong>Logo replacement in progress...</strong>
          <br />
          <span class="levels-content helpContent">
            <p>
              restarting the application!
            </p>
        </div>`,
      title: " ",
      showConfirmButton: false,
      timer: 2000,
    });

  const bestLearnBadgeAlerts = () =>
    MySwal.fire({
      title: "Your learning quality!",
      html: `
        <div>
        <strong>You learn in the best way!</strong>
          </div>
          <br />
          <span class="levels-content"><p>This badge is only present if you have <strong> read at least 6 tips </strong>in the "How to" section.</p>

        </div>`,
      footer:
        "Getting the basics right by reading the tips from the start is a great choice!",
      imageUrl: BestLearnIcon,
      imageWidth: 200,
      imageHeight: 100,
      imageAlt: "Custom image",
    });
  return {
    attendanceAlerts,
    notRegularAlert,
    isRegularAlert,
    intermedAlertComponent,
    confirmedAlertComponent,
    expertAlertComponent,
    enterChildZoneAlert,
    confirmedEnterChildZoneAlert,
    levelBadgesAlert,
    curiousBadgeAlerts,
    selectNumberHelp,
    validateChangeLogo,
    bestLearnBadgeAlerts,
  };
};

export default useSweetAlertComponent;

import IntermedIcon from "../../images/intermediate/intermedIcon.svg";
import {
  HealingIcon,
  TissusIcon,
  chakraIcon,
  negativeIcon,
  whaleIcon,
  DeepMeditationIcon,
  ToxinIcon,
  higherConsciousnessIcon,
  spiritualIcon,
  sleepIcon,
  immuneIcon,
  liberateIcon,
  releaseIcon,
  innerIcon,
  dnaIcon,
  LoveIcon,
  OvercomeIcon,
  awakeIcon,
  MoutainIcon,
  RainOnRoofIcon,
  CaveIcon,
  StormIcon,
  FireIcon,
  LakeIcon,
  WaterfallIcon,
  VolcanoIcon,
  MillIcon,
  BeachIcon,
  DesertIcon,
  ForsetIcon,
  RainforestIcon,
  NightIcon,
  RiverIcon,
} from "./PictureExports";

import {
  HealingSound,
  HealingSoundAlt,
  TissusSound,
  TissusSoundAlt,
  chakraSound,
  chakraSoundAlt,
  negativeSound,
  negativeSoundAlt,
  whaleSound,
  whaleSoundAlt,
  deepMeditateSound,
  deepMeditateSoundAlt,
  ToxinSound,
  ToxinSoundAlt,
  HigherConsciousnessSound,
  HigherConsciousnessSoundAlt,
  spiritualSound,
  spiritualSoundAlt,
  deeperSleepSound,
  deeperSleepSoundAlt,
  immuneSound,
  immuneSoundAlt,
  liberateSound,
  liberateSoundAlt,
  releaseSound,
  releaseSoundAlt,
  innerSound,
  innerSoundAlt,
  dnaSound,
  dnaSoundAlt,
  loveSound,
  loveSoundAlt,
  overcomeSound,
  overcomeSoundAlt,
  awakeSound,
  awakeSoundAlt,
  AjamSound,
  AjamSoundAlt,
  BambooSound,
  BambooSoundAlt,
  DhyanSound,
  DhyanSoundAlt,
  GuruSound,
  GuruSoundAlt,
  HarekrishnaSound,
  HarekrishnaSoundAlt,
  OmkarSound,
  OmkarSoundAlt,
  OmnamahSound,
  OmnamahSoundAlt,
  OmsarvaSound,
  OmsarvaSoundAlt,
  SaurashtreSound,
  SaurashtreSoundAlt,
  WaveSound,
  WaveSoundAlt,
  DesertSound,
  DesertSoundAlt,
  ForestSound,
  ForestSoundAlt,
  RiverSound,
  RiverSoundAlt,
  RainForestSound,
  RainForestSoundAlt,
  NightSound,
  NightSoundAlt,
  MountainSound,
  MountainSoundAlt,
  RainOnRoofSound,
  RainOnRoofSoundAlt,
  CaveSound,
  CaveSoundAlt,
  StormSound,
  StormSoundAlt,
  FireSound,
  FireSoundAlt,
  LakeIconSound,
  LakeIconSoundAlt,
  volcanoSound,
  volcanoSoundAlt,
  waterfallSound,
  waterfallSoundAlt,
  millSound,
  millSoundAlt,
} from "./SoundExports";

const TimePresets = [
  {
    id: 0,
    title: "5 Mins",
    time: 5,
  },
  {
    id: 1,
    title: "25 Mins",
    time: 25,
  },
  {
    id: 2,
    title: "50 Mins",
    time: 50,
  },
];
const soundPresets = [
  {
    id: 0,
    title: "Beach",
    sound: WaveSound,
    alt: WaveSoundAlt,
    img: BeachIcon,
  },
  {
    id: 1,
    title: "Desert",
    sound: DesertSound,
    alt: DesertSoundAlt,
    img: DesertIcon,
  },
  {
    id: 2,
    title: "Forest",
    sound: ForestSound,
    alt: ForestSoundAlt,
    img: ForsetIcon,
  },
  {
    id: 3,
    title: "Night",
    sound: NightSound,
    alt: NightSoundAlt,
    img: NightIcon,
  },
  {
    id: 4,
    title: "Tropical",
    sound: RainForestSound,
    alt: RainForestSoundAlt,
    img: RainforestIcon,
  },
  {
    id: 5,
    title: "River",
    sound: RiverSound,
    alt: RiverSoundAlt,
    img: RiverIcon,
  },
  {
    id: 6,
    title: "Cavern",
    sound: CaveSound,
    alt: CaveSoundAlt,
    img: CaveIcon,
  },
  {
    id: 7,
    title: "Mountain",
    sound: MountainSound,
    alt: MountainSoundAlt,
    img: MoutainIcon,
  },
  {
    id: 8,
    title: "Lake",
    sound: LakeIconSound,
    alt: LakeIconSoundAlt,
    img: LakeIcon,
  },
  {
    id: 9,
    title: "Waterfalls",
    sound: waterfallSound,
    alt: waterfallSoundAlt,
    img: WaterfallIcon,
  },
  {
    id: 10,
    title: "Volcan",
    sound: volcanoSound,
    alt: volcanoSoundAlt,
    img: VolcanoIcon,
  },
  {
    id: 11,
    title: "Mill",
    sound: millSound,
    alt: millSoundAlt,
    img: MillIcon,
  },
  {
    id: 12,
    title: "Fire",
    sound: FireSound,
    alt: FireSoundAlt,
    img: FireIcon,
  },
  {
    id: 13,
    title: "Rain",
    sound: RainOnRoofSound,
    alt: RainOnRoofSoundAlt,
    img: RainOnRoofIcon,
  },
  {
    id: 14,
    title: "Storm",
    sound: StormSound,
    alt: StormSoundAlt,
    img: StormIcon,
  },
];

const intermediateSoundPresets = [
  {
    id: 0,
    title: "Ajam nirvikalpam",
    sound: AjamSound,
    alt: AjamSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 1,
    title: "bamboo mantra",
    sound: BambooSound,
    alt: BambooSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 2,
    title: "Dhyan Mantra",
    sound: DhyanSound,
    alt: DhyanSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 3,
    title: "Guru brahma",
    sound: GuruSound,
    alt: GuruSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 4,
    title: "harekrishna flute",
    sound: HarekrishnaSound,
    alt: HarekrishnaSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 5,
    title: "Omkar",
    sound: OmkarSound,
    alt: OmkarSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 6,
    title: "om namah shivaya",
    sound: OmnamahSound,
    alt: OmnamahSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 7,
    title: "Om Sarva Mangal",
    sound: OmsarvaSound,
    alt: OmsarvaSoundAlt,
    img: IntermedIcon,
  },
  {
    id: 8,
    title: "Saurashtre Somnatham",
    sound: SaurashtreSound,
    alt: SaurashtreSoundAlt,
    img: IntermedIcon,
  },
];

const confirmedSoundPresets = [
  {
    id: 0,
    title: "174Hz",
    sub: "Deep sleep",
    sound: deeperSleepSound,
    alt: deeperSleepSoundAlt,
    img: sleepIcon,
  },
  {
    id: 1,
    title: "285Hz",
    sub: "Immune system",
    sound: immuneSound,
    alt: immuneSoundAlt,
    img: immuneIcon,
  },
  {
    id: 2,
    title: "396Hz",
    sub: "releases guilt and fear",
    sound: liberateSound,
    alt: liberateSoundAlt,
    img: liberateIcon,
  },
  {
    id: 3,
    title: "417Hz",
    sub: "releases the past",
    sound: releaseSound,
    alt: releaseSoundAlt,
    img: releaseIcon,
  },
  {
    id: 4,
    title: "432Hz",
    sub: "inner healing",
    sound: innerSound,
    alt: innerSoundAlt,
    img: innerIcon,
  },
  {
    id: 5,
    title: "528Hz",
    sub: "DNA repair",
    sound: dnaSound,
    alt: dnaSoundAlt,
    img: dnaIcon,
  },
  {
    id: 6,
    title: "639Hz",
    sub: "Connection & love",
    sound: loveSound,
    alt: loveSoundAlt,
    img: LoveIcon,
  },
  {
    id: 7,
    title: "714Hz",
    sub: "overcoming problems",
    sound: overcomeSound,
    alt: overcomeSoundAlt,
    img: OvercomeIcon,
  },
  {
    id: 8,
    title: "852Hz",
    sub: "awakening intuition",
    sound: awakeSound,
    alt: awakeSoundAlt,
    img: awakeIcon,
  },
];

const expertSoundPresets = [
  {
    id: 0,
    title: "*174Hz",
    sub: "healing, pain relief",
    sound: HealingSound,
    alt: HealingSoundAlt,
    img: HealingIcon,
  },
  {
    id: 1,
    title: "*285Hz",
    sub: "healing and tissue regeneration",
    sound: TissusSound,
    alt: TissusSoundAlt,
    img: TissusIcon,
  },
  {
    id: 2,
    title: "*396Hz",
    sub: "root chakra balance",
    sound: chakraSound,
    alt: chakraSoundAlt,
    img: chakraIcon,
  },
  {
    id: 3,
    title: "*417Hz",
    sub: "removes negative energy",
    sound: negativeSound,
    alt: negativeSoundAlt,
    img: negativeIcon,
  },
  {
    id: 4,
    title: "*432Hz",
    sub: "whale song",
    sound: whaleSound,
    alt: whaleSoundAlt,
    img: whaleIcon,
  },
  {
    id: 5,
    title: "*528Hz",
    sub: "Deep meditation",
    sound: deepMeditateSound,
    alt: deepMeditateSoundAlt,
    img: DeepMeditationIcon,
  },
  {
    id: 6,
    title: "*714Hz",
    sub: "toxin cleansing",
    sound: ToxinSound,
    alt: ToxinSoundAlt,
    img: ToxinIcon,
  },
  {
    id: 7,
    title: "*852Hz",
    sub: "higher consciousness",
    sound: HigherConsciousnessSound,
    alt: HigherConsciousnessSoundAlt,
    img: higherConsciousnessIcon,
  },
  {
    id: 8,
    title: "*963Hz",
    sub: "spiritual guidance",
    sound: spiritualSound,
    alt: spiritualSoundAlt,
    img: spiritualIcon,
  },
];

export {
  TimePresets,
  soundPresets,
  intermediateSoundPresets,
  confirmedSoundPresets,
  expertSoundPresets,
  HealingSound,
  HealingSoundAlt,
  TissusSound,
  TissusSoundAlt,
  chakraSound,
  chakraSoundAlt,
  negativeSound,
  negativeSoundAlt,
  whaleSound,
  whaleSoundAlt,
  deepMeditateSound,
  deepMeditateSoundAlt,
  ToxinSound,
  ToxinSoundAlt,
  HigherConsciousnessSound,
  HigherConsciousnessSoundAlt,
  spiritualSound,
  spiritualSoundAlt,
  HealingIcon,
  TissusIcon,
  chakraIcon,
  negativeIcon,
  whaleIcon,
  DeepMeditationIcon,
  ToxinIcon,
  higherConsciousnessIcon,
  spiritualIcon,
  deeperSleepSound,
  deeperSleepSoundAlt,
  immuneSound,
  immuneSoundAlt,
  liberateSound,
  liberateSoundAlt,
  releaseSound,
  releaseSoundAlt,
  innerSound,
  innerSoundAlt,
  dnaSound,
  dnaSoundAlt,
  loveSound,
  loveSoundAlt,
  overcomeSound,
  overcomeSoundAlt,
  awakeSound,
  awakeSoundAlt,
  AjamSound,
  AjamSoundAlt,
  BambooSound,
  BambooSoundAlt,
  DhyanSound,
  DhyanSoundAlt,
  GuruSound,
  GuruSoundAlt,
  HarekrishnaSound,
  HarekrishnaSoundAlt,
  OmkarSound,
  OmkarSoundAlt,
  OmnamahSound,
  OmnamahSoundAlt,
  OmsarvaSound,
  OmsarvaSoundAlt,
  SaurashtreSound,
  SaurashtreSoundAlt,
  WaveSound,
  WaveSoundAlt,
  DesertSound,
  DesertSoundAlt,
  ForestSound,
  ForestSoundAlt,
  RiverSound,
  RiverSoundAlt,
  RainForestSound,
  RainForestSoundAlt,
  NightSound,
  NightSoundAlt,
  MountainSound,
  MountainSoundAlt,
  RainOnRoofSound,
  RainOnRoofSoundAlt,
  CaveSound,
  CaveSoundAlt,
  StormSound,
  StormSoundAlt,
  FireSound,
  FireSoundAlt,
  LakeIconSound,
  LakeIconSoundAlt,
  volcanoSound,
  volcanoSoundAlt,
  waterfallSound,
  waterfallSoundAlt,
  millSound,
  millSoundAlt,
};

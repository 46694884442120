import "./animAppName-style.css";

const AnimAppName = () => {
  return (
    <div className="stage-hello">
      <p> The practice of meditation made simple.</p>
    </div>
  );
};

export default AnimAppName;

import { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useReverseArray from "../hooks/useReverseArray";
import openHistoryAtom from "../statesManager/openHistoryAtom";
import "./modalScroll.css";
import { DateRangeTwoTone } from "@material-ui/icons";
import { TimerTwoTone } from "@material-ui/icons";
import { VolumeUpTwoTone } from "@material-ui/icons";
import { VolumeOffTwoTone } from "@material-ui/icons";
import Erase from "../images/supp.svg";
import "./historyMeditation.css";
import { useHistory } from "react-router-dom";

const ModalScroll = ({ handleEraseHistory, deteleAll }) => {
  let history = useHistory();
  const { mapRevers } = useReverseArray();
  // eslint-disable-next-line no-unused-vars
  const [openHistory, setOpenHistory] = useRecoilState(openHistoryAtom);

  const [historymeditation] = useState(
    JSON.parse(localStorage.getItem("history")) ?? []
  );

  useEffect(() => {
    // console.log("history from history Component", historymeditation);
  }, [historymeditation]);
  return (
    <div>
      <div className="modal" id="myModal2" role="dialog">
        <div className="modal-dialog" style={{ maxWidth: "600px" }}>
          {/* Modal content */}
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                background: "#5c09e2",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                padding: 7,
              }}
            >
              <div className="modalcontentHeader">
                {historymeditation.length !== 0 ? (
                  <p>You have done:{historymeditation.length} session(s)</p>
                ) : (
                  ""
                )}
                <img
                  className="eraseIcon"
                  onClick={() => {
                    setOpenHistory(false);
                    history.replace("/session");
                  }}
                  src={Erase}
                  alt="erase"
                />
              </div>
            </div>
            <div
              style={{ background: "var(--Background)" }}
              className="modal-body"
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "var(--colorText)",
                  fontFamily: "roboto",
                  lineHeight: "23px",
                  marginBottom: "0px",
                  padding: "0px 15px 0px",
                  height: "312px",
                  overflowY: "scroll",
                  textAlign: "justify",
                  background: "var(--Background)",
                }}
              >
                <div className="card-history">
                  {mapRevers(historymeditation).map((res) => (
                    <Fragment key={res.id}>
                      <div key={res.id} className="history-list">
                        <span>
                          <DateRangeTwoTone />
                          <p>Date: </p>
                          <sub>{res.date}</sub>
                        </span>
                        <span>
                          <TimerTwoTone />
                          <p>Session duration: </p>
                          <sub>{res.time} mins</sub>
                        </span>
                        {res.sound ? (
                          <span>
                            <VolumeUpTwoTone />
                            <p>Session sound: </p>
                            <sub>{res.sound}</sub>
                          </span>
                        ) : (
                          <span>
                            <VolumeOffTwoTone />
                            <p>No sound</p>
                          </span>
                        )}
                      </div>
                      <br />
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className="text-center-histo">
                <button onClick={deteleAll} className="eraseHistoButton">
                  Delete history
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalScroll;

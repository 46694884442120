import { Card } from "@material-ui/core";
import detailedConseils from "./detailedConseilsData";

const DetailedConseilComponent = ({
  idClicked,
  setOpenConseils,
  handleClickHowMany,
}) => {
  return (
    <Card className="deteiledConseils-container">
      <div className="cardConseil-header">
        <h1>
          {` ${detailedConseils[idClicked].number} -
          ${detailedConseils[idClicked].title}`}
        </h1>
        <div className="closeConseils" onClick={() => setOpenConseils(false)}>
          X
        </div>
      </div>

      <div className="detailedConseil-card">
        <p>{detailedConseils[idClicked].content.map((res) => res.parag1)}</p>
        <p>{detailedConseils[idClicked].content.map((res) => res.parag2)}</p>
        <p>{detailedConseils[idClicked].content.map((res) => res.parag3)}</p>
        <p>{detailedConseils[idClicked].content.map((res) => res.parag4)}</p>
        <p>{detailedConseils[idClicked].content.map((res) => res.parag5)}</p>
        <p>{detailedConseils[idClicked].content.map((res) => res.parag6)}</p>
        <div className="footer-conseils">
          <button
            onClick={() => {
              handleClickHowMany();
              setOpenConseils(false);
            }}
            className="modal__btn"
          >
            Close
          </button>
        </div>
      </div>
    </Card>
  );
};

export default DetailedConseilComponent;

import { Fragment } from "react";
import { useRecoilState } from "recoil";
import sessionLinkAtom from "../../statesManager/sessionLinkAtom";

const GoToTimerButton = ({
  timeDuration,
  titleSound,
  setNavigationName,
  history,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [SessionLink, setSessionLink] = useRecoilState(sessionLinkAtom);
  return (
    <Fragment>
      {timeDuration || titleSound ? (
        <button
          className="btn btn--primary text-center mt-4"
          title="Start your first session"
          onClick={() => {
            setNavigationName("session");
            setSessionLink(true);
            history.push("/session");
          }}
        >
          Go to timer
        </button>
      ) : null}
    </Fragment>
  );
};

export default GoToTimerButton;

import React from "react";
import Modal from "@material-ui/core/Modal";
import PictureFilters from "./pictureFilters/PictureFilters";

import "./profilePictureResize.css";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function ModalFilters() {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);
  // let history = useHistory();

  const handleClose = () => {
    setOpen(false);
    return window.location.replace("/");
  };

  // Boby of the modal content
  const body = (
    <div style={modalStyle} className="paper modal-414">
      <PictureFilters />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

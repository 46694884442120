import React from "react";
import { useEffect } from "react";

function SidebarItem({ icon, active, handleClick }) {
  useEffect(() => {
    console.log(active);
  }, [active]);
  return (
    <div className="sidebar">
      <button
        className={`sidebar__sidebarItem ${active ? "active-side" : ""}`}
        onClick={handleClick}
      >
        {icon}
      </button>
    </div>
  );
}

export default SidebarItem;

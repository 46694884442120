import { useEffect } from "react";
import { useState } from "react";
import CuriousIcon from "../../images/curiousBadge.svg";
import useSweetAlertComponent from "../sweetAlertComponent/useSweetAlertComponent";

const CuriousBadgeComponent = () => {
  const [clickedCuriousBadge, setClickedCuriousBadge] = useState(false);
  const { curiousBadgeAlerts } = useSweetAlertComponent();

  const handleClickCuriousBadge = () => {
    setClickedCuriousBadge((clickedCuriousBadge) => !clickedCuriousBadge);
  };

  useEffect(() => {
    if (clickedCuriousBadge) {
      curiousBadgeAlerts().then((clickedCuriousBadge) => {
        if (clickedCuriousBadge) {
          setClickedCuriousBadge(false);
        }
      });
    }
  }, [clickedCuriousBadge, curiousBadgeAlerts]);
  return (
    <div onClick={handleClickCuriousBadge} className="curiousBadge-container">
      <img src={CuriousIcon} alt="icon" style={{ width: 40, height: 40 }} />
    </div>
  );
};

export default CuriousBadgeComponent;

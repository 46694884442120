import { useEffect, useState } from "react";

const useDarkModeTheme = () => {
  const [mode, setMode] = useState("light");

  const onSelectMode = (mode) => {
    const backDark = document.getElementById("root");
    const coverBackDark = document.getElementById("cover-App");
    setMode(mode);
    if (mode === "dark") {
      document.body.classList.add("dark-mode");
      backDark.classList.add("dark");
      coverBackDark.classList.add("dark");
    } else {
      document.body.classList.remove("dark-mode");
      backDark.classList.remove("dark");
      coverBackDark.classList.remove("dark");
    }
  };

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);
  return {
    mode,
    onSelectMode,
  };
};

export default useDarkModeTheme;

import { useState } from "react";
import "./whyModalStyle.css";
import InfosWhyData from "../infosWhy/InfoWhyData";

const WhyModalComponent = ({ idClicked }) => {
  const [openModalWhy, setOpenModalWhy] = useState(false);

  const handleOpenModalWhy = () => {
    setOpenModalWhy((openModalWhy) => !openModalWhy);
  };
  return (
    <div className="buttonInfos">
      <button
        className="notSmooth modalBtn"
        onClick={handleOpenModalWhy}
        data-micromodal-trigger="modal-1"
      >
        More info...
      </button>

      {openModalWhy ? (
        <div
          className="modal micromodal-slide"
          style={{
            height: "100vh",
            position: "absolute",
            top: 0,
          }}
          aria-hidden="true"
          id="top fullpage"
        >
          {InfosWhyData[idClicked] ? (
            <span
              className="modal__overlay"
              tabIndex="-1"
              data-micromodal-close
            >
              <div
                className="modal__container slide-in-bottom"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-1-title"
              >
                <header className="modal__header">
                  <h2 className="modal__title" id="modal-1-title">
                    {InfosWhyData[idClicked].title}
                  </h2>

                  <button
                    onClick={() => setOpenModalWhy(false)}
                    className="modal__close"
                    aria-label="Close modal"
                    data-micromodal-close
                  ></button>
                </header>
                <main className="modal__content" id="modal-1-content">
                  {InfosWhyData[idClicked].content.map((res) => (
                    <div key={res.id} className="infosContainer">
                      {InfosWhyData[idClicked].sub ? (
                        <sub className="subWhyContent">
                          {InfosWhyData[idClicked].sub}
                        </sub>
                      ) : null}
                      {res.sub1 ? (
                        <sub className="titleInfos">{res.sub1}</sub>
                      ) : null}
                      <h1 className="titleInfos">{res.title}</h1>
                      <p className="paragInfos">{res.parag1}</p>
                      <h1 className="titleInfos">{res.title2}</h1>
                      <p className="paragInfos">{res.parag2}</p>
                      <h1 className="titleInfos">{res.title3}</h1>
                      <p className="paragInfos">{res.parag3}</p>
                      <h1 className="titleInfos">{res.title4}</h1>
                      {res.parag4 ? (
                        <p className="paragInfos">{res.parag4}</p>
                      ) : null}
                      {res.title5 ? (
                        <h1 className="titleInfos">{res.title5}</h1>
                      ) : null}
                      {res.parag5 ? (
                        <p className="paragInfos">{res.parag5}</p>
                      ) : null}
                      {res.title6 ? (
                        <h1 className="titleInfos">{res.title6}</h1>
                      ) : null}
                      {res.sub6 ? (
                        <h1 className="titleInfos">{res.sub6}</h1>
                      ) : null}
                      {res.parag6 ? (
                        <p className="paragInfos">{res.parag6}</p>
                      ) : null}
                    </div>
                  ))}
                </main>
                <footer className="modal__footer">
                  <button
                    onClick={() => setOpenModalWhy(false)}
                    className="modal__btn"
                    data-micromodal-close
                    aria-label="Close this dialog window"
                  >
                    Close
                  </button>
                </footer>
              </div>
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default WhyModalComponent;

const CustomBadgeWithProgress = ({ classBadge, badgeImg, progressValue }) => {
  return (
    <div className={`${classBadge}`}>
      <img
        src={badgeImg}
        alt="badge"
        style={{
          width: 35,
          height: 35,
          left: 10,
          marginTop: 4,
        }}
      />
      <div
        className="circle x-small"
        data-fill={`${progressValue}`}
        style={{ color: "#28a745" }}
      >
        <span>{progressValue}%</span>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default CustomBadgeWithProgress;

import { useEffect, useState } from "react";
import AnimateText from "../animateText/AnimateText";
import Logo from "./assets/logo-app2.svg";
import "./childStyle.css";
import {
  stressTheme,
  ApprentissageTheme,
  WhitMusicTheme,
  BienveillanceTheme,
  ConfianceTheme,
  colereTheme,
  tristeTheme,
  sommeilTheme,
} from "./embedIdDatas";
import MediasSelection from "./MediasSelection";
import InfiniteLoopLoader from "../infiniteLoopLoader/InfiniteLoopLoader";
import childSectionSound from "../../sounds/child/cartoon-positive-sound.mp3";
import childSectionSoundAtl from "../../sounds/child/cartoon-positive-sound.ogg";
import clickedChildZoneAtom from "../../statesManager/clickedChildZoneAtom";
import { useRecoilState } from "recoil";

const ChildPage = () => {
  const [audioWelcome, setAudioWelcome] = useState(true);
  const [clickedChildZone] = useRecoilState(clickedChildZoneAtom);
  const [clickedLoader, setClickedLoader] = useState(false);
  const [clickedStressTheme, setClickedStressTheme] = useState(false);
  const [clickedApprentissageTheme, setClickedApprentissageTheme] =
    useState(false);
  const [clickedWithMusicTheme, setClickedWithMusicTheme] = useState(false);
  const [clickedBienveillanceTheme, setClickedBienveillanceTheme] =
    useState(false);
  const [clickedConfianceTheme, setClickedConfianceTheme] = useState(false);
  const [clickedColereTheme, setClickedColereTheme] = useState(false);
  const [clickedTristeTheme, setClickedTristeTheme] = useState(false);
  const [clickedSommeilTheme, setClickedSommeilTheme] = useState(false);

  const handleClickLoader = () => {
    setClickedLoader(true);
    setTimeout(() => {
      setClickedLoader(false);
    }, 2000);
  };

  const handleClickStressZone = () => {
    setClickedStressTheme((clickedStressTheme) => !clickedStressTheme);
  };
  const handleClickSommeilZone = () => {
    setClickedSommeilTheme((clickedSommeilTheme) => !clickedSommeilTheme);
  };
  const handleClickColereZone = () => {
    setClickedColereTheme((clickedColereTheme) => !clickedColereTheme);
  };
  const handleClickTristeZone = () => {
    setClickedTristeTheme((clickedTristeTheme) => !clickedTristeTheme);
  };
  const handleClickBienveillanceZone = () => {
    setClickedBienveillanceTheme(
      (clickedBienveillanceTheme) => !clickedBienveillanceTheme
    );
  };
  const handleClickConfianceZone = () => {
    setClickedConfianceTheme((clickedConfianceTheme) => !clickedConfianceTheme);
  };
  const handleClickApprentissageZone = () => {
    setClickedApprentissageTheme(
      (clickedApprentissageTheme) => !clickedApprentissageTheme
    );
  };
  const handleClickWithMusicZone = () => {
    setClickedWithMusicTheme((clickedWithMusicTheme) => !clickedWithMusicTheme);
  };

  useEffect(() => {
    if (clickedStressTheme) {
      setClickedApprentissageTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedColereTheme(false);
      setClickedConfianceTheme(false);
      setClickedSommeilTheme(false);
      setClickedTristeTheme(false);
      setClickedWithMusicTheme(false);
      handleClickLoader();
    }
    if (clickedWithMusicTheme) {
      setClickedApprentissageTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedColereTheme(false);
      setClickedConfianceTheme(false);
      setClickedSommeilTheme(false);
      setClickedTristeTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
    if (clickedApprentissageTheme) {
      setClickedWithMusicTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedColereTheme(false);
      setClickedConfianceTheme(false);
      setClickedSommeilTheme(false);
      setClickedTristeTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
    if (clickedBienveillanceTheme) {
      setClickedWithMusicTheme(false);
      setClickedApprentissageTheme(false);
      setClickedColereTheme(false);
      setClickedConfianceTheme(false);
      setClickedSommeilTheme(false);
      setClickedTristeTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
    if (clickedConfianceTheme) {
      setClickedWithMusicTheme(false);
      setClickedApprentissageTheme(false);
      setClickedColereTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedSommeilTheme(false);
      setClickedTristeTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
    if (clickedColereTheme) {
      setClickedWithMusicTheme(false);
      setClickedApprentissageTheme(false);
      setClickedConfianceTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedSommeilTheme(false);
      setClickedTristeTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
    if (clickedTristeTheme) {
      setClickedWithMusicTheme(false);
      setClickedApprentissageTheme(false);
      setClickedConfianceTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedSommeilTheme(false);
      setClickedColereTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
    if (clickedSommeilTheme) {
      setClickedWithMusicTheme(false);
      setClickedApprentissageTheme(false);
      setClickedConfianceTheme(false);
      setClickedBienveillanceTheme(false);
      setClickedTristeTheme(false);
      setClickedColereTheme(false);
      setClickedStressTheme(false);
      handleClickLoader();
    }
  }, [
    clickedStressTheme,
    clickedWithMusicTheme,
    clickedApprentissageTheme,
    clickedBienveillanceTheme,
    clickedColereTheme,
    clickedConfianceTheme,
    clickedSommeilTheme,
    clickedTristeTheme,
    audioWelcome,
  ]);

  const AudioChildSection = () => {
    if (audioWelcome) {
      setTimeout(() => {
        return setAudioWelcome(false);
      }, 2500);
    }
    return (
      <audio autoPlay>
        <source src={childSectionSound} type="audio/mpeg" />
        <source src={childSectionSoundAtl} type="audio/ogg" />
        <p>
          Your browser does not support HTML5 audio. Here is a{" "}
          <a href={`${childSectionSound}`}>link to audio file</a>instead.
        </p>
      </audio>
    );
  };

  return (
    <div className="slide-in-bottom-child">
      {clickedChildZone && audioWelcome ? <AudioChildSection /> : null}
      <div className="childSection-content">
        <p className="copyright">Copyright - Rodolphe Augusto - 2021</p>
        <AnimateText />
        <br />
        <br />
        <img className="logo" src={Logo} alt="logo" />

        <div className="alertContent">
          <div className="logoText">
            <h2>For the little ones (3 to 10 years old)</h2>
            <sub>Media from the Youtube platform</sub>
          </div>
          <p className="alertText">
            All media have been carefully selected and are suitable for use with
            are all suitable for use with a child!
          </p>
        </div>
      </div>

      <div className="buttonContainer">
        <button
          className={
            clickedStressTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickStressZone}
        >
          Stress
        </button>
        <button
          className={
            clickedApprentissageTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickApprentissageZone}
        >
          Apprentice
        </button>
        <button
          className={
            clickedWithMusicTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickWithMusicZone}
        >
          With Music
        </button>
        <button
          className={
            clickedBienveillanceTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickBienveillanceZone}
        >
          Kindness
        </button>
        <button
          className={
            clickedConfianceTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickConfianceZone}
        >
          Trust
        </button>
        <button
          className={
            clickedColereTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickColereZone}
        >
          Anger
        </button>
        <button
          className={
            clickedTristeTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickTristeZone}
        >
          Sadness
        </button>
        <button
          className={
            clickedSommeilTheme
              ? "buttonChildZone buttonChildZone-active"
              : "buttonChildZone "
          }
          onClick={handleClickSommeilZone}
        >
          Sleep
        </button>
      </div>

      {!clickedStressTheme &&
      !clickedSommeilTheme &&
      !clickedTristeTheme &&
      !clickedApprentissageTheme &&
      !clickedBienveillanceTheme &&
      !clickedColereTheme &&
      !clickedConfianceTheme &&
      !clickedWithMusicTheme ? (
        <div className="noneCat">
          <h2>No Category chosen</h2>
          <sub>The media of the chosen category appear here!</sub>
        </div>
      ) : clickedLoader ? (
        <div className="noneCat">
          <InfiniteLoopLoader />
        </div>
      ) : (
        <MediasSelection
          clickedStressTheme={clickedStressTheme}
          stressTheme={stressTheme}
          clickedApprentissageTheme={clickedApprentissageTheme}
          ApprentissageTheme={ApprentissageTheme}
          clickedWithMusicTheme={clickedWithMusicTheme}
          WhitMusicTheme={WhitMusicTheme}
          clickedBienveillanceTheme={clickedBienveillanceTheme}
          BienveillanceTheme={BienveillanceTheme}
          clickedConfianceTheme={clickedConfianceTheme}
          ConfianceTheme={ConfianceTheme}
          clickedColereTheme={clickedColereTheme}
          colereTheme={colereTheme}
          clickedTristeTheme={clickedTristeTheme}
          tristeTheme={tristeTheme}
          clickedSommeilTheme={clickedSommeilTheme}
          sommeilTheme={sommeilTheme}
        />
      )}
    </div>
  );
};

export default ChildPage;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Wifi from "@material-ui/icons/Wifi";
import WifiOff from "@material-ui/icons/WifiOff";
import useNetwork from "../../hooks/useNetwork";
import { Container } from "@material-ui/core";

import clickedConnexionAtom from "../../statesManager/clickedConnexionAtom";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    alignItems: "center",
    justifyContent: "center",
    width: 217,
  },
  icon: {
    fontSize: 100,
    margin: "0 auto",
  },
  active: {
    color: "green",
  },
  activeLess: {
    color: "#93ce1c",
  },
  activeMoreLess: {
    color: "#c8840a",
  },
  activeSlow: {
    color: "#ff5722",
  },
  inactive: {
    color: "grey",
  },
  connexionContainer: {
    position: "absolute",
    top: -43,
    right: -30,
    zIndex: 62,
    width: "186px",
    height: "136px",
    transform: "scale(0.19)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
});
const UseNetworkExample = () => {
  const classes = useStyles();

  const iconInactiveStyles = `${classes.icon} ${classes.inactive}`;
  const networkState = useNetwork();
  const { online } = networkState;
  const [clickedConnexion, setClickedConnexion] =
    useRecoilState(clickedConnexionAtom);

  const handleClickConnexion = () => {
    setClickedConnexion((clickedConnexion) => !clickedConnexion);
  };

  let netForce = networkState.rtt;
  const iconActiveStyles = `${classes.icon} ${
    networkState.rtt <= 100
      ? classes.active
      : networkState.rtt > 100 && networkState.rtt <= 200
      ? classes.activeLess
      : networkState.rtt > 200 && networkState.rtt <= 375
      ? classes.activeMoreLess
      : networkState.rtt > 375
      ? classes.activeSlow
      : null
  }`;

  useEffect(() => {
    // console.log("connexion", clickedConnexion);
    // console.log("network", netForce);
  }, [clickedConnexion, netForce]);
  return (
    <Container className={classes.connexionContainer} maxWidth="md">
      {online ? (
        <span
          style={{
            width: 217,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: 49,
              textTransform: "uppercase",
              color: "var(--colorText)",
            }}
          >
            {networkState.effectiveType}
          </p>
        </span>
      ) : (
        <p style={{ fontSize: 58, width: 280, color: "darkred" }}>Offline</p>
      )}
      <div
        onClick={handleClickConnexion}
        className={`${online ? " ping" : ""} ${classes.container}`}
      >
        {online ? (
          <Wifi className={iconActiveStyles} />
        ) : (
          <WifiOff
            style={{ color: "darkred" }}
            className={iconInactiveStyles}
          />
        )}
      </div>
    </Container>
  );
};
export default UseNetworkExample;

import BaseLayout from "../../baseLayout/BaseLayout";
import "./session.css";
import { Fragment, useEffect, useState } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Stop";
import useDateTime from "../../hooks/useDateTime";
import useIntervalHook from "../../hooks/useIntervalHook";
import useCutDecimals from "../../hooks/useCutDecimals";
import EndSessionSound from "../../sounds/bell-tone.mp3";
import EndSessionSoundAtl from "../../sounds/bell-tone.ogg";
import { useRecoilState } from "recoil";
import timeDurationAtom from "../../statesManager/timeDurationAtom";
import useMakePercent from "../../hooks/useMakePercent";
import selectedSoundObjecAtom from "../../statesManager/selectedSoundObjectAtom";
import titleSoundAtom from "../../statesManager/titleSoundAtom";
import useMobile from "../../hooks/useMobile";
import "../progressCircle/progressCircle.css";
import { v4 as uuidv4 } from "uuid";
import openHistoryAtom from "../../statesManager/openHistoryAtom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Zen from "../../images/icon-zen-time.svg";
import Watch from "../../images/icon-stopwatch-line.svg";
import ModalScroll from "../../modalWithScroll/ModalScroll";
import clickedStartTimerAtom from "../../statesManager/clickedStartTimerAtom";
import navigationNameAtom from "../../statesManager/navigationNameAtom";
import useTransitionControl from "../../hooks/useTransitionControl";
import MinutesPickerComponent from "./minutesPicker/MinutesPickerComponent";
import DeleteIcon from "../../images/x-button.svg";
import BlockSettings from "./block-settings/BlockSettings";
import {
  defaultStyle,
  transitionStyles,
} from "../../common-styles/transitionStyleConstants";
import startLoaderAtom from "../../statesManager/StartLoaderAtom";
import { useHistory } from "react-router-dom";
import CountDown from "./countDown/CountDown";
import useLocalStorage from "../profile-section/storageHooks/useLocalStorage";

const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

const Session = () => {
  const MySwal = withReactContent(Swal);
  const { isMobile } = useMobile();
  // eslint-disable-next-line no-unused-vars
  const [clickedStart, setClickedStart] = useRecoilState(clickedStartTimerAtom);
  const [clickedStartLocal, setClickedStartLocal] = useState(false);
  const [titleSound, setTitleSound] = useRecoilState(titleSoundAtom);
  const [timerMins, setTimerMins] = useState(0);
  const [selectTime, setSelectTime] = useState(false);
  const { n } = useDateTime();
  const { useInterval } = useIntervalHook();
  const { precentValue, chartValue, setChartValue, setPercentValue } =
    useMakePercent();
  const { cutDecimals } = useCutDecimals();
  const [timeDuration, setTimeDuration] = useRecoilState(timeDurationAtom);
  // eslint-disable-next-line no-unused-vars
  const [navigationName, setNavigationName] =
    useRecoilState(navigationNameAtom);
  const [state, enter, exit] = useTransitionControl();
  const [secondsRemaining, setSecondsRemaining] = useState(
    JSON.parse(localStorage.getItem("timeMeditate"))
  );
  const [historymeditation, setHistoryMeditation] = useState(
    JSON.parse(localStorage.getItem("history")) ?? []
  );
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [openHistory, setOpenHistory] = useRecoilState(openHistoryAtom);
  const [selectedSound] = useRecoilState(selectedSoundObjecAtom);
  const baseMinTime = 60;
  const minsResult = timeDuration
    ? timeDuration * baseMinTime
    : timerMins * baseMinTime;
  let history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [startLoader, setStartLoader] = useRecoilState(startLoaderAtom);

  const [clock, setClock] = useLocalStorage({}, "clock");

  const [clickedBlockSettings, setClickedBlockSettings] = useState(false);

  const handleClickBlockSettings = () => {
    setClickedBlockSettings((clickedBlockSettings) => !clickedBlockSettings);
  };

  useEffect(() => {
    if (timerMins && status === "Stopped") {
      setSecondsRemaining(minsResult);
    }
    if (timeDuration) {
      setTimerMins(timeDuration);
    }

    function setPercentage() {
      setPercentValue(
        Number(cutDecimals((secondsRemaining / minsResult) * 100, 0))
      );
    }
    if (timerMins) {
      setPercentage();
    }
    if (status === "Stopped" && secondsRemaining === null) {
      localStorage.setItem("timeMeditate", secondsRemaining);
    }

    // If the status is started that send the time each second elapsed
    // And if you close the window whitout stop the counter
    return () => {
      localStorage.setItem("timeMeditate", secondsRemaining);
    };
  }, [
    cutDecimals,
    minsResult,
    secondsRemaining,
    status,
    timerMins,
    setPercentValue,
    timeDuration,
    selectedSound,
  ]);

  useEffect(() => {
    if (clickedStartLocal && chartValue < 100) {
      handleStart();
    }
    if (clickedStartLocal && chartValue === 100) {
      setStatus(STATUS.STOPPED);
      setHistoryMeditation([
        ...historymeditation,
        {
          id: uuidv4(),
          date: n,
          timestamp: Math.round(new Date().getTime() / 1000),
          time: minsResult / 60,
          sound: titleSound,
        },
      ]);
      setClickedStart(false);
      setClickedStartLocal(false);
      setSecondsRemaining(0);
      setTimeDuration(0);
      setTimeout(() => {
        setTitleSound("");
        setTimerMins(0);
      }, 2000);
      localStorage.removeItem("timeMeditate");
    }
    if (status === STATUS.STOPPED && historymeditation) {
      localStorage.setItem("history", JSON.stringify(historymeditation));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartValue, clickedStartLocal, precentValue, timerMins, setTimeDuration]);

  const handleClickStartCounter = () => {
    if (minsResult > 0) {
      setClickedStart((clickedStart) => !clickedStart);
      setClickedStartLocal((clickedStartLocal) => !clickedStartLocal);
    } else {
      return;
    }
  };
  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };

  const handleStop = () => {
    setStatus(STATUS.STOPPED);
    setClickedStart(false);
    setClickedStartLocal(false);
    setSecondsRemaining(0);
    setTimeDuration(0);
    setTimeout(() => {
      setTitleSound("");
      setTimerMins(0);
    }, 2000);
    localStorage.removeItem("timeMeditate");
    window.location.reload();
  };

  useInterval(
    () => {
      if (status === STATUS.STARTED && chartValue < 100 && chartValue >= 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
        localStorage.setItem("statusTimer", "Stopped");
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  useEffect(() => {
    if (chartValue === 100 && precentValue === 0) {
      setStatus(STATUS.STOPPED);
      setChartValue(0);

      localStorage.removeItem("timeMeditate");
      setTimeout(() => {
        history.replace("/session");
      }, 2000);
    }
    if (status === STATUS.STARTED) {
      localStorage.setItem("statusTimer", "Started");
    }
    if (status === STATUS.STOPPED) {
      localStorage.setItem("statusTimer", "Stopped");
    }
  }, [chartValue, precentValue, status, setChartValue, history]);

  const AudioEndSession = () => {
    return (
      <audio autoPlay>
        <source src={EndSessionSound} type="audio/mpeg" />
        <source src={EndSessionSoundAtl} type="audio/ogg" />
        <p>
          Your browser does not support HTML5 audio. Here is a
          <a href={`${EndSessionSound}`}>link to audio file</a>instead.
        </p>
      </audio>
    );
  };

  const handleOpenHistory = () => {
    setOpenHistory((openHistory) => !openHistory);
    setNavigationName("session");
    // if (openHistory) {
    //   window.location.replace("/");
    // }
  };
  const handleEraseHistory = () => {
    setStartLoader(true);
    setHistoryMeditation([]);
    localStorage.clear();
  };

  const deteleAll = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will not be able to go back, and your level on the application will be reset!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367f0",
      cancelButtonColor: "#ee0979",
      confirmButtonText: "Yes, reset it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleEraseHistory();
        setTimeout(() => {
          setStartLoader(false);
          Swal.fire({
            text: "History deleted, Restart the application!",
            showConfirmButton: false,
            timer: 1000,
          });
          setNavigationName("home");
          window.location.replace("/");
        }, 2000);
      }
    });
  };

  const style = {
    ...defaultStyle,
    ...(transitionStyles[state] ?? {}),
  };

  useEffect(() => {
    if (navigationName === "session" && state !== "entered") {
      enter();
    }

    if (navigationName !== "session" && state === "exiting") {
      exit();
    }
  }, [state, navigationName, enter, exit]);

  return (
    <BaseLayout>
      {chartValue === 0 &&
      precentValue === 100 &&
      status === STATUS.STOPPED &&
      !openHistory &&
      timerMins !== 0 ? (
        <AudioEndSession />
      ) : null}
      <div style={style} className={`session-container fixed`}>
        {openHistory ? (
          <Fragment>
            <div className="overlay" />
            <div className="slide-in-top">
              <ModalScroll deteleAll={deteleAll} />
            </div>
          </Fragment>
        ) : null}
        {status === STATUS.STARTED ? (
          <div className="zen-container">
            <img src={Zen} alt="zen" className="zenIcon" />
          </div>
        ) : null}
        <div
          className={
            (timerMins || titleSound) && status === STATUS.STARTED
              ? "title-session-container"
              : ""
          }
        >
          {timerMins !== 0 && status === STATUS.STARTED ? (
            <h2>
              Session duration:
              <p
                style={{
                  fontSize: 18,
                  color: "var(--purpleText)",
                }}
              >
                {timerMins} mins
              </p>{" "}
            </h2>
          ) : null}
          {titleSound && status === STATUS.STARTED ? (
            <h2>
              Session sound:
              <p
                style={{
                  fontSize: 18,
                  color: "var(--purpleText)",
                }}
              >
                {titleSound}
              </p>{" "}
            </h2>
          ) : null}
        </div>
        <br />
        {status === STATUS.STOPPED ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {historymeditation.length !== 0 && !openHistory ? (
              <button
                style={{ transform: "translate(0px, -3em)" }}
                className="btn btn--primary text-center mt-4"
                title="Historique des sessions"
                onClick={handleOpenHistory}
              >
                History
              </button>
            ) : null}
            <div className="watch-container">
              <img style={{ width: 80, height: 80 }} src={Watch} alt="timer" />
            </div>
            <h1 style={{ width: 250 }}>Your session</h1>
            <div className="headSectionTimer">
              {timerMins ? (
                <h1
                  className="session-textContent"
                  style={{ width: "100%", height: 20 }}
                >
                  Session duration:{" "}
                  <span
                    style={{
                      color: "var(--purpleText)",
                    }}
                  >
                    {" "}
                    {timerMins} minutes{" "}
                    <img
                      onClick={() => {
                        setTimerMins(0);
                        setSelectTime(false);
                      }}
                      src={DeleteIcon}
                      alt="delete"
                      style={{ width: 22, marginLeft: "1rem" }}
                    />
                  </span>
                </h1>
              ) : (
                <p style={{ color: "red !important" }}>
                  Choose the session duration.
                </p>
              )}
            </div>
          </div>
        ) : (
          <h2>Good meditation to you.</h2>
        )}
        <div
          onClick={() => setClickedBlockSettings(false)}
          className={
            !clickedStartLocal
              ? "counter-container counterNotStart"
              : "counter-container counterStart"
          }
        >
          {!clickedStartLocal ? (
            <div className="timerContent">
              {isMobile && selectTime && !timerMins ? (
                <h1
                  style={{
                    fontSize: 14,
                    maxWidth: 360,
                    color: "var(--purpleText)",
                  }}
                >
                  Tap on the chosen number to validate it
                </h1>
              ) : null}
              <form className="session-timer form-inline">
                <div className="session-timer__input">
                  {isMobile ? (
                    <MinutesPickerComponent
                      timerMins={timerMins}
                      setTimerMins={setTimerMins}
                      selectTime={selectTime}
                      setSelectTime={setSelectTime}
                    />
                  ) : null}
                  {!isMobile ? (
                    <input
                      className="form-control"
                      name="timer-minutes"
                      type="text"
                      value={timerMins}
                      onChange={(e) => setTimerMins(e.target.value)}
                    />
                  ) : null}
                  <label className="form-label" htmlFor="timer-minutes">
                    minutes
                  </label>
                </div>
              </form>
              <div className="sessionText">
                {!timerMins && !titleSound && !selectTime ? (
                  <h1
                    className="session-textContent"
                    style={{ color: "#cc5144" }}
                  >
                    Set the duration.
                  </h1>
                ) : titleSound ? (
                  <h1 className="session-textContent">
                    The sound{" "}
                    <strong style={{ color: "var(--purpleText" }}>
                      {titleSound}
                    </strong>{" "}
                    is selected{" "}
                  </h1>
                ) : null}
              </div>
            </div>
          ) : (
            <Fragment>
              {!clock ? (
                <div className="statsContainer">
                  <div className="indicator">
                    <div
                      className="progress-circle "
                      data-progress={chartValue}
                    ></div>
                  </div>
                </div>
              ) : (
                <CountDown secondsRemaining={secondsRemaining} />
              )}
            </Fragment>
          )}
        </div>
        <div
          className="buttonStartTimerContainer"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            className="buttonStartTimer"
            style={isMobile ? {} : { transform: "translate(-1em,10px)" }}
          >
            {!clickedStartLocal ? (
              <button className="startButton" onClick={handleClickStartCounter}>
                <PlayArrowIcon />
              </button>
            ) : (
              <div className="wrapperStopButton">
                <button
                  className="stopButton"
                  onClick={() => {
                    handleClickStartCounter();
                    handleStop();
                  }}
                >
                  <PauseIcon />
                </button>
              </div>
            )}
          </div>
          <br />
          <br />
          <sub
            style={{ fontSize: 14, fontWeight: "bold" }}
            title="Date et heure actuelles"
            data-toggle="tooltip"
            data-placement="left"
          >
            Date - {n}
          </sub>
        </div>
        {status === "Stopped" ? (
          <BlockSettings
            handleClickBlockSettings={handleClickBlockSettings}
            clock={clock}
            setClock={setClock}
            clickedBlockSettings={clickedBlockSettings}
            setClickedBlockSettings={setClickedBlockSettings}
          />
        ) : null}
      </div>
    </BaseLayout>
  );
};

export default Session;

import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Session from "./components/session/Session";
import Why from "./components/why/Why";
import How from "./components/how/How";
import Presets from "./components/presets/Presets";
import { useRecoilState } from "recoil";
import selectedSoundObjecAtom from "./statesManager/selectedSoundObjectAtom";
import titleSoundAtom from "./statesManager/titleSoundAtom";
import { useEffect, useState, useLayoutEffect } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "./images/iphone-install-notification/add-icon.png";
import CustomConfirm from "./components/customConfirm/CustomConfirm";
import useMobile from "./hooks/useMobile";
import clickedChildZoneAtom from "./statesManager/clickedChildZoneAtom";
import historymeditationAtom from "./statesManager/historymeditationAtom";
import attendanceLogicSelector from "./statesManager/selectors/attendanceLogicSelector";
import useSweetAlertComponent from "./baseLayout/sweetAlertComponent/useSweetAlertComponent";
import clickedStartTimerAtom from "./statesManager/clickedStartTimerAtom";
import useSessionStorage from "./components/profile-section/storageHooks/useSessionStorage";
import ModalCropper from "./components/profile-section/profilePictureResize/ModalCropper";
import ModalFilters from "./components/profile-section/profilePictureResize/ModalFilters";
import LoaderComponent from "./components/loaderComponent/LoaderComponent";
import startLoaderAtom from "./statesManager/StartLoaderAtom";

function App() {
  //  Use SessionStorage custom hook for set the compressed picture
  const [stateCompress] = useSessionStorage({}, "imgBase64Compressed");
  const [titleSound] = useRecoilState(titleSoundAtom);
  const [clickedChildZone] = useRecoilState(clickedChildZoneAtom);
  const [selectedSound] = useRecoilState(selectedSoundObjecAtom);
  const alreadyInstall = "yes";
  const { isLandscape } = useMobile();
  const [isOnWrongPosition, setIsOnWrongPosition] = useState(false);
  const [historymeditation] = useRecoilState(historymeditationAtom);
  const [is48h] = useRecoilState(attendanceLogicSelector);
  const { notRegularAlert, isRegularAlert } = useSweetAlertComponent();
  const [clickedStart] = useRecoilState(clickedStartTimerAtom);
  const [startLoader] = useRecoilState(startLoaderAtom);
  const isStartedApp = sessionStorage.getItem("started") !== null;

  useEffect(() => {
    if (isIOS) {
      const Msg = () => (
        <div className="messageIPhoneContent">
          <div className="firstColumn">
            <img src={AddIcon} alt="add icon" />
            &ensp;
            <b style={{ color: "black", fontSize: 14 }}>
              This application is not fully compatible with your IPhone:{" "}
            </b>
            <br />
          </div>
          <div className="secondColumn">
            <b style={{ fontSize: 14 }}>No sound can be used!</b>
          </div>
          <div className="alreadyInstalled">
            <b style={{ fontSize: 14 }}>
              I'm sorry for the inconvenience, it's due to Apple's poor policy
              and poor efforts of Apple towards web developers.
            </b>
          </div>
        </div>
      );
      if (localStorage.getItem("alreadyInstalled") === null) {
        const displayMsg = () => {
          toast(<Msg />);
        };
        displayMsg();
      }
    }
  }, []);

  const screen = window.screen;
  const { JSalert } = CustomConfirm();

  let orientation =
    (screen.orientation || {}).type ||
    screen.mozOrientation ||
    screen.msOrientation;

  useEffect(() => {
    setIsOnWrongPosition(true);
    if (orientation === "landscape-primary") {
      setIsOnWrongPosition(true);
      if (isOnWrongPosition && isMobile && !clickedChildZone) {
        JSalert();
      }
    } else if (orientation === "landscape-secondary") {
      setIsOnWrongPosition(true);
      if (isOnWrongPosition && isMobile && !clickedChildZone) {
        JSalert();
      }
    } else if (
      orientation === "portrait-secondary" ||
      orientation === "portrait-primary"
    ) {
      setIsOnWrongPosition(false);
    } else if (orientation === undefined) {
      setIsOnWrongPosition(false);
      console.log("The orientation API isn't supported in this browser :(");
    }
  }, [
    screen,
    JSalert,
    orientation,
    isLandscape,
    isOnWrongPosition,
    setIsOnWrongPosition,
    clickedChildZone,
  ]);

  useLayoutEffect(() => {
    let isStartedApp = true;
    const isTimestamp = JSON.parse(localStorage.getItem("history")) !== null;
    const historyMed = historymeditation.length >= 2;
    const attendanceMessage = sessionStorage.getItem("attendance") !== null;

    if (isTimestamp && historyMed && !attendanceMessage) {
      if (isStartedApp && !startLoader) {
        setTimeout(() => {
          if (is48h) {
            notRegularAlert();
          } else if (!is48h) {
            isRegularAlert();
          }
        }, 4970);

        setTimeout(() => {
          sessionStorage.setItem("attendance", true);
        }, 4970);
      }
    }

    // console.log("startLoader", startLoader);
    // console.log("isStartedApp", isStartedApp);
  }, [
    historymeditation,
    is48h,
    notRegularAlert,
    isRegularAlert,
    startLoader,
    isStartedApp,
  ]);

  const SessionSoundSelected = () => {
    const { sound, alt } = selectedSound;
    return (
      <audio autoPlay loop>
        <source src={`${sound}`} type="audio/mpeg" />
        <source src={`${alt}`} type="audio/ogg" />
        <p>
          Your browser does not support HTML5 audio. Here is a{" "}
          <a href={`${sound}`}>link to audio file</a>instead.
        </p>
      </audio>
    );
  };

  return (
    <div>
      {titleSound !== "" && selectedSound && clickedStart ? (
        <SessionSoundSelected />
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 999 }}
        onClick={() => localStorage.setItem("alreadyInstalled", alreadyInstall)}
      />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/why">
          <Why />
        </Route>
        <Route path="/how">
          <How />
        </Route>
        <Route path="/presets">
          <Presets />
        </Route>
        <Route path="/session">
          <Session />
        </Route>
        <Route path="/cropper-modal">
          <ModalCropper stateCompress={stateCompress} />
        </Route>
        <Route path="/filters-tool">
          <ModalFilters />
        </Route>
        <Route path="/loader">
          <LoaderComponent />
        </Route>
      </Switch>
    </div>
  );
}

export default App;

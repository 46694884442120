import { useEffect, useRef, useState } from "react";
import ChildMeditations from "./ChildMeditations";

const MediasSelection = ({
  clickedStressTheme,
  stressTheme,
  clickedApprentissageTheme,
  ApprentissageTheme,
  clickedWithMusicTheme,
  WhitMusicTheme,
  clickedBienveillanceTheme,
  BienveillanceTheme,
  clickedConfianceTheme,
  ConfianceTheme,
  clickedColereTheme,
  colereTheme,
  clickedTristeTheme,
  tristeTheme,
  clickedSommeilTheme,
  sommeilTheme,
}) => {
  const [stressSec, setStressSec] = useState([]);
  const [apprentissageSec, setApprentissageSec] = useState([]);
  const [withMusicSec, setWithMusicSec] = useState([]);
  const [bienveillanceSec, setBienveillanceSec] = useState([]);
  const [confianceSec, setConfianceSec] = useState([]);
  const [colereSec, setColereSec] = useState([]);
  const [tristeSec, setTristeSec] = useState([]);
  const [sommeilSec, setSommeilSec] = useState([]);
  const componentMounted = useRef(true);

  useEffect(() => {
    let controller = new AbortController();

    // Stress section Async function
    async function loadStressTheme() {
      const stressList = await stressTheme;
      setStressSec(stressList);
      // console.log("stressList", stressSec);
    }
    if (clickedStressTheme) {
      loadStressTheme();
    }

    // Apprentissage section Async function
    async function loadApprentissageTheme() {
      const ApprentissageList = await ApprentissageTheme;
      setApprentissageSec(ApprentissageList);
      // console.log("apprentissageSec", apprentissageSec);
    }
    if (clickedApprentissageTheme) {
      loadApprentissageTheme();
    }

    // With music section Async function
    async function loadwithMusicTheme() {
      const withMusicList = await WhitMusicTheme;
      setWithMusicSec(withMusicList);
      // console.log("withMusicSec", withMusicSec);
    }
    if (clickedWithMusicTheme) {
      loadwithMusicTheme();
    }

    // Bienveillance section Async function
    async function loadBienveillanceTheme() {
      const BienveillanceList = await BienveillanceTheme;
      setBienveillanceSec(BienveillanceList);
      // console.log("bienveillanceSec", bienveillanceSec);
    }
    if (clickedBienveillanceTheme) {
      loadBienveillanceTheme();
    }

    // Confiance section Async function
    async function loadConfianceTheme() {
      const ConfianceList = await ConfianceTheme;
      setConfianceSec(ConfianceList);
      // console.log("confianceSec", confianceSec);
    }
    if (clickedConfianceTheme) {
      loadConfianceTheme();
    }

    // Colère section Async function
    async function loadColereTheme() {
      const colereList = await colereTheme;
      setColereSec(colereList);
      // console.log("colereSec", colereSec);
    }
    if (clickedColereTheme) {
      loadColereTheme();
    }

    // Triste section Async function
    async function loadTristeTheme() {
      const TristeList = await tristeTheme;
      setTristeSec(TristeList);
      // console.log("tristeSec", tristeSec);
    }
    if (clickedTristeTheme) {
      loadTristeTheme();
    }

    // Sommeil section Async function
    async function loadSommeilTheme() {
      const SommeilList = await sommeilTheme;
      setSommeilSec(SommeilList);
      // console.log("sommeilSec", sommeilSec);
    }
    if (clickedSommeilTheme) {
      loadSommeilTheme();
    }

    return () => {
      componentMounted.current = false;
      controller?.abort();
    };
  }, [
    stressTheme,
    stressSec,
    clickedStressTheme,
    ApprentissageTheme,
    apprentissageSec,
    clickedApprentissageTheme,
    clickedWithMusicTheme,
    WhitMusicTheme,
    withMusicSec,
    BienveillanceTheme,
    clickedBienveillanceTheme,
    bienveillanceSec,
    ConfianceTheme,
    confianceSec,
    clickedConfianceTheme,
    colereTheme,
    colereSec,
    clickedColereTheme,
    tristeTheme,
    clickedTristeTheme,
    tristeSec,
    sommeilTheme,
    clickedSommeilTheme,
    sommeilSec,
  ]);

  return (
    <div className="categories-container">
      {clickedStressTheme
        ? stressSec &&
          stressSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedApprentissageTheme
        ? apprentissageSec &&
          apprentissageSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedWithMusicTheme
        ? withMusicSec &&
          withMusicSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedBienveillanceTheme
        ? bienveillanceSec &&
          bienveillanceSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedConfianceTheme
        ? confianceSec &&
          confianceSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedColereTheme
        ? colereSec &&
          colereSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedTristeTheme
        ? tristeSec &&
          tristeSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
      {clickedSommeilTheme
        ? sommeilSec &&
          sommeilSec.map((res) => (
            <ChildMeditations key={res.id} embedId={`${res.embedId}`} />
          ))
        : null}
    </div>
  );
};

export default MediasSelection;

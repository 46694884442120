const defaultStyle = {
  transition: "opacity 500ms ease-in-out",
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export { defaultStyle, transitionStyles };

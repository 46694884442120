import { useEffect, useState } from "react";
import Buddha from "./assets/buddha-baby.png";

const ButtonChildSection = ({ clickedChildZone, handleClickChildZone }) => {
  const [clickedLabelChild, setClickedLabelChild] = useState(false);

  const handleClickLabelChild = () => {
    setClickedLabelChild((clickedLabelChild) => !clickedLabelChild);
  };

  useEffect(() => {
    const LabelTimeOut = setTimeout(() => {
      if (clickedLabelChild) {
        setClickedLabelChild(false);
      }
    }, 3000);

    return () => clearTimeout(LabelTimeOut);
  }, [clickedLabelChild, handleClickChildZone]);
  return (
    <div
      className={
        clickedChildZone
          ? "buttonChild-container buttonChild-container-active"
          : "buttonChild-container"
      }
    >
      <button>
        <span
          onClick={handleClickLabelChild}
          className={
            clickedLabelChild
              ? "child-button-label--active"
              : clickedChildZone
              ? "child-button-label child-button-label-inZone"
              : "child-button-label"
          }
        >
          {clickedChildZone ? (
            <p className="child-label-text">Leave the section</p>
          ) : (
            <p className="child-label-text">Children's section</p>
          )}
        </span>
        {clickedLabelChild ? <span id="triangle-child-level"></span> : null}
        <span
          className={
            clickedLabelChild ? "child-button-img--active" : "child-button-img"
          }
        >
          <img
            onClick={() => {
              handleClickChildZone();
            }}
            src={Buddha}
            alt="buddha"
            style={{
              width: 80,
              height: "auto",
              border: "1px solid purple",
              borderRadius: "50%",
              boxShadow: "0 2px 6px #302e2e",
            }}
          />
        </span>
      </button>
    </div>
  );
};

export default ButtonChildSection;

import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import BaseLayout from "../../baseLayout/BaseLayout";
import HourGlass from "../../images/icon-hourglass.svg";
import timeDurationAtom from "../../statesManager/timeDurationAtom";
import "./presets.css";
import selectedSoundObjecAtom from "../../statesManager/selectedSoundObjectAtom";
import titleSoundAtom from "../../statesManager/titleSoundAtom";
import OkIcon from "../../images/ok.svg";
import DeleteIcon from "../../images/x-button.svg";
import { VolumeOffTwoTone, VolumeUpTwoTone } from "@material-ui/icons";
import TimerPresets from "../../images/timer-presets.svg";
import StartIcon from "../../images/start-no.svg";
import {
  TimePresets,
  soundPresets,
  intermediateSoundPresets,
  confirmedSoundPresets,
  expertSoundPresets,
} from "./dataSounds";
import HeaderPresets from "./HeaderPresets";
import navigationNameAtom from "../../statesManager/navigationNameAtom";
import GoToTimerButton from "./GoToTimerButton";
import levelsNameAtom from "../../statesManager/levelsNameAtom";
import useSoundsToPlay from "../../hooks/useSoundsToPlay";
import useTransitionControl from "../../hooks/useTransitionControl";
import {
  defaultStyle,
  transitionStyles,
} from "../../common-styles/transitionStyleConstants";

const Presets = () => {
  let history = useHistory();
  const [state, enter, exit] = useTransitionControl();
  const { SoundList, SoundListAlt } = useSoundsToPlay();
  const [levelsName] = useRecoilState(levelsNameAtom);
  const [timeDuration, setTimeDuration] = useRecoilState(timeDurationAtom);
  // eslint-disable-next-line no-unused-vars
  const [selectedSound, setSelectedSound] = useRecoilState(
    selectedSoundObjecAtom
  );
  const [titleSound, setTitleSound] = useRecoilState(titleSoundAtom);
  // eslint-disable-next-line no-unused-vars
  const [navigationName, setNavigationName] =
    useRecoilState(navigationNameAtom);

  useEffect(() => {
    if (timeDuration) {
      localStorage.setItem("timeMeditate", timeDuration * 60);
    }
  }, [timeDuration]);

  const style = {
    ...defaultStyle,
    ...(transitionStyles[state] ?? {}),
  };

  useEffect(() => {
    if (navigationName === "presets" && state !== "entered") {
      enter();
    }

    if (navigationName !== "presets" && state === "exiting") {
      exit();
    }
  }, [state, navigationName, enter, exit]);

  const AudioSelected = () => {
    return (
      <audio autoPlay>
        <source src={SoundList} type="audio/mpeg" />
        <source src={SoundListAlt} type="audio/ogg" />
        <p>
          Your browser does not support HTML5 audio. Here is a{" "}
          <a href={`${SoundList}`}>link to audio file</a>instead.
        </p>
      </audio>
    );
  };

  return (
    <BaseLayout>
      <div style={style} className={`presets-container`}>
        {titleSound !== "" && navigationName === "presets" ? (
          <AudioSelected />
        ) : null}
        <br />
        {titleSound || timeDuration ? (
          <HeaderPresets
            timeDuration={timeDuration}
            OkIcon={OkIcon}
            DeleteIcon={DeleteIcon}
            setTimeDuration={setTimeDuration}
            StartIcon={StartIcon}
            titleSound={titleSound}
            VolumeOffTwoTone={VolumeOffTwoTone}
            VolumeUpTwoTone={VolumeUpTwoTone}
            setTitleSound={setTitleSound}
          />
        ) : null}
        <img
          className="preset-img-title"
          src={HourGlass}
          alt=""
          style={{ width: 30 }}
        />
        <br />
        <h1>Presets to get you started</h1>
        <div
          className={timeDuration || titleSound ? "hiddenText" : "visibleText"}
        >
          <blockquote
            className="presentation-presets"
            style={{ wordBreak: "break-word", padding: 15 }}
          >
            Don't know how long your meditation session should last? meditation
            session? No problem! Here are some presets to get you started. to
            get you started.
          </blockquote>
        </div>
        <GoToTimerButton
          timeDuration={timeDuration}
          titleSound={titleSound}
          setNavigationName={setNavigationName}
          history={history}
        />
        <br />
        <hr className="hr-preset" style={{ width: "100%", opacity: 0.2 }} />
        <div
          className={
            !timeDuration
              ? "presets-content"
              : "presets-content preset-content-hidden"
          }
        >
          <div className="buttonPresets-container">
            {TimePresets.map((res) => (
              <button
                className={`buttonPresets preset1 ${res.time} ${
                  res.time === timeDuration
                    ? " buttonPresets--active sepia"
                    : ""
                }`}
                key={res.id}
                onClick={() => setTimeDuration(res.time)}
              >
                <div className="soundPresets-content">
                  <img
                    src={TimerPresets}
                    alt="presetsIcon"
                    style={{ width: "7em" }}
                  />
                  <h2 className="timerName">{res.title}</h2>
                </div>
              </button>
            ))}
          </div>
          <br />
          <hr style={{ width: "100%", opacity: 0.2 }} />
          <br />
          <div className="buttonPresets-container">
            {soundPresets.map((res) => (
              <button
                className={`buttonPresets ${res.title} ${
                  res.title === titleSound ? "buttonPresets--active sepia" : ""
                }`}
                key={res.id}
                onClick={() => {
                  setSelectedSound({
                    id: res.id,
                    title: res.title,
                    sound: res.sound,
                    alt: res.alt,
                  });
                  setTitleSound(res.title);
                }}
              >
                <div className="sound-content">
                  <img
                    className={`soundPicture ${res.title}`}
                    src={res.img}
                    alt="icon"
                  />
                  <h2 className="soundTitle">{res.title}</h2>
                </div>
              </button>
            ))}
          </div>
          <br />
          <hr style={{ width: "100%", opacity: 0.2 }} />
          {levelsName !== "none" && levelsName !== "beginner" ? (
            <div className="buttonPresets-container">
              {intermediateSoundPresets.map((res) => (
                <button
                  className={`buttonPresets intermediate ${res.title} ${
                    res.title === titleSound
                      ? "buttonPresets--active sepia"
                      : ""
                  }`}
                  key={res.id}
                  onClick={() => {
                    setSelectedSound({
                      id: res.id,
                      title: res.title,
                      sound: res.sound,
                      alt: res.alt,
                    });
                    setTitleSound(res.title);
                  }}
                >
                  <div className="sound-contentIntermed">
                    <span className="pictureMantra">
                      <img
                        className={`soundPicture ${res.title}`}
                        src={res.img}
                        alt="icon"
                      />
                    </span>
                    <span className="textMantra">
                      <h2 className=" soundTitleIntermed">{res.title}</h2>
                    </span>
                  </div>
                </button>
              ))}
            </div>
          ) : null}
          <br />
          {levelsName !== "none" &&
          levelsName !== "beginner" &&
          levelsName !== "intermed" ? (
            <hr style={{ width: "100%", opacity: 0.2 }} />
          ) : null}
          {levelsName !== "none" &&
          levelsName !== "beginner" &&
          levelsName !== "intermed" ? (
            <div className="buttonPresets-container">
              {confirmedSoundPresets.map((res) => (
                <button
                  className={`buttonPresets confirmed ${res.title} ${
                    res.title === titleSound
                      ? "buttonPresets--active sepia"
                      : ""
                  }`}
                  key={res.id}
                  onClick={() => {
                    setSelectedSound({
                      id: res.id,
                      title: res.title,
                      sound: res.sound,
                      alt: res.alt,
                    });
                    setTitleSound(res.title);
                  }}
                >
                  <div className="sound-contentConfirmed ">
                    <span className="pictureFrequency">
                      <img
                        className={`soundPicture soundPictureConfirmed ${res.title}`}
                        src={res.img}
                        alt="icon"
                      />
                    </span>
                    <span className="textFrequency">
                      <h2 className=" soundTitleConfirmed">{res.title}</h2>
                    </span>
                  </div>
                  {titleSound === res.title ? (
                    <span className="text-tooltip text-tooltip--active">
                      <sub style={{ fontSize: 14 }}>{res.sub}</sub>
                      <span id="triangle-code"></span>
                    </span>
                  ) : (
                    <span className="text-tooltip">
                      <sub style={{ fontSize: 14 }}>{res.sub}</sub>
                    </span>
                  )}
                </button>
              ))}
            </div>
          ) : null}
          {levelsName !== "none" &&
          levelsName !== "beginner" &&
          levelsName !== "intermed" &&
          levelsName !== "confirmed" ? (
            <Fragment>
              <br />
              <hr style={{ width: "100%", opacity: 0.2 }} />
            </Fragment>
          ) : null}
          {levelsName !== "none" &&
          levelsName !== "beginner" &&
          levelsName !== "intermed" &&
          levelsName !== "confirmed" ? (
            <div className="buttonPresets-container">
              {expertSoundPresets.map((res) => (
                <button
                  className={`buttonPresets confirmed ${res.title} ${
                    res.title === titleSound
                      ? "buttonPresets--active sepia"
                      : ""
                  }`}
                  key={res.id}
                  onClick={() => {
                    setSelectedSound({
                      id: res.id,
                      title: res.title,
                      sound: res.sound,
                      alt: res.alt,
                    });
                    setTitleSound(res.title);
                  }}
                >
                  <div className="sound-contentConfirmed ">
                    <span className="pictureFrequency">
                      <img
                        className={`soundPicture soundPictureConfirmed ${res.title}`}
                        src={res.img}
                        alt="icon"
                      />
                    </span>
                    <span className="textFrequency">
                      <h2 className=" soundTitleConfirmed">{res.title}</h2>
                    </span>
                  </div>
                  {titleSound === res.title ? (
                    <span className="text-tooltip text-tooltip--active">
                      <sub style={{ fontSize: 14 }}>{res.sub}</sub>
                      <span id="triangle-code"></span>
                    </span>
                  ) : (
                    <span className="text-tooltip">
                      <sub style={{ fontSize: 14 }}>{res.sub}</sub>
                    </span>
                  )}
                </button>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </BaseLayout>
  );
};

export default Presets;

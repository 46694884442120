import "./baseLayout.css";
import BottomNavigation from "../components/bottomNavigation/BottomNavigation";
import ScrollTop from "./scroll-top-button/ScrollTop";
import { useRecoilState } from "recoil";
import clickedStartTimerAtom from "../statesManager/clickedStartTimerAtom";
import QrCodeShare from "../components/qrcodeShare/QrCodeShare";
import qrcodeShareAtom from "../statesManager/qrcodeShareAtom";
import LevelBadges from "./levelBadges/LevelBadges";
import levelsNameAtom from "../statesManager/levelsNameAtom";
import { useEffect, useState } from "react";
import newLevelAtom from "../statesManager/newLevelAtom";
import navigationNameAtom from "../statesManager/navigationNameAtom";
import useDarkModeTheme from "../hooks/useDarkModeTheme";
import AttendanceBadge from "./attendanceBadge/AttendanceBadge";
import attendanceLogicSelector from "../statesManager/selectors/attendanceLogicSelector";
import historymeditationAtom from "../statesManager/historymeditationAtom";
import useSweetAlertComponent from "./sweetAlertComponent/useSweetAlertComponent";
import DarkSwitchButton from "./darkSwitch/DarkSwitchButton";
import CuriousBadgeComponent from "./curiousBadge/CuriousBadgeComponent";
import clickedChangeLogoAtom from "../statesManager/clickedChangeLogoAtom";
import LogoChoiceComponent from "../components/home/logoChoices/LogoChoiceComponent";
import profileAvatar from "../images/user-profile.svg";
import graduate from "../images/notFreeMode/Graduate3.svg";
import Student from "../images/notFreeMode/student.svg";
import Teacher from "../images/notFreeMode/teacher.svg";
import BestLearnerBadge from "./bestLearnerBadge/BestLearnerBadge";
import howManyClickAtom from "../statesManager/howManyClickAtom";
import UseNetworkExample from "./networkInfo/NetworkExample";
import clickedConnexionAtom from "../statesManager/clickedConnexionAtom";
import NetworkModal from "./networkInfo/NetworkModal";
import { isMobile } from "react-device-detect";
import clickedProfileAtom from "../statesManager/clickedProfileAtom";
import ProfileComponent from "../components/profile-section/ProfileComponent";
import LoaderComponent from "../components/loaderComponent/LoaderComponent";
import startLoaderAtom from "../statesManager/StartLoaderAtom";
import openOptionsAtom from "../statesManager/openOptionsAtom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const BaseLayout = (props) => {
  const MySwal = withReactContent(Swal);
  const { mode, onSelectMode } = useDarkModeTheme();
  const [historymeditation] = useRecoilState(historymeditationAtom);
  const {
    intermedAlertComponent,
    confirmedAlertComponent,
    expertAlertComponent,
  } = useSweetAlertComponent();
  const { children } = props;
  const [clickedStartTimerButton] = useRecoilState(clickedStartTimerAtom);
  const [qrcodeShare] = useRecoilState(qrcodeShareAtom);
  const [levelsName] = useRecoilState(levelsNameAtom);
  const [newLevel, setNewLevel] = useRecoilState(newLevelAtom);
  const [intermedAlert] = useState(
    localStorage.getItem("IntermedMessage") ?? null
  );
  const [confirmedAlert] = useState(
    localStorage.getItem("confirmedMessage") ?? null
  );
  const [expertAlert] = useState(localStorage.getItem("expertMessage") ?? null);
  const historyMed = historymeditation.length >= 2;
  const [navigationName] = useRecoilState(navigationNameAtom);
  const [is48h] = useRecoilState(attendanceLogicSelector);
  const attendanceMessage = sessionStorage.getItem("attendance") !== null;
  const [curiousBadgeAppear, setCuriousBadgeAppear] = useState(false);
  const [clickedChangeLogo] = useRecoilState(clickedChangeLogoAtom);
  const [howManyClick] = useRecoilState(howManyClickAtom);
  const [clickedConnexion] = useRecoilState(clickedConnexionAtom);
  const [clickedProfile, setClickedProfile] =
    useRecoilState(clickedProfileAtom);
  const [startLoader] = useRecoilState(startLoaderAtom);

  const [openOptions, setOpenOptions] = useRecoilState(openOptionsAtom);

  useEffect(() => {
    const allSessionsLenght = historymeditation.length;
    if (allSessionsLenght >= 2) {
      const SessionWithSoundLenght = historymeditation.filter(
        (res) => res.sound !== ""
      ).length;
      if (SessionWithSoundLenght >= allSessionsLenght / 2) {
        setCuriousBadgeAppear(true);
      } else {
        setCuriousBadgeAppear(false);
      }
    }
  }, [historymeditation]);

  useEffect(() => {
    if (levelsName === "intermed" && intermedAlert === null) {
      intermedAlertComponent();

      localStorage.setItem("IntermedMessage", "ok");
      localStorage.removeItem("evolution");
    }

    if (levelsName === "confirmed" && confirmedAlert === null) {
      confirmedAlertComponent();
      localStorage.setItem("confirmedMessage", "ok");
      localStorage.removeItem("IntermedMessage");
      localStorage.removeItem("evolution");
    }

    if (levelsName === "expert" && expertAlert === null) {
      expertAlertComponent();

      localStorage.setItem("expertMessage", "ok");
      localStorage.removeItem("confirmedMessage");
      localStorage.removeItem("evolution");
    }
  }, [
    intermedAlertComponent,
    confirmedAlertComponent,
    expertAlertComponent,
    intermedAlert,
    levelsName,
    confirmedAlert,
    expertAlert,
  ]);

  useEffect(() => {
    async function loadContent() {
      if (
        navigationName === "home" &&
        localStorage.getItem("evolution") === null
      ) {
        switch (levelsName) {
          case "beginner":
            setNewLevel(true);
            setTimeout(() => {
              localStorage.setItem("evolution", "done1");
            }, 500);
            break;
          case "intermed":
            setNewLevel(true);
            setTimeout(() => {
              localStorage.setItem("evolution", "done2");
            }, 500);
            break;
          case "confirmed":
            setNewLevel(true);
            setTimeout(() => {
              localStorage.setItem("evolution", "done3");
            }, 500);
            break;
          case "expert":
            setNewLevel(true);
            setTimeout(() => {
              localStorage.setItem("evolution", "done4");
            }, 500);
            break;
          default:
            setNewLevel(false);
            break;
        }
      }
      if (localStorage.getItem("evolution") !== null) {
        setNewLevel(false);
      }
    }

    async function loadWithConditions() {
      await loadContent();
    } // Execute the created function directly
    loadWithConditions();
  }, [newLevel, setNewLevel, navigationName, levelsName]);

  const handleClickProfile = () => {
    setClickedProfile((clickedProfile) => !clickedProfile);
  };

  useEffect(() => {
    if (openOptions) {
      MySwal.fire({
        title: "E-learning Mode.",
        html: `
        <div>
          <strong>This is where you choose whether you want to learn or pass on knowledge.</strong>
          <div style="display:flex">
            <button class="buttonsOptions"><h5>Learner</h5> <img  class="modesPictures" src=${Student} alt="help" /><p style="filter:opacity(0.5)">Click to register</p></button>
            <button class="buttonsOptions"><h5>Teacher</h5> <img  class="modesPictures" src=${Teacher} alt="help" /><p style="filter:opacity(0.5)">Click to register</p></button>
          </div>
        </div>
          <span class="levels-content helpContent"><p style="color:red">Service not yet available...<br /><strong style="color:black">It will be available at the end of the first quarter of 2022.</strong></p>
          <span style="font-size:14px">You can visit the website <a href="https://my-meditation-time.org" target="new">My Meditation Time.org</a> to subscribe to the newsletter, so you will be informed as soon as the service is released.</span>
        </div>`,
        imageUrl: graduate,
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: "Custom image",
      }).then((openOptions) => {
        if (openOptions) {
          setOpenOptions(false);
        }
      });
    }
  }, [openOptions, MySwal, setOpenOptions]);

  return (
    <div className="layout-container-App" id="top fullpage">
      <main className="cover-App" id="cover-App">
        {clickedChangeLogo ? (
          <div className="logoChoice-content">
            {" "}
            <div className="overlay-logoChoice"></div>
            <LogoChoiceComponent />
          </div>
        ) : null}
        <DarkSwitchButton mode={mode} onSelectMode={onSelectMode} />
        <div className="badges-section">
          {levelsName !== "" && !clickedStartTimerButton ? (
            <LevelBadges />
          ) : null}
          {historyMed &&
          !is48h &&
          attendanceMessage &&
          !clickedStartTimerButton ? (
            <AttendanceBadge />
          ) : null}
          {curiousBadgeAppear && !clickedStartTimerButton ? (
            <CuriousBadgeComponent />
          ) : null}
          {howManyClick && howManyClick >= 6 && !clickedStartTimerButton ? (
            <BestLearnerBadge />
          ) : null}
        </div>
        {isMobile && navigationName !== "presets" ? (
          <div className="networkContent ">
            <UseNetworkExample />
          </div>
        ) : null}
        {qrcodeShare ? <QrCodeShare /> : null}
        {clickedConnexion ? <NetworkModal /> : null}
        {startLoader ? <LoaderComponent /> : null}
        <div className="wrapper-App">{children}</div>
        {navigationName === "home" ? (
          <img
            onClick={handleClickProfile}
            className="configIcon"
            src={profileAvatar}
            alt="config"
            style={{ width: 55, height: 50 }}
          />
        ) : null}
        {clickedProfile ? (
          <div className="content-profile">
            <ProfileComponent />
          </div>
        ) : null}
      </main>
      <ScrollTop />
      {!clickedStartTimerButton ? (
        <BottomNavigation className="slide-in-top" />
      ) : null}
    </div>
  );
};

export default BaseLayout;

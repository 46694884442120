import { useEffect } from "react";
import { useRecoilState } from "recoil";
import homeLinkAtom from "../statesManager/homeLinkAtom";
import howLinkAtom from "../statesManager/howLinkAtom";
import navigationNameAtom from "../statesManager/navigationNameAtom";
import presetsLinkAtom from "../statesManager/presetsLinkAtom";
import sessionLinkAtom from "../statesManager/sessionLinkAtom";
import whyLinkAtom from "../statesManager/whyLinkAtom";

const useSwitchIconsNavigation = () => {
  const [homeLink, setHomeLink] = useRecoilState(homeLinkAtom);
  const [WhyLink, setWhyLink] = useRecoilState(whyLinkAtom);
  const [HowLink, setHowLink] = useRecoilState(howLinkAtom);
  const [PresetsLink, setPresetsLink] = useRecoilState(presetsLinkAtom);
  const [SessionLink, setSessionLink] = useRecoilState(sessionLinkAtom);
  const [navigationName, setNavigationName] =
    useRecoilState(navigationNameAtom);

  useEffect(() => {
    switch (navigationName) {
      case "home":
        setHomeLink(true);
        setHowLink(false);
        setPresetsLink(false);
        setSessionLink(false);
        setWhyLink(false);
        break;
      case "why":
        setWhyLink(true);
        setHomeLink(false);
        setHowLink(false);
        setPresetsLink(false);
        setSessionLink(false);
        break;
      case "how":
        setHowLink(true);
        setHomeLink(false);
        setPresetsLink(false);
        setSessionLink(false);
        setWhyLink(false);
        break;
      case "presets":
        setPresetsLink(true);
        setHomeLink(false);
        setSessionLink(false);
        setWhyLink(false);
        setHowLink(false);
        break;
      case "session":
        setSessionLink(true);
        setHomeLink(false);
        setHowLink(false);
        setPresetsLink(false);
        setWhyLink(false);
        break;
      default:
        break;
    }
    sessionStorage.setItem("navigation", navigationName);
  }, [
    HowLink,
    WhyLink,
    PresetsLink,
    SessionLink,
    homeLink,
    setHowLink,
    setHomeLink,
    setSessionLink,
    setPresetsLink,
    setWhyLink,
    setNavigationName,
    navigationName,
  ]);

  const handleChangeHome = () => {
    setNavigationName("home");
  };
  const handleChangeWhy = () => {
    setNavigationName("why");
  };
  const handleChangeHow = () => {
    setNavigationName("how");
  };
  const handleChangePresets = () => {
    setNavigationName("presets");
  };
  const handleChangeSession = () => {
    setNavigationName("session");
  };

  return {
    homeLink,
    setHomeLink,
    WhyLink,
    setWhyLink,
    HowLink,
    setHowLink,
    PresetsLink,
    setPresetsLink,
    SessionLink,
    setSessionLink,
    setNavigationName,
    navigationName,
    handleChangeHome,
    handleChangeWhy,
    handleChangeHow,
    handleChangePresets,
    handleChangeSession,
  };
};

export default useSwitchIconsNavigation;

import "./loader-style.css";

const LoaderComponent = () => {
  return (
    <div className="loaderApp-container">
      <div className="container-load">
        <div className="dotload dotload-1"></div>
        <div className="dotload dotload-2"></div>
        <div className="dotload dotload-3"></div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default LoaderComponent;

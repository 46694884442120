import { useEffect } from "react";
import NumberSelectStyle from "../../../images/counterOptions/24-hours-select.svg";
import PercentSelectStyle from "../../../images/counterOptions/percent-select.svg";
import NumberNotStyle from "../../../images/counterOptions/24-hours-not.svg";
import PercentNotStyle from "../../../images/counterOptions/percent-not.svg";

import "./blockSetting-style.css";

const BlockSettings = ({
  handleClickBlockSettings,
  clickedBlockSettings,
  setClickedBlockSettings,
  clock,
  setClock,
}) => {
  useEffect(() => {
    if (localStorage.getItem("clock") === null) {
      localStorage.setItem("clock", false);
      setClock(false);
    }
  }, [setClock]);
  return (
    <div
      className={
        clickedBlockSettings
          ? "block-settings-wrapper opened-settings"
          : "block-settings-wrapper"
      }
    >
      <div id="block_settings" className="block_settings">
        <h5 style={{ textAlign: "center", background: "#5707de", padding: 6 }}>
          Chrono Config
        </h5>
        <section>
          <button
            onClick={() => {
              setClock(false);
              localStorage.setItem("clock", false);
              setClickedBlockSettings(false);
            }}
            className={!clock ? "buttonChoice  buttonSelected" : "buttonChoice"}
          >
            <img
              src={clock ? PercentNotStyle : PercentSelectStyle}
              alt="percent"
              className="choicesTime"
            />
            <p>Percent</p>
          </button>
          <button
            onClick={() => {
              setClock(true);
              localStorage.setItem("clock", true);
              setClickedBlockSettings(false);
            }}
            className={clock ? "buttonChoice buttonSelected" : "buttonChoice"}
          >
            <img
              src={clock ? NumberSelectStyle : NumberNotStyle}
              alt="number"
              className="choicesTime"
            />
            <p>Countdown</p>
          </button>
        </section>
        <span onClick={handleClickBlockSettings} id="settings_close"></span>
      </div>
    </div>
  );
};

export default BlockSettings;

import { useRecoilState } from "recoil";

import historymeditationAtom from "../statesManager/historymeditationAtom";

const usePercentAssiduity = () => {
  const [historymeditation] = useRecoilState(historymeditationAtom);

  let timeStampToday = Math.round(new Date().getTime() / 1000);
  let timeStamp48h = timeStampToday - 48 * 3600;
  let timeStamp24h = timeStampToday - 24 * 3600;
  const last_session = historymeditation[historymeditation.length - 1];
  const date1 = last_session?.timestamp;
  let totalZero = date1 <= timeStamp48h;
  let totalHalf = date1 <= timeStamp24h;

  let pourcentAssiduity = !totalZero && !totalHalf ? 100 : totalHalf ? 50 : 0;
  return [pourcentAssiduity];
};

export default usePercentAssiduity;

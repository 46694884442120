import "../logoChoices/LevelStyle.css";
import {
  logo,
  FullLogo,
  LogoA,
  LogoB,
  LogoC,
  LogoD,
  LogoE,
  LogoF,
  LogoG,
  LogoH,
  LogoI,
  LogoJ,
  LogoK,
  LogoL,
  LogoM,
  LogoN,
  LogoBis,
  LogoBuddha,
  LogoBuddha2,
  LogoChild2,
  LogoChild3,
  LogoChild4,
  LogoChild5,
  LogoGourou,
  LogoJapan,
  LogoMan,
  LogoOhm,
  LogoShadow,
  LogoFull2,
  LogoFull3,
  LogoSunshine,
} from "../logoChoices/LogoExports";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import clickedChangeLogoAtom from "../../../statesManager/clickedChangeLogoAtom";
import selectedLogoAtom from "../../../statesManager/selectedLogoAtom";
import choosedLogoAtom from "../../../statesManager/choosedLogoAtom";
import { Fragment } from "react";
import LogoConfig from "../../../images/logo-choices/config-purple.svg";

import clickedProfileAtom from "../../../statesManager/clickedProfileAtom";

const LevelLogo = ({ historymeditation }) => {
  const [clickedChangeLogo, setClickedChangeLogo] = useRecoilState(
    clickedChangeLogoAtom
  );
  // eslint-disable-next-line no-unused-vars
  const [selectedLogo, setSelectedLogo] = useRecoilState(selectedLogoAtom);
  const [choosedLogo] = useRecoilState(choosedLogoAtom);
  const [logoName, setLogoName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [clickedProfile, setClickedProfile] =
    useRecoilState(clickedProfileAtom);

  const handleClickChangeLogo = () => {
    setClickedChangeLogo((clickedChangeLogo) => !clickedChangeLogo);
  };

  useEffect(() => {
    const LogoChoices = [
      {
        id: 0,
        title: "logo",
        img: logo,
      },
      {
        id: 1,
        title: "FullLogo",
        img: FullLogo,
      },
      {
        id: 2,
        title: "LogoA",
        img: LogoA,
      },
      {
        id: 3,
        title: "LogoB",
        img: LogoB,
      },
      {
        id: 4,
        title: "LogoC",
        img: LogoC,
      },
      {
        id: 5,
        title: "LogoD",
        img: LogoD,
      },
      {
        id: 6,
        title: "LogoE",
        img: LogoE,
      },
      {
        id: 7,
        title: "LogoF",
        img: LogoF,
      },
      {
        id: 8,
        title: "LogoG",
        img: LogoG,
      },
      {
        id: 9,
        title: "LogoH",
        img: LogoH,
      },
      {
        id: 10,
        title: "LogoI",
        img: LogoI,
      },
      {
        id: 11,
        title: "LogoJ",
        img: LogoJ,
      },
      {
        id: 12,
        title: "LogoK",
        img: LogoK,
      },
      {
        id: 13,
        title: "LogoL",
        img: LogoL,
      },
      {
        id: 14,
        title: "LogoM",
        img: LogoM,
      },
      {
        id: 15,
        title: "LogoN",
        img: LogoN,
      },
      {
        id: 16,
        title: "LogoBis",
        img: LogoBis,
      },
      {
        id: 17,
        title: "LogoBuddha",
        img: LogoBuddha,
      },
      {
        id: 18,
        title: "LogoBuddha2",
        img: LogoBuddha2,
      },
      {
        id: 19,
        title: "LogoChild2",
        img: LogoChild2,
      },
      {
        id: 20,
        title: "LogoChild3",
        img: LogoChild3,
      },
      {
        id: 21,
        title: "LogoChild4",
        img: LogoChild4,
      },
      {
        id: 22,
        title: "LogoChild5",
        img: LogoChild5,
      },
      {
        id: 23,
        title: "LogoGourou",
        img: LogoGourou,
      },
      {
        id: 24,
        title: "LogoJapan",
        img: LogoJapan,
      },
      {
        id: 25,
        title: "LogoMan",
        img: LogoMan,
      },
      {
        id: 26,
        title: "LogoOhm",
        img: LogoOhm,
      },
      {
        id: 27,
        title: "LogoShadow",
        img: LogoShadow,
      },
      {
        id: 28,
        title: "LogoFull2",
        img: LogoFull2,
      },
      {
        id: 29,
        title: "LogoFull3",
        img: LogoFull3,
      },
      {
        id: 30,
        title: "LogoSunshine",
        img: LogoSunshine,
      },
    ];

    if (clickedChangeLogo) {
      setSelectedLogo(LogoChoices);
    }
  }, [clickedChangeLogo, setSelectedLogo]);

  useEffect(() => {
    if (choosedLogo) {
      setLogoName(choosedLogo[0]?.pict);
    }
    // console.log("clickedProfile", clickedProfile);
  }, [choosedLogo, logoName, clickedProfile]);

  return (
    <div
      style={{ position: "relative" }}
      className={`Logo-Container ${
        historymeditation.length > 0 && historymeditation.length <= 60
          ? "blue"
          : historymeditation.length > 60 && historymeditation.length <= 120
          ? "purple"
          : historymeditation.length > 120 && historymeditation.length <= 360
          ? "red"
          : historymeditation.length > 360
          ? "black"
          : "white"
      }`}
    >
      {choosedLogo && logoName !== undefined ? (
        <Fragment>
          <img src={logoName} className="App-logo" alt="logo" />
          <div onClick={handleClickChangeLogo} className="corner"></div>
          <img
            onClick={handleClickChangeLogo}
            className="avatar"
            src={LogoConfig}
            alt="avatar"
            style={{ width: 28 }}
          />
        </Fragment>
      ) : (
        <Fragment>
          <img src={logo} className="App-logo" alt="logo" />
          <div onClick={handleClickChangeLogo} className="corner"></div>
          <img
            onClick={handleClickChangeLogo}
            className="avatar"
            src={LogoConfig}
            alt="avatar"
            style={{ width: 28 }}
          />
        </Fragment>
      )}
    </div>
  );
};

export default LevelLogo;

import { selector } from "recoil";
import historymeditationAtom from "../historymeditationAtom";

const attendanceLogicSelector = selector({
  key: "attendanceLogicSelector",
  get: ({ get }) => {
    try {
      const historymeditation = get(historymeditationAtom);

      if (historymeditation !== []) {
        const last_session = historymeditation[historymeditation.length - 1];
        const date1 = last_session?.timestamp;
        let timeStamp = Math.round(new Date().getTime() / 1000);
        let timeStampYesterday = timeStamp - 48 * 3600;
        let is48h = date1 <= timeStampYesterday;
        return is48h;
      } else {
        return;
      }
    } catch (e) {
      console.error("ERROR", e);
    }
  },
});

export default attendanceLogicSelector;

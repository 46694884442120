/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRecoilState } from "recoil";
import clickedProfileAtom from "../../statesManager/clickedProfileAtom";
import "./profile-style.css";
import { Avatar as MuiAvatar, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import styled from "styled-components";
import useLocalStorage from "./storageHooks/useLocalStorage";
import PictureCompress from "./profilePictureResize/PictureCompress";
import BeginnerBadge from "../../images/beginner-home.svg";
import avatarPicture from "../../images/default-profile.svg";
import IntermediateBadge from "../../images/intermediate-home.svg";
import ConfirmedBadge from "../../images/confirmed-home.svg";
import ExpertBadge from "../../images/expert-home.svg";
import historymeditationAtom from "../../statesManager/historymeditationAtom";
import { useState } from "react";
import { useEffect } from "react";
import CustomBadgeWithoutProgress from "../../baseLayout/levelBadges/customBadges/CustomBadgeWithoutProgress";
import CustomBadgeWithProgress from "../../baseLayout/levelBadges/customBadges/CustomBadgeWithProgress";
import useConstants from "../../baseLayout/levelBadges/useConstants";
import AttendanceBadge from "../../baseLayout/attendanceBadge/AttendanceBadge";
import BestLearnerBadge from "../../baseLayout/bestLearnerBadge/BestLearnerBadge";
import CuriousBadgeComponent from "../../baseLayout/curiousBadge/CuriousBadgeComponent";
import attendanceLogicSelector from "../../statesManager/selectors/attendanceLogicSelector";
import clickedStartTimerAtom from "../../statesManager/clickedStartTimerAtom";
import howManyClickAtom from "../../statesManager/howManyClickAtom";
import SingleImg from "../../images/Meditating-at-sunset.jpg";
import ContourImg from "../../images/border-white.svg";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { createRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LogoAppProfile from "../../logo-app2.svg";
import ShareComponent from "./ShareComponent";
import startLoaderAtom from "../../statesManager/StartLoaderAtom";
import useCutDecimals from "../../hooks/useCutDecimals";
import InfoSign from "../../images/info-signs.svg";
import { useSafeState } from "../../hooks/useSafeState";

const BigAvatar = styled(MuiAvatar)`
  width: 135px;
  height: 135px;
  margin: 0 auto 16px;
  border: 1px solid ${grey[500]};
  box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
}));

const ProfileComponent = () => {
  const classes = useStyles();
  //  Use LocalStorage custom hook for getting the  cropped picture
  const [croppedImg] = useLocalStorage({}, "imgBase64Cropped");
  // Use LocalStorage for getting the style of picture
  const [ImageStyle] = useLocalStorage({}, "styleImg");
  // eslint-disable-next-line no-unused-vars
  const [clickedProfile, setClickedProfile] =
    useRecoilState(clickedProfileAtom);
  const [historymeditation] = useRecoilState(historymeditationAtom);
  const [numberSessions, setNumeberSession] = useState(0);
  const [timeToMeditate, setTimeToMeditate] = useState(undefined);
  const [lastDaySession, setLastDaySession] = useState(undefined);
  const historyMed = historymeditation.length >= 2;
  const [is48h] = useRecoilState(attendanceLogicSelector);
  const attendanceMessage = sessionStorage.getItem("attendance") !== null;
  const [clickedStartTimerButton] = useRecoilState(clickedStartTimerAtom);
  const [curiousBadgeAppear, setCuriousBadgeAppear] = useState(false);
  const [howManyClick] = useRecoilState(howManyClickAtom);
  const ref = createRef(null);
  const [clickedShare, setClickedShare] = useState(false);
  const [clickedInvite, setClickedInvite] = useState(false);
  const MySwal = withReactContent(Swal);
  const { cutDecimals } = useCutDecimals();
  const [numberSessByDate, setNumeberSessByDate] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [startLoader, setStartLoader] = useRecoilState(startLoaderAtom);
  const {
    BeginnerLevel,
    IntermedLevel,
    ConfirmedLevel,
    ExpertLevel,
    AbsoluteExpertLevel,
    progressBeginnerLevelValue,
    progressIntermedLevelValue,
    progressConfirmedLevelValue,
    progressExpertLevelValue,
  } = useConstants();

  let firstSession = historymeditation[0]?.date;
  const [firtDateState, setFirstDateState] = useSafeState("");
  const [numberDays, setNumberDays] = useSafeState(0);

  // const diffDays = (date, otherDate) =>
  //   Math.ceil(Math.abs(date - otherDate) / (60 * 60 * 24));
  // let oned = 24 * 60 * 60 * 1000;

  useEffect(() => {
    let NumberDone = historymeditation.length;

    if (historymeditation.length > 0) {
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      let LastSession = historymeditation[historymeditation.length - 1];
      setNumeberSession(NumberDone);
      setTimeToMeditate(
        historymeditation.map((res) => res.time).reduce(reducer)
      );

      setLastDaySession(LastSession?.date);
    }
  }, [historymeditation]);

  useEffect(() => {
    const FirstSessionTime = historymeditation[0]?.timestamp;
    const TodayTimestamp = Math.round(new Date().getTime() / 1000);
    let ToString = firstSession?.toString();
    let firstDateCutted = ToString?.slice(0, 10);

    async function loadContent() {
      if (historymeditation.length > 0) {
        let date1 = await FirstSessionTime;
        let date2 = TodayTimestamp;
        let Difference_In_Time = date2 - date1;
        var days = Math.ceil(Difference_In_Time / (3600 * 24));
        let numberSessionByTime = numberSessions / days;
        setNumberDays(days);
        setNumeberSessByDate(numberSessionByTime);
      }
    }

    if (firstDateCutted !== null) {
      setFirstDateState(firstDateCutted);
    }

    async function loadWithConditions() {
      // console.log("numberSessionByTime", numberSessByDate);
      await loadContent();
    } // Execute the created function directly
    loadWithConditions();

    return () => {
      return;
    };
  }, [
    historymeditation,
    firtDateState,
    numberSessions,
    firstSession,
    numberSessByDate,
    setFirstDateState,
    setNumberDays,
  ]);

  useEffect(() => {
    const allSessionsLenght = historymeditation.length;
    if (allSessionsLenght >= 2) {
      const SessionWithSoundLenght = historymeditation.filter(
        (res) => res.sound !== ""
      ).length;
      if (SessionWithSoundLenght >= allSessionsLenght / 2) {
        setCuriousBadgeAppear(true);
      } else {
        setCuriousBadgeAppear(false);
      }
    }
  }, [historymeditation]);

  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.5,
    width: 600,
    height: 1800,
  });

  const download = (image, { name = "My Profile", extension = "png" } = {}) => {
    setStartLoader(false);
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => {
    takeScreenShot(ref.current).then(download);
  };
  const handleClickedShare = () => {
    setClickedShare((clickedShare) => !clickedShare);
  };

  const handleClickInvite = () => {
    setClickedInvite((clickedInvite) => !clickedInvite);
  };

  if (clickedShare) {
    MySwal.fire({
      position: "top-end",
      icon: "info",
      title: "How does it work?",
      html: `
        <div>
        <strong>This is an automatic image creation & download tool...</strong>
          </div>
          <br />
          <span className="content"><p>By clicking on <strong>ok</strong> one <strong>image of your profile</strong> will be done on the fly, then <strong>saved on your phone</strong>.</p>
          <br />
          <p>Once this is done, you will have an image with the name "My Profile". <strong>in your download folder</strong>...</p>
          </span>
          </div>`,
      footer: "all you have to do is share it with the friends you want!",
      showCancelButton: true,
      confirmButtonColor: "#7367f0",
      cancelButtonColor: "#ee0979",
      confirmButtonText: "Ok, i do that",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setStartLoader(true);

        downloadScreenshot();

        setClickedShare(false);
      } else {
        setClickedShare(false);
      }
    });
  }

  const sessionTimeAverage = timeToMeditate / numberSessions;
  const [clickedMinStat, setClickedMinStat] = useState(false);
  const [clickedSessionDetail, setClickedSessionDetails] = useState(false);
  const [clickedFirstSession, setClickedFirstSession] = useState(false);

  const handleClickMinStat = () => {
    setClickedMinStat((clickedMinStat) => !clickedMinStat);
  };
  const handleClickSessionDetails = () => {
    setClickedSessionDetails((clickedSessionDetails) => !clickedSessionDetails);
  };
  const handleClickFirstSession = () => {
    setClickedFirstSession((clickedFirstSession) => !clickedFirstSession);
  };

  useEffect(() => {
    if (clickedMinStat) {
      setTimeout(() => {
        setClickedMinStat(false);
      }, 5000);
    }
    if (clickedSessionDetail) {
      setTimeout(() => {
        setClickedSessionDetails(false);
      }, 5000);
    }
    if (clickedFirstSession) {
      setTimeout(() => {
        setClickedFirstSession(false);
      }, 5000);
    }
    return () => clearTimeout(setTimeout);
  }, [clickedMinStat, clickedSessionDetail, clickedFirstSession]);

  return (
    <div ref={ref} className="profile-container slide-in-bottom">
      {clickedInvite ? (
        <ShareComponent setClickedInvite={setClickedInvite} />
      ) : null}
      <div className="header-profile">
        <div className="logoProfile">
          <img
            src={LogoAppProfile}
            alt="logo app"
            style={{ width: 40 }}
            className="logoProfileCopyright"
          />
          <sub>my-meditation-time.us</sub>
        </div>
        <button
          className="closeProfileButton"
          onClick={() => setClickedProfile(false)}
        >
          x
        </button>
        <h1>Profile</h1>
        <div className="bottom-headerProfile">
          <p onClick={handleClickedShare}>Share</p>
          <a href="#" style={{ fontWeight: "500" }}>
            <p onClick={handleClickInvite}>Invite</p>
          </a>
        </div>
      </div>
      <div className="profile-innerContent">
        <div className="EditProfileLover__imageContainer">
          {croppedImg.imageDestination ? (
            <span>
              <img src={ContourImg} alt="contour" className="contour" />
              <img
                id="profile-picture"
                src={croppedImg.imageDestination}
                style={ImageStyle}
                alt="profile"
              />
            </span>
          ) : (
            <BigAvatar
              className={classes.large}
              $withBorder
              alt="Avatar"
              src={avatarPicture}
            />
          )}
          <PictureCompress />
        </div>
      </div>
      <div className="modalProfileBody image-bg">
        <div className="profile-middleContent">
          <div
            onClick={handleClickSessionDetails}
            className="profile-middleHead"
          >
            {historymeditation.length > 0 && clickedSessionDetail ? (
              <span className="text-tooltip text-tooltip--active">
                <sub
                  style={{ fontSize: 14, color: "black", textAlign: "center" }}
                >
                  Average session :<br />
                  <strong>{cutDecimals(numberSessByDate, 2)}</strong> / day
                  <br />
                </sub>
                <span id="triangle-code"></span>
              </span>
            ) : null}
            <h2>Number of sessions</h2>
            <div className="session-profileHead">
              <h3>{numberSessions ? numberSessions : 0}</h3>
              <sub>sessions</sub>
              {historymeditation.length > 0 ? (
                <img
                  src={InfoSign}
                  alt="info"
                  className={
                    clickedSessionDetail
                      ? "infoSigns infosSignsSelect"
                      : "infoSigns"
                  }
                />
              ) : null}
            </div>
          </div>
          <div className="profile-nextmiddleHead">
            <h2>Total time</h2>
            <div onClick={handleClickMinStat} className="session-profileHead">
              {historymeditation.length > 0 && clickedMinStat ? (
                <span className="text-tooltip text-tooltip--active">
                  <sub style={{ fontSize: 14 }}>
                    Average time : <br />
                    <strong>{cutDecimals(sessionTimeAverage, 2)} </strong>
                    mins / session
                  </sub>
                  <span id="triangle-code"></span>
                </span>
              ) : null}

              <h3>{timeToMeditate ? timeToMeditate : 0}</h3>
              <sub>minutes</sub>
              {historymeditation.length > 0 ? (
                <img
                  src={InfoSign}
                  alt="info"
                  className={
                    clickedMinStat ? "infoSigns infosSignsSelect" : "infoSigns"
                  }
                />
              ) : null}
            </div>
          </div>
          <div
            onClick={handleClickFirstSession}
            className="profile-lastmiddleHead"
          >
            {historymeditation.length > 0 && clickedFirstSession ? (
              <span className="text-tooltip text-tooltip--active">
                <sub style={{ fontSize: 14, textAlign: "center" }}>
                  {" "}
                  Date 1st session: <strong>{firstSession}</strong>
                  <br />
                  Total number of days: <strong>{numberDays}</strong>
                </sub>
                <span id="triangle-code"></span>
              </span>
            ) : null}
            <h2>Last session</h2>
            <div className="session-profileHead">
              <sub>{lastDaySession ? lastDaySession : "Aucune"}</sub>
              {historymeditation.length > 0 ? (
                <img
                  src={InfoSign}
                  alt="info"
                  className={
                    clickedFirstSession
                      ? "infoSigns infosSignsSelect"
                      : "infoSigns"
                  }
                />
              ) : null}
            </div>
          </div>
          <div className="badgesContainer">
            <h2>Badge(s) in your possession</h2>
            <div className="badgesSestionProfile">
              <div className="levelsBadges-container">
                {BeginnerLevel && historymeditation.length < 2 ? (
                  <CustomBadgeWithoutProgress
                    classBadge="beginnerBadge"
                    badgeImg={BeginnerBadge}
                  />
                ) : null}
                {BeginnerLevel && historymeditation.length >= 2 ? (
                  <CustomBadgeWithProgress
                    classBadge="beginnerBadge"
                    badgeImg={BeginnerBadge}
                    progressValue={progressBeginnerLevelValue}
                  />
                ) : IntermedLevel && historymeditation.length < 62 ? (
                  <CustomBadgeWithoutProgress
                    classBadge="intermedBadge"
                    badgeImg={IntermediateBadge}
                  />
                ) : IntermedLevel && historymeditation.length >= 62 ? (
                  <CustomBadgeWithProgress
                    classBadge="intermedBadge"
                    badgeImg={IntermediateBadge}
                    progressValue={progressIntermedLevelValue}
                  />
                ) : ConfirmedLevel && historymeditation.length < 122 ? (
                  <CustomBadgeWithoutProgress
                    classBadge="confirmedBadge"
                    badgeImg={ConfirmedBadge}
                  />
                ) : ConfirmedLevel && historymeditation.length >= 122 ? (
                  <CustomBadgeWithProgress
                    classBadge="confirmedBadge"
                    badgeImg={ConfirmedBadge}
                    progressValue={progressConfirmedLevelValue}
                  />
                ) : ExpertLevel && historymeditation.length < 362 ? (
                  <CustomBadgeWithoutProgress
                    classBadge="expertBadge"
                    badgeImg={ExpertBadge}
                  />
                ) : ExpertLevel && historymeditation.length >= 362 ? (
                  <CustomBadgeWithProgress
                    classBadge="expertBadge"
                    badgeImg={ExpertBadge}
                    progressValue={progressExpertLevelValue}
                  />
                ) : AbsoluteExpertLevel ? (
                  <CustomBadgeWithoutProgress
                    classBadge="expertBadge"
                    badgeImg={ExpertBadge}
                    checkColor="color-green"
                  />
                ) : null}
              </div>
              {historyMed &&
              !is48h &&
              attendanceMessage &&
              !clickedStartTimerButton ? (
                <AttendanceBadge />
              ) : null}
              {howManyClick && howManyClick >= 6 && !clickedStartTimerButton ? (
                <BestLearnerBadge />
              ) : null}
              {curiousBadgeAppear && !clickedStartTimerButton ? (
                <CuriousBadgeComponent />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="backstretch"
          style={{
            left: "0px",
            top: "0px",
            overflow: "hidden",
            margin: "0px",
            padding: "0px",
            height: "100%",
            width: "100%",
            zIndex: -999998,
            position: "absolute",
            borderRadius: 14,
          }}
        >
          <img
            loading="lazy"
            src={SingleImg}
            alt="alt"
            style={{
              position: "absolute",
              margin: "0px",
              padding: "0px",
              border: "none",
              width: "100%",
              height: "85%",
              maxHeight: "none",
              maxWidth: "none",
              zIndex: -999999,
              left: 0,
              bottom: "0px",
              objectFit: "cover",
              borderRadius: 14,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;

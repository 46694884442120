import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CustomConfirm = () => {
  const MySwal = withReactContent(Swal);

  function JSalert() {
    MySwal.fire({
      icon: "error",
      title: "Le mode paysage n'est pas optimal!",
      text: "Cette application a été conçue pour être utilisée en mode portrait uniquement !",
      footer: "C'est bien mieux en bonne position.",
      showConfirmButton: true,
    });
  }
  return { JSalert };
};

export default CustomConfirm;

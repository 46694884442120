const HeaderPresets = ({
  timeDuration,
  OkIcon,
  DeleteIcon,
  setTimeDuration,
  StartIcon,
  titleSound,
  VolumeOffTwoTone,
  VolumeUpTwoTone,
  setTitleSound,
}) => {
  return (
    <div className="headerMultimedia">
      <div className="DurationHead">
        {timeDuration ? (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {timeDuration && (
              <img
                src={OkIcon}
                alt="okIcon"
                style={{ width: 22, marginLeft: 3 }}
              />
            )}
            <h3
              style={{
                marginRight: "0.9rem",
                fontSize: 16,
                color: "var(--colorText)",
              }}
            >
              Duration:{" "}
              <span style={{ color: "#8830fd" }}>{timeDuration} mins</span>{" "}
            </h3>
            {timeDuration && (
              <img
                src={DeleteIcon}
                alt="okIcon"
                style={{
                  width: 31,
                  height: 24,
                  cursor: "pointer",
                  transform: "translate(-12px, 0) scale(0.98)",
                }}
                onClick={() => setTimeDuration(0)}
              />
            )}
          </span>
        ) : (
          <img src={StartIcon} alt="startIcon" style={{ width: 32 }} />
        )}
      </div>
      <div className="SoundHeader">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!titleSound ? (
            <VolumeOffTwoTone style={{ color: "darkred" }} />
          ) : (
            <VolumeUpTwoTone style={{ color: "#4fba6f" }} />
          )}
          <h3 style={{ color: "#8830fd", fontSize: 19 }}>{titleSound}</h3>
          {titleSound && (
            <img
              src={DeleteIcon}
              alt="okIcon"
              style={{
                width: 31,
                height: 24,
                cursor: "pointer",
                marginLeft: 15,
              }}
              onClick={() => setTitleSound("")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderPresets;

const CustomBadgeWithoutProgress = ({ classBadge, badgeImg, checkColor }) => {
  return (
    <div className={`${classBadge}`}>
      <img
        src={badgeImg}
        alt="badge"
        style={{
          width: 35,
          height: 35,
          left: 10,
          marginTop: 4,
        }}
      />
      <div className={`check ${checkColor}`}></div>
    </div>
  );
};

export default CustomBadgeWithoutProgress;

const useScrollWithButton = () => {
  function slide(containerById, direction) {
    var container = document.getElementById(containerById);
    let scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction === "top") {
        container.scrollTop -= 5;
      } else if (direction === "bottom") {
        container.scrollTop += 5;
      }
      if (direction === "left") {
        container.scrollLeft -= 10;
      } else if (direction === "right") {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 100) {
        window.clearInterval(slideVar);
      }
    }, 50);
  }
  return {
    slide,
  };
};

export default useScrollWithButton;

import { Card, CardContent } from "@material-ui/core";
import logo from "../../logo-app2.svg";
import BaseLayout from "../../baseLayout/BaseLayout";
import mind from "../../images/icon-mind.svg";
import StartButton from "../startButton/StartButton";
import "./why.css";

import WhyModalComponent from "./whyModal/WhyModalComponent";
import { useEffect, useState } from "react";
import useTransitionControl from "../../hooks/useTransitionControl";
import navigationNameAtom from "../../statesManager/navigationNameAtom";
import { useRecoilState } from "recoil";
import {
  defaultStyle,
  transitionStyles,
} from "../../common-styles/transitionStyleConstants";

const Why = () => {
  const [idClicked, setIdClicked] = useState("");
  const [state, enter, exit] = useTransitionControl();
  const [navigationName] = useRecoilState(navigationNameAtom);

  const dataBenefits = [
    {
      id: 0,
      title: "1. Better focus",
      content:
        "Meditation is a practice of focusing our attention and being aware of when it is drifting. This helps to improve our concentration outside of our meditation session.",
    },
    {
      id: 1,
      title: "2. More creativity",
      content:
        "One study showed that people who practiced mindfulness meditation performed better on a task requiring them to come up with new ideas.",
    },
    {
      id: 2,
      title: "3. Increased immunity",
      content:
        "Relaxation and mindfulness meditation enhance immunity, resistance to tumours and viruses and reduce the risk of breast cancer recurrence.",
    },
    {
      id: 3,
      title: "4. better memory",
      content:
        "People who practice mindfulness meditation are able to focus, block out distractions, improve their quick memory and increase their productivity.",
    },
    {
      id: 4,
      title: "5.Less stressful",
      content:
        "Mindfulness meditation has been shown to help people perform better under pressure, cope with situations and feel better.",
    },
    {
      id: 5,
      title: "6. Less anxiety",
      content:
        "Meditation helps us to look at frightening or disturbing thoughts, emotions and sensations rationally, with calm, detachment and greater control.",
    },
    {
      id: 6,
      title: "7. More grey matter",
      content:
        "More grey matter leads to emotional stability and increased concentration in daily life. Meditation also helps to reduce cognitive decline.",
    },
    {
      id: 7,
      title: "8. Emotional balance",
      content:
        "Meditation helps to deal with unhealthy emotional states, traumas, neuroses and neurotic behaviours that result from a tortured and traumatised ego.",
    },
    {
      id: 8,
      title: "9. Increased fertility",
      content:
        "Women are more likely to conceive when they are relaxed. Relaxation reduces stress, which has an impact on sperm count and motility, and stimulates male fertility.",
    },
    {
      id: 9,
      title: "10. Relieves irritable bowel syndrome",
      content:
        "When patients with irritable bowel syndrome began practising meditation twice a day, symptoms of bloating, diarrhoea and constipation improved.",
    },
    {
      id: 10,
      title: "11. Lowers blood pressure",
      content:
        "A study conducted at Harvard Medical School found that meditation lowers blood pressure by making the body less reactive to stress hormones.",
    },
    {
      id: 11,
      title: "12. anti-inflammatory",
      content:
        "Stress causes inflammation, a condition linked to heart disease, arthritis, asthma and psoriasis. Relaxation can help by turning off the stress response.",
    },
    {
      id: 12,
      title: "13. Calme",
      content:
        "In the meditative mind, one attends to the thought, whereas in an ordinary mind, the thought is the boss. In the meditative mind, the counteracting thought is just another thought.",
    },
  ];

  const style = {
    ...defaultStyle,
    ...(transitionStyles[state] ?? {}),
  };

  useEffect(() => {
    if (navigationName === "why" && state !== "entered") {
      enter();
    }

    if (navigationName !== "why" && state === "exiting") {
      exit();
    }
  }, [state, navigationName, enter, exit]);

  return (
    <BaseLayout>
      <div style={style} className={`why-container`}>
        <br />
        <img src={mind} alt="" style={{ width: 102 }} />
        <h1>The 13 greatest benefits of meditation</h1>
        <br />
        <blockquote style={{ wordBreak: "break-word", padding: 15 }}>
          Although the research on meditation is not as strong as that on
          nutrition or exercise, there is a reason why it has been around for
          thousands of years. We are just beginning to understand how beneficial
          it can be for many aspects of life, from disease and pain management
          to sleep and emotional control. Here are the top 13 benefits of
          meditation.
        </blockquote>
        <br />
        <div className="why-content">
          {dataBenefits.map((res) => (
            <Card
              key={res.id}
              onClick={() => {
                setIdClicked(res.id);
              }}
              className="card-container"
              style={{ width: "94%", marginBottom: 10 }}
            >
              <div className="card-left">
                <h2>{res.number}</h2>{" "}
                <h2 className="card-whyTitle">{res.title}</h2>
                <img src={logo} alt="logo" style={{ width: 48 }} />
              </div>
              <div className="card-right">
                <CardContent className="why-content-text" key={res.id}>
                  {res.content}
                  <WhyModalComponent
                    setIdClicked={setIdClicked}
                    idClicked={idClicked}
                  />
                </CardContent>
              </div>
            </Card>
          ))}
        </div>
        <StartButton />
      </div>
    </BaseLayout>
  );
};

export default Why;

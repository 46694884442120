const useCreateRange = () => {
  let range = [];
  function createRange(min, max) {
    for (let i = min; i <= max; i++) {
      range.push(i);
    }

    return range;
  }
  return { createRange, range };
};

export default useCreateRange;

import "./startingApp-style.css";
import "./gaugeLoad-style.css";
import LogoApp from "../../logo-app2.svg";
import SingleImg from "../../images/Meditating-at-sunset.jpg";

const StartingAppLoader = () => {
  return (
    <div className="startingApp-container">
      <h1 className="loadingText" data-text="Start-up in progress...">
        Start-up in progress...
      </h1>
      <div className="loader-startApp slide-in-blurred-right">
        <div className="dot-startApp"></div>
        <div className="dot-startApp"></div>
        <div className="dot-startApp"></div>
      </div>
      <div className="footer-loadingApp bounce-in-bottom">
        <ul id="skill">
          <li>
            <span className="bar-loaderApp graphic-design"></span>
          </li>
        </ul>
      </div>
      <div className="center-loadingApp">
        <img
          className="roll-in-left"
          src={LogoApp}
          alt="logo"
          style={{ width: 91, height: 91, marginBottom: "1rem" }}
        />
        <p className="tracking-in-expand-fwd-bottom"> My Meditation Time</p>
      </div>

      <div
        className="backstretch"
        style={{
          left: "0px",
          top: "0px",
          overflow: "hidden",
          margin: "0px",
          padding: "0px",
          height: "100%",
          width: "100%",
          zIndex: -999998,
          position: "absolute",
          borderRadius: 14,
        }}
      >
        <img
          loading="lazy"
          src={SingleImg}
          alt="alt"
          style={{
            position: "absolute",
            margin: "0px",
            padding: "0px",
            border: "none",
            width: "100%",
            height: "85%",
            maxHeight: "none",
            maxWidth: "none",
            zIndex: -999999,
            left: 0,
            bottom: "0px",
            objectFit: "cover",
            borderRadius: 14,
          }}
        />
      </div>
    </div>
  );
};

export default StartingAppLoader;

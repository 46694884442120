import { useEffect, useState } from "react";
import BestLearnIcon from "../../images/bestLearner.svg";
import useSweetAlertComponent from "../sweetAlertComponent/useSweetAlertComponent";

const BestLearnerBadge = () => {
  const { bestLearnBadgeAlerts } = useSweetAlertComponent();
  const [clickedBestLearnBadge, setClickedBestLearnBadge] = useState(false);

  const handleClickBadge = () => {
    setClickedBestLearnBadge((clickedBestLearnBadge) => !clickedBestLearnBadge);
  };

  useEffect(() => {
    if (clickedBestLearnBadge) {
      bestLearnBadgeAlerts().then((clickedBestLearnBadge) => {
        if (clickedBestLearnBadge) {
          setClickedBestLearnBadge(false);
        }
      });
    }
  }, [clickedBestLearnBadge, bestLearnBadgeAlerts]);
  return (
    <div onClick={handleClickBadge} className="bestLearn-container">
      <img src={BestLearnIcon} alt="best" style={{ width: 52 }} />
    </div>
  );
};

export default BestLearnerBadge;

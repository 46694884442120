import { useRecoilState } from "recoil";
import topButton from "../../images/top-button.png";
import titleSoundAtom from "../../statesManager/titleSoundAtom";
import "./scrollTop.css";

const ScrollTop = () => {
  const [titleSound] = useRecoilState(titleSoundAtom);

  window.addEventListener("scroll", (e) => {
    var el = document.getElementById("jsScroll");
    if (window.scrollY > 400) {
      el.classList.add("visible");
    }
    if (window.scrollY > 400 && titleSound !== "") {
      el.classList.remove("visible");
    } else if (window.scrollY < 400) {
      el.classList.remove("visible");
    }
  });

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <div>
      <div id="jsScroll" className="scroll" onClick={scrollToTop}>
        <img className="fa fa-angle-up" src={topButton} alt="" />
      </div>
    </div>
  );
};

export default ScrollTop;

import { Card, CardContent } from "@material-ui/core";
import BaseLayout from "../../baseLayout/BaseLayout";
import water from "../../images/icon-water-drop.svg";
import StartButton from "../startButton/StartButton";
import logo from "../../logo-app2.svg";
import "./how.css";
import { useEffect, useState } from "react";
import DetailedConseilComponent from "./detailedConseils/DetailedConseilComponent";
import useTransitionControl from "../../hooks/useTransitionControl";
import { useRecoilState } from "recoil";
import navigationNameAtom from "../../statesManager/navigationNameAtom";
import howManyClickAtom from "../../statesManager/howManyClickAtom";
import {
  defaultStyle,
  transitionStyles,
} from "../../common-styles/transitionStyleConstants";

const How = () => {
  const [openConseils, setOpenConseils] = useState(false);
  const [idClicked, setIdClicked] = useState("");
  const [state, enter, exit] = useTransitionControl();
  const [navigationName] = useRecoilState(navigationNameAtom);
  const [howManyClick, setHowManyClick] = useRecoilState(howManyClickAtom);

  const dataBenefits = [
    {
      id: 0,
      title: "1. Select a location",
      content:
        "Choose a comfortable place where you will not be interrupted or distracted.",
    },
    {
      id: 1,
      title: "2. Getting comfortable",
      content:
        "Wear comfortable, loose-fitting clothes. You can also use a blanket, pillow or cushion if you wish.",
    },
    {
      id: 2,
      title: "3. Reserve time",
      content:
        "Be gentle with yourself. You can start with a 5-10 minute meditation and work your way up.",
    },
    {
      id: 3,
      title: "4. Finding a comfortable posture",
      content:
        "You can sit on the floor or on a chair, stand, walk or lie down. Play with the different positions.",
    },
    {
      id: 4,
      title: "5. Put your mind at rest",
      content:
        "Take a moment to identify the thoughts and feelings you are experiencing. Then focus on your physical position.",
    },
    {
      id: 5,
      title: "6. Take deep breaths",
      content:
        "Focus on your breathing. Notice each inhalation and exhalation. Taking deep breaths helps to relax your body and mind.",
    },
    {
      id: 6,
      title: "7. Focus on the present",
      content:
        "When your mind wanders, come back to your body, your breath and the present moment.",
    },
    {
      id: 7,
      title: "8. Let go of your thoughts and emotions",
      content:
        "When you notice thoughts or emotions arising, choose not to focus on them. Let them come, let them go, without judgement.",
    },
  ];

  const style = {
    ...defaultStyle,
    ...(transitionStyles[state] ?? {}),
  };

  useEffect(() => {
    if (navigationName === "how" && state !== "entered") {
      enter();
    }

    if (navigationName !== "how" && state === "exiting") {
      exit();
    }
  }, [howManyClick, state, navigationName, enter, exit]);

  const handleOpenClick = () => {
    setOpenConseils((openConseils) => !openConseils);
  };

  const handleClickHowMany = () => {
    setHowManyClick((howManyClick) => howManyClick + 1);
    localStorage.setItem("howManyClick", howManyClick);
  };

  return (
    <BaseLayout>
      {openConseils ? (
        <div
          className={
            openConseils
              ? "detailContent slide-in-bottom"
              : "detailContent slide-out-bottom"
          }
        >
          <DetailedConseilComponent
            idClicked={idClicked}
            setOpenConseils={setOpenConseils}
            handleClickHowMany={handleClickHowMany}
          />
        </div>
      ) : null}
      <div style={style} className={`how-container`}>
        <br />
        <img src={water} alt="" style={{ width: 80 }} />
        <h1>How to do meditation</h1>
        <br />
        <blockquote style={{ wordBreak: "break-word", padding: 15 }}>
          Meditation helps to improve concentration, stimulate creativity and
          reduce stress. These simple steps will help you get started.
        </blockquote>
        <br />
        {openConseils ? (
          <span
            className="modal__overlay"
            tabIndex="-1"
            data-micromodal-close
          ></span>
        ) : null}
        <div className="how-content">
          {dataBenefits.map((res) => (
            <Card
              onClick={() => {
                setIdClicked(res.id);
                handleOpenClick();
              }}
              className={
                openConseils && res.id === idClicked
                  ? `card-container ${res.id} scale-out-center`
                  : `card-container ${res.id}`
              }
              key={res.id}
              style={{
                width: "94%",
                marginBottom: 10,
                background: "var(--cardColor)",
              }}
            >
              <div className="card-left">
                <h2>{res.number}</h2>{" "}
                <h2 className="card-whyTitle">{res.title}</h2>
                <img src={logo} alt="logo" style={{ width: 40 }} />
              </div>
              <div className="card-right">
                <CardContent className="how-content-text" key={res.id}>
                  {res.content}
                  <br />
                  <div className="subConseils notSmooth">
                    <sub className="conseils">See the tips!</sub>
                  </div>
                </CardContent>
              </div>
            </Card>
          ))}
        </div>
        <StartButton />
      </div>
    </BaseLayout>
  );
};

export default How;

const detailedConseils = [
  {
    id: 0,
    number: 1,
    title: "Choose the right place and time",
    content: [
      {
        id: 0,
        parag1:
          "Especially when you are a beginner, it is important to choose the right place for meditation. You need a place that is comfortable and free of distractions.",
      },
      {
        id: 1,
        parag2:
          "For example, if you want to start practising meditation indoors, choose a cool, well ventilated room. Turn down the lights, turn off the television and radio, close the windows to block out street noise and close the door for privacy.",
      },
      {
        id: 2,
        parag3:
          "You can put a scented candle, a bouquet of flowers or incense in the room to enhance your soothing experience.",
      },
      {
        id: 3,
        parag4:
          "Like the location, you should choose a time when you are comfortable. Initially, meditate when you are already feeling very relaxed, such as at dawn in the morning or in the late evening.",
      },
      {
        id: 4,
        parag5:
          "Once you have learned the art of meditation, you can do it anywhere: at home, at your desk, in any open space and even in a crowded public place.",
      },
    ],
  },
  {
    id: 1,
    number: 2,
    title: " Wear comfortable clothes",
    content: [
      {
        id: 0,
        parag1:
          "We wear different clothes for different activities and meditation is no exception.",
      },
      {
        id: 1,
        parag2:
          "In fact, wearing the right clothes for meditation is just as important as choosing the right atmosphere and timing.",
      },
      {
        id: 2,
        parag3:
          "If you wear tight clothes like jeans, you may be physically uncomfortable. This will make it difficult to stay calm and meditate.",
      },
      {
        id: 3,
        parag4:
          "Anything tight will prevent the body from reaching its relaxed state. In addition, tight clothing will restrict your own movement and cause poor circulation.",
      },
      {
        id: 4,
        parag5:
          "Choose clothes made of breathable fabrics, such as cotton or silk. Clothing should fit loosely or feel stretchy. Loose, comfortable clothing will ensure good circulation and improve your concentration level.",
      },
      {
        id: 5,
        parag6:
          "When meditating, wear loose, comfortable shoes or remove your shoes.",
      },
    ],
  },
  {
    id: 2,
    number: 3,
    title: "do some stretching",
    content: [
      {
        id: 0,
        parag1:
          "Meditation requires sitting in one place for a period of time. In fact, those new to meditation often find it very difficult or uncomfortable to sit still for several minutes.",
      },
      {
        id: 1,
        parag2:
          "For this reason, it is recommended that you do some gentle stretching before starting your meditation session to minimise any tension or tightness.",
      },
      {
        id: 2,
        parag3:
          "Stretching helps to release muscle tension, improve circulation and reduce restlessness so that your body feels lighter. Stretching also relaxes your body, allowing you to sit longer.",
      },
      {
        id: 3,
        parag4:
          "Do some gentle stretching for 10 minutes before your meditation session to prepare your body and mind. Stretching your hamstrings, back and shoulders can help you sit more comfortably.",
      },
    ],
  },
  {
    id: 3,
    number: 4,
    title: "Maintaining the right posture",
    content: [
      {
        id: 0,
        parag1:
          "Posture is important for meditation, as proper posture helps you to sit comfortably. The correct posture will also help you to stay focused and alert and attentive.",
      },
      {
        id: 1,
        parag2:
          "On the other hand, poor posture can make you drowsy and even cause obstructed breathing.",
      },
      {
        id: 2,
        parag3:
          "When you meditate, sit cross-legged and keep your spine straight and erect, your body equally relaxed. This upright posture helps you to focus on your breathing as you inhale and exhale in a slow, deep manner.",
      },
      {
        id: 3,
        parag4:
          "Your head should be straight ahead, face, jaw and eyes relaxed. Place your hands on a cushion or on your lap so that your arms are relaxed.",
      },
      {
        id: 4,
        parag5: "It is not at all advisable to meditate while lying down. ",
      },
    ],
  },
  {
    id: 4,
    number: 5,
    title: "Remove potential distractions",
    content: [
      {
        id: 0,
        parag1:
          "As a beginner, you may notice many distractions during meditation.",
      },
      {
        id: 1,
        parag2:
          "The sound of water running from the tap, the whistling of the air conditioner or fan, a dog barking, or the sound of a moving vehicle outside are just a few of the many things that can distract you.",
      },
      {
        id: 2,
        parag3:
          "Although it is not possible to get rid of all types of distractions, you can take control of many things. Try to remove these distractions before you sit down to meditate.",
      },
      {
        id: 3,
        parag4:
          "For example, you can have a light snack if you are hungry, drink water if you are thirsty, use the toilet if you need to, and calm your pet so that all the things you are most concerned about are done.",
      },
      {
        id: 4,
        parag5:
          "When it comes to meditation, make sure you set aside time for it when you know you will not be disturbed. You will not be able to concentrate if your mind is constantly thinking about something else.",
      },
    ],
  },
  {
    id: 5,
    number: 6,
    title: "Follow this deep breathing exercise",
    content: [
      {
        id: 0,
        parag1:
          "When you meditate, give yourself something positive to focus on, like your breath.",
      },
      {
        id: 1,
        parag2:
          "As soon as you start to concentrate on your inhalations and exhalations, thoughts of the outside world disappear of their own accord. This is one of the easiest ways to avoid worrying about things that can cause stress and distract your mind.",
      },
      {
        id: 2,
        parag3:
          "Deep breathing slows your heart rate, helps you relax and prevents random thoughts from entering your current mental state.",
      },
      {
        id: 3,
        parag4: "Sit upright in a comfortable position.",
      },
      {
        id: 4,
        parag5:
          "Start counting your breaths as you inhale and exhale slowly and deeply. Make sure your belly expands when you breathe in and contracts when you breathe out.",
      },
      {
        id: 5,
        parag6:
          "Keep your breathing as natural as possible and soon the sound of your breathing will transport your mind to a totally different world.",
      },
    ],
  },
  {
    id: 6,
    number: 7,
    title: "Anchor yourself in the present.",
    content: [
      {
        id: 0,
        parag1:
          "Do exercises to be fully aware of this moment at any time. Simply focus directly on an element in the environment around you or on a specific sensation in your body.",
      },
      {
        id: 1,
        parag2:
          "- For example, you can focus on the blue colour of a pen or folder on a table, or become more aware of the feel of your feet on the floor or your hands on the armrests of a chair. Use this practice when you feel you are getting distracted, your mind is wandering or you are feeling stress.",
      },
      {
        id: 2,
        parag3:
          "- You can also try to be aware of several sensations at the same time. For example, pick up a key ring and concentrate on the sound of the keys clinking together, the feeling of them in your hand and even their metallic smell.",
      },
      {
        id: 3,
        parag4:
          "Your object of concentration should help you stay in the present moment and relax your mind and body. In fact, in meditation, focusing means giving a little attention to the object you want to use as your centre of consciousness.",
      },
      {
        id: 4,
        parag5:
          "Don't be too hard on yourself if you can't concentrate. Mindfulness takes time to have an impact.",
      },
    ],
  },
  {
    id: 7,
    number: 8,
    title: "Use mental images.",
    content: [
      {
        id: 0,
        parag1:
          "Visualise things that will guide your breathing. Imagine a coin on the spot above your belly button that rises and falls with each inhalation and exhalation. You can also imagine a buoy in the sea that rises and falls on the waves as you breathe, or a lotus flower on your belly that opens with each breath.",
      },
      {
        id: 1,
        parag2:
          "- If your mind starts to wander, that's okay. You are just starting out and meditation takes practice. Just try to refocus your attention on your breathing and try not to think about anything else. ",
      },
    ],
  },
];

export default detailedConseils;

import { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PictureCropper from "./pictureCropper/PictureCropper";

import { useHistory } from "react-router-dom";

function ModalCropper({ onStudio, setOnStudio }) {
  const [open, setOpen] = useState(true);
  let history = useHistory();

  return (
    <div>
      <div>
        <Modal
          classNames="cropper-modal"
          open={open}
          onClose={() => {
            setOpen(false);
            history.push("/filters-tool");
          }}
          center
        >
          <PictureCropper />
        </Modal>
      </div>
    </div>
  );
}

export default ModalCropper;

import { useEffect, useCallback, useState, useRef } from "react";

export function useSafeState(initialValue = null) {
  const isMounted = useRef(true);
  const [state, setState] = useState(initialValue);
  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  const setStateSafe = useCallback((value) => {
    if (isMounted) {
      setState(value);
    }
  }, []);
  return [state, setStateSafe];
}

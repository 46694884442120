const stressTheme = [
  {
    id: 0,
    embedId: "DWOHcGF1Tmc",
  },
  {
    id: 1,
    embedId: "9hbSsuss3YA",
  },
  {
    id: 2,
    embedId: "gkqZrN_9DRI",
  },
  {
    id: 3,
    embedId: "ap6S-Yc4ans",
  },
];
const ApprentissageTheme = [
  { id: 0, embedId: "Bk_qU7l-fcU" },
  { id: 1, embedId: "FKU6QwT8psY" },
  {
    id: 2,
    embedId: "IN5z4gNOVYg",
  },
  {
    id: 3,
    embedId: "wf5K3pP2IUQ",
  },
  {
    id: 4,
    embedId: "JL_22TU80Hs",
  },
  {
    id: 5,
    embedId: "W2_tq5O1fOc",
  },
  {
    id: 6,
    embedId: "56_8aK3cLEA",
  },
  {
    id: 7,
    embedId: "mV9BBR1RoGg",
  },
  {
    id: 8,
    embedId: "xtMHCVBgV00",
  },
  {
    id: 9,
    embedId: "N4sKY8smCho",
  },
  {
    id: 10,
    embedId: "9CdPQ7X1MzU",
  },
  {
    id: 11,
    embedId: "QKDLEdpRIRE",
  },
];

const WhitMusicTheme = [
  {
    id: 0,
    embedId: "-_dTtHriNlk",
  },
];
const BienveillanceTheme = [
  {
    id: 0,
    embedId: "ELOads7rbxE",
  },
];
const ConfianceTheme = [
  {
    id: 0,
    embedId: "hgPnt-M6Suc",
  },
];
const colereTheme = [
  {
    id: 0,
    embedId: "FKU6QwT8psY",
  },
  {
    id: 1,
    embedId: "jKSkAtFUjo0",
  },
  {
    id: 2,
    embedId: "CM0OoOIESfc",
  },
];
const tristeTheme = [
  {
    id: 0,
    embedId: "xekJojGnScY",
  },
  {
    id: 1,
    embedId: "nEv4wGRQgIs",
  },
];
const sommeilTheme = [
  {
    id: 0,
    embedId: "tNk7N6Awm_g",
  },
  {
    id: 1,
    embedId: "YkaKUNfwaeQ",
  },
  {
    id: 2,
    embedId: "dzr8vPR-M1c",
  },
  {
    id: 3,
    embedId: "sim2jV6oGrg",
  },
  {
    id: 4,
    embedId: "sTTaDWczDQw",
  },
  {
    id: 5,
    embedId: "ih-aL0k91r8",
  },
  {
    id: 6,
    embedId: "bEgE_p8qQmA",
  },
  {
    id: 7,
    embedId: "YfYAv2b9orM",
  },
];

export {
  stressTheme,
  ApprentissageTheme,
  WhitMusicTheme,
  BienveillanceTheme,
  ConfianceTheme,
  colereTheme,
  tristeTheme,
  sommeilTheme,
};

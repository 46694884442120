import { useState } from "react";

const LevelText = ({ historymeditation }) => {
  const [clickedLevelInfo, setClickedLevelInfo] = useState(false);

  const handleClickLevelInfo = () => {
    setClickedLevelInfo((clickedLevelInfo) => !clickedLevelInfo);
    setTimeout(() => {
      setClickedLevelInfo(false);
    }, 3000);
  };
  return (
    <div>
      {historymeditation.length !== 0 ? (
        <span className="flex" onClick={handleClickLevelInfo}>
          <h3>Level :</h3>
          {clickedLevelInfo && historymeditation.length < 720 ? (
            <span className="text-tooltip-level fade-in text-tooltip--active">
              <sub style={{ fontSize: 14 }}>
                {`You are left with
              ${
                historymeditation.length > 0 && historymeditation.length <= 60
                  ? 60 - historymeditation.length
                  : historymeditation.length > 60 &&
                    historymeditation.length <= 120
                  ? 120 - historymeditation.length
                  : historymeditation.length > 120 &&
                    historymeditation.length <= 360
                  ? 360 - historymeditation.length
                  : historymeditation.length > 360
                  ? 720 - historymeditation.length
                  : null
              }
              session(s) before moving on to the next level!`}
              </sub>
              <span id="triangle-code-level"></span>
            </span>
          ) : null}
          {historymeditation.length > 0 && historymeditation.length <= 60 ? (
            <p className="niveau text-blue">Beginner</p>
          ) : historymeditation.length > 60 &&
            historymeditation.length <= 120 ? (
            <p className="niveau text-purple">Intermediate</p>
          ) : historymeditation.length > 120 &&
            historymeditation.length <= 360 ? (
            <p className="niveau text-red">Confirmed</p>
          ) : historymeditation.length > 360 ? (
            <p className="niveau text-black">Expert</p>
          ) : historymeditation.length > 720 ? (
            <p className="niveau text-black">Absolut Expert</p>
          ) : (
            ""
          )}
        </span>
      ) : null}
    </div>
  );
};

export default LevelText;

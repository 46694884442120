import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import navigationNameAtom from "../../statesManager/navigationNameAtom";

const StartButton = () => {
  let history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [navigationName, setNavigationName] =
    useRecoilState(navigationNameAtom);
  return (
    <p className="text-center">
      <button
        className="btn btn--primary text-center mt-4"
        title="Start your first session"
        onClick={() => {
          setNavigationName("session");
          history.push("/session");
        }}
      >
        Start your session
      </button>
    </p>
  );
};

export default StartButton;

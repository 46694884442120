import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import clickedChangeLogoAtom from "../../../statesManager/clickedChangeLogoAtom";
import selectedLogoAtom from "../../../statesManager/selectedLogoAtom";
import okIcon from "../../../images/ok.svg";
import suppIcon from "../../../images/supp.svg";
import useScrollWithButton from "../../../hooks/useScrollWithButton";
import placeholderIcon from "../../../images/picture-image.svg";
import useSweetAlertComponent from "../../../baseLayout/sweetAlertComponent/useSweetAlertComponent";
import startLoaderAtom from "../../../statesManager/StartLoaderAtom";

const LogoChoiceComponent = () => {
  const [selectedLogo] = useRecoilState(selectedLogoAtom);
  const [LogoView, setLogoView] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [clickedChangeLogo, setClickedChangeLogo] = useRecoilState(
    clickedChangeLogoAtom
  );
  const [clickedValidate, setClickedValidate] = useState(false);
  const { validateChangeLogo } = useSweetAlertComponent();
  // eslint-disable-next-line no-unused-vars
  const [startLoader, setStartLoader] = useRecoilState(startLoaderAtom);

  const handleSendLogoToLocalStore = () => {
    setClickedValidate(true);

    localStorage.setItem("logoChoice", JSON.stringify(LogoView));
  };

  const { slide } = useScrollWithButton();

  useEffect(() => {
    if (clickedValidate) {
      setStartLoader(true);
      setClickedChangeLogo(false);
      validateChangeLogo().then((clickedChangeLogo) => {
        if (clickedChangeLogo) {
          setClickedValidate(false);

          setTimeout(() => {
            setStartLoader(false);
            window.location.reload();
          }, 2000);
        }
      });
    }
  }, [
    selectedLogo,
    clickedValidate,
    validateChangeLogo,
    setClickedChangeLogo,
    setStartLoader,
  ]);

  return (
    <div className="logoChoice-container ">
      <div className="header-logoChoice">
        <div className="logoChoice-text">Choose your logo</div>
        <div
          onClick={() => setClickedChangeLogo(false)}
          className="logoChoice-close"
        >
          X
        </div>
      </div>
      <div className="logoChoice-middle">
        <strong className="textLogoChoice">
          {" "}
          {LogoView && LogoView.length !== 0
            ? "Validate or delete by clicking on the buttons below"
            : "You can choose between more than ten logos!"}
        </strong>
        <div className="logoPreview">
          {LogoView.length !== 0 ? (
            <div className="logoChoice-pictureContainer">
              <img
                src={LogoView[0]?.pict}
                alt="preview"
                className="logoPreview-picture"
              />
            </div>
          ) : null}
        </div>
        {LogoView.length !== 0 ? (
          <div className="buttonContainer-logoChoices">
            <button
              onClick={handleSendLogoToLocalStore}
              className="logoChoice-button"
            >
              <img src={okIcon} alt="ok" style={{ width: 40 }} />
            </button>
            <button className="logoChoice-button">
              <img
                onClick={() => setLogoView([])}
                src={suppIcon}
                alt="ok"
                style={{ width: 40 }}
              />
            </button>
          </div>
        ) : (
          <div
            style={{
              width: 250,
              height: 250,
              border: "2px dashed var(--dashedBorderColor)",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <img
              src={placeholderIcon}
              alt="placeholder"
              className="placeholderLogo"
            />
            <strong
              style={{
                width: "65%",
                textAlign: "center",
                color: "var(--purpleText)",
              }}
            >
              Choose an image from the list below!
            </strong>
          </div>
        )}
      </div>
      <div className="logoChoice-lowerMiddle">
        <div className="logoChoice-containerList">
          <div className="logoChoice-arrowLeft">
            <svg
              onClick={() => slide("logoChoice-list", "left")}
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
            >
              <g>
                <g>
                  <path
                    d="M226.808,256L440.082,16.99c2.677-3,3.341-7.291,1.698-10.96C440.138,2.361,436.492,0,432.473,0h-133.61
			c-2.906,0-5.675,1.24-7.61,3.409L71.918,249.209c-3.453,3.87-3.453,9.712,0,13.581l219.335,245.801
			c1.935,2.168,4.703,3.409,7.61,3.409h133.61c4.021,0,7.666-2.361,9.308-6.03c1.643-3.669,0.979-7.96-1.698-10.96L226.808,256z
			 M303.432,491.602L93.198,256L303.432,20.398h106.271L205.529,249.209c-3.453,3.87-3.453,9.712,0,13.581l204.174,228.811H303.432z
			"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M287.643,423.616l-6.37-7.139c-3.75-4.201-10.196-4.569-14.401-0.82c-4.202,3.751-4.57,10.198-0.82,14.401l6.37,7.139
			c2.016,2.258,4.809,3.409,7.615,3.409c2.416,0,4.843-0.854,6.787-2.589C291.025,434.266,291.393,427.819,287.643,423.616z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M257.608,389.958L132.014,249.209c-3.749-4.201-10.194-4.571-14.4-0.819c-4.202,3.75-4.57,10.197-0.819,14.4
			L242.389,403.54c2.015,2.258,4.808,3.409,7.614,3.409c2.415,0,4.842-0.854,6.787-2.59
			C260.991,400.608,261.359,394.161,257.608,389.958z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="logoChoice-list" id="logoChoice-list">
            {selectedLogo.map((res) => (
              <ul
                className={
                  LogoView[0]?.pict === res?.img
                    ? "logoChoice-ul logoChoice-li--selected"
                    : "logoChoice-ul"
                }
                key={res.id}
              >
                <li
                  onClick={() =>
                    setLogoView([
                      { id: res.id, title: res.title, pict: res.img },
                    ])
                  }
                  className="logoChoice-li"
                >
                  {res.id === 0 ? (
                    <p className="defaultLogo">Default logo</p>
                  ) : null}
                  <img
                    src={res.img}
                    alt="logo"
                    style={{ width: 100, height: 100 }}
                    className="logoChoice-img"
                  />
                </li>
              </ul>
            ))}
          </div>
          <div className="logoChoice-arrowRight">
            <svg
              onClick={() => slide("logoChoice-list", "right")}
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
            >
              <g>
                <g>
                  <path
                    d="M440.082,249.209L220.747,3.409C218.812,1.24,216.044,0,213.137,0H79.527c-4.021,0-7.666,2.361-9.308,6.03
			c-1.643,3.669-0.979,7.96,1.698,10.96L285.192,256L71.918,495.01c-2.677,3-3.341,7.291-1.698,10.96
			c1.642,3.669,5.288,6.03,9.308,6.03h133.61c2.906,0,5.675-1.24,7.61-3.409l219.335-245.801
			C443.535,258.921,443.535,253.079,440.082,249.209z M208.568,491.602H102.297l204.175-228.811c3.453-3.87,3.453-9.712,0-13.581
			L102.297,20.398h106.271L418.802,256L208.568,491.602z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M245.948,81.942l-6.37-7.139c-3.751-4.201-10.195-4.57-14.401-0.82c-4.202,3.751-4.57,10.198-0.82,14.401l6.37,7.139
			c2.016,2.258,4.809,3.409,7.615,3.409c2.416,0,4.842-0.854,6.787-2.589C249.33,92.592,249.698,86.146,245.948,81.942z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M395.204,249.209L269.61,108.46c-3.749-4.201-10.194-4.571-14.4-0.819c-4.202,3.75-4.57,10.197-0.819,14.4
			l125.594,140.749c2.015,2.258,4.808,3.409,7.614,3.409c2.415,0,4.842-0.854,6.787-2.59
			C398.587,259.859,398.955,253.412,395.204,249.209z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="footerLogoChoice">
        <button
          onClick={() => setClickedChangeLogo(false)}
          className="modal__btn"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default LogoChoiceComponent;

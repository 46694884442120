import { useEffect } from "react";
import { useRecoilState } from "recoil";

import AttendanceBadgeIcon from "../../images/attendance-home.svg";
import AttendanceHalfBadgeIcon from "../../images/attendance-half.svg";
import clickedBadgeAttendanceAtom from "../../statesManager/clickedBadgeAttendanceAtom";
import useSweetAlertComponent from "../sweetAlertComponent/useSweetAlertComponent";

import usePercentAssiduity from "../../hooks/usePercentAssiduity";

const AttendanceBadge = () => {
  const [clickedBadge, setClickedBadge] = useRecoilState(
    clickedBadgeAttendanceAtom
  );
  const { attendanceAlerts } = useSweetAlertComponent();
  const [pourcentAssiduity] = usePercentAssiduity();

  const handleClickBadge = () => {
    setClickedBadge(true);
  };
  useEffect(() => {
    if (clickedBadge) {
      attendanceAlerts().then((clickedBadge) => {
        if (clickedBadge) {
          setClickedBadge(false);
        }
      });
    }
    console.log("% assiduiti", pourcentAssiduity);
  }, [clickedBadge, attendanceAlerts, setClickedBadge, pourcentAssiduity]);

  return (
    <div className="attendanceBadge-container">
      {pourcentAssiduity === 100 ? (
        <img
          onClick={handleClickBadge}
          src={AttendanceBadgeIcon}
          alt="icon"
          style={{ width: 40, height: 40, marginTop: 5, cursor: "pointer" }}
        />
      ) : pourcentAssiduity === 50 ? (
        <img
          onClick={handleClickBadge}
          src={AttendanceHalfBadgeIcon}
          alt="icon"
          style={{ width: 40, height: 40, marginTop: 5, cursor: "pointer" }}
        />
      ) : null}
    </div>
  );
};

export default AttendanceBadge;
